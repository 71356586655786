/** **************************************************************************
 * Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
 ****************************************************************************/

import React from 'react';
import ReactDOM from 'react-dom';
import { HelpContext } from '../../../Contexts/Help';
import EmptyState from '../../../shared/EmptyState';
import Draggable from 'react-draggable';
import { Remarkable } from 'remarkable';

import {
  isNotEmpty,
  decodeURLHash,
} from '../../../shared/Utilities';

import './style.scss';
import InlineSVG from '../../../shared/InlineSVG';

export const HelpTrigger =( { helpKey } ) => {
  const [
    ,
    ,
    selectedHelpItem,
    ,
    showHelp,
    setShowHelp,
    showHelpFor,
    ,
    ,
  ] = React.useContext( HelpContext );

  const toggleHelp = ( e, helpKey ) => {
    if ( showHelp && selectedHelpItem && selectedHelpItem[helpKey] ) {
      e.preventDefault();
      e.stopPropagation();
      setShowHelp( false );
    } else {
      showHelpFor( e, helpKey );
    }
  };

  return (
    <span
      className="helpTrigger"
      onClick={ e => toggleHelp( e, helpKey ) }
    >

      <InlineSVG type="help" />
    </span>
  );
};

export const ContextualHelpContainer = ( ) => {
  const [
    helpItems,
    ,
    selectedHelpItem,
    setSelectedHelpItem,
    showHelp,
    setShowHelp,
    showHelpFor,
    ,
    ,
  ] = React.useContext( HelpContext );

  const [ section, setSection ] = React.useState( null );
  const [ helpPage, setHelpPage ] = React.useState( null );

  const md = new Remarkable();

  const helpRoot = document.getElementById( 'reactHelpPortal' );

  const openHelpPage = ( toPage=true ) => {
    if ( toPage ) {
      const hash = decodeURLHash();
      const { page, report } = hash;
      const _helpPage = report || page || '';

      return window.open(
        `#.=help_documentation&help_page=${_helpPage}`,
        '_blank',
      );
    }
    return window.open(
      '#.=help_documentation',
      '_blank',
    );
  };

  const setHelpLocation = () => {
    const hash = decodeURLHash();
    const _section = hash['.'];
    const { page, report } = hash;
    const _helpPage = report || page || '';
    setSection( _section );
    setHelpPage( _helpPage );
  };

  // sets the route and page for reuse
  React.useEffect( ( ) => {
    setHelpLocation();
    window.addEventListener( 'hashchange', setHelpLocation );
    return () => window.removeEventListener( 'hashchange', setHelpLocation );
  }, [] );

  // when the help closes, go back to the menu
  React.useEffect( ( ) => {
    if ( showHelp === false ) {
      setSelectedHelpItem( null );
    }
  }, [ showHelp ] );

  // The portal allows this container to live at the root of the body and helps
  // avoid any z-index issues
  return ReactDOM.createPortal(
    <React.Fragment>
      {
        (
          isNotEmpty( section )
          && isNotEmpty( helpPage )
        ) &&
        <React.Fragment>
          <Draggable
            handle=".helpHeader"
            defaultPosition={{x: 0, y: 0}}
            position={null}
            scale={1}
          >
            <div
              className={`helpContainer ${showHelp ? 'visible' : ''}`} >
              <div className="helpHeader">
                <div className="titleWrapper">
                  <InlineSVG type="draggable" elementClass="draggableIcon" />
                  <InlineSVG type="help_center_nav" />
                  <h2>Help Center</h2>
                </div>
                <div className="actions" >
                  <button
                    onClick={ () => setShowHelp( false ) }
                    className="closeButton roundGlyphButton"
                  >
                    <InlineSVG type="close" />
                  </button>
                </div>
              </div>

              <div className="helpBody">
                {
                  isNotEmpty( selectedHelpItem )
                    ? <React.Fragment>
                      <button
                        onClick={ () => setSelectedHelpItem( null ) }
                        className="backButton"
                      >
                        <span>
                          <InlineSVG type="carretLeft" />
                          Back
                        </span>
                      </button>
                      <div
                        className="helpItemDescriptionDetail"
                        dangerouslySetInnerHTML={
                          { __html: md.render( Object.values( selectedHelpItem )[0].content ) }
                        }
                      />
                    </React.Fragment>
                    : <React.Fragment>
                      {
                        isNotEmpty( helpItems )
                          ? <React.Fragment>
                            <label className="helpTopicsCount">
                              Help Topics
                              {
                                isNotEmpty( helpItems ) &&
                                <strong className="itemCount">({Object.values( helpItems ).length})</strong>
                              }
                            </label>
                            <ul className="allItemsMenu">
                              {
                                Object.entries( helpItems ).map( ( [ key, helpContent ], index ) => {
                                  return <li
                                    key={index}
                                    onClick={ e => showHelpFor( e, key ) }
                                  >
                                    <div
                                      className="helpItemDescription"
                                      dangerouslySetInnerHTML={
                                        { __html: md.render( helpContent.content ) }
                                      }
                                    />
                                  </li>;
                                } )
                              }
                            </ul>
                          </React.Fragment>
                          : <EmptyState message={ 'No help items to display'} />
                      }
                    </React.Fragment>
                }
              </div>
              <div className="helpFooter">
                {
                  decodeURLHash()['.'] !== 'help_documentation' &&
                  <button
                    className="openHelpCenter"
                    onClick={ openHelpPage }
                  >
                    <span>
                      All Documentation
                      <InlineSVG type="newTabLink" version="primary" />
                    </span>
                  </button>
                }
              </div>
            </div>
          </Draggable>
        </React.Fragment>
      }
    </React.Fragment>,
    helpRoot,
  );
};
