/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { globalColors, isNotEmpty } from '../Utilities';
import { chartGradients } from './shared';
import {
  deprioritizedKeys,
  forReviewKeys,
  prioritizedKeys,
} from '../../components/Reporting/Dashboards/Widgets/v2/VulnerabilityInstancesCategories';
import EmptyState from '../EmptyState';

// preferred stacking order from bottom to top,
// 1. deprirotized children
// 2. deprioritized
// 3. for_review children
// 4. for_review
// 5. prioritized children
// 6. prioritized
const preferredCategoryOrder = [
  ...deprioritizedKeys,
  'deprioritized',
  ...forReviewKeys,
  'for_review',
  ...prioritizedKeys,
  'prioritized',
];

const AreaSeries = ( {
  series,
  seriesKey,
  max,
  svgHeight,
  svgWidth,
  // xOffset,
  xGutter,
  yGutter,
  isTag=false,
} ) => {
  const pathCurveRatio = 3; // lower number is a greater curve

  const [ points, setPoints ] = React.useState( null );
  const [ pointsString, setPointsString ] = React.useState( '' );
  const [ pathString, setPathString ] = React.useState( '' );

  // takes the series info and creates a pathstring to draw the area, ported from Area.js
  React.useEffect( () => {
    if (
      isNotEmpty( series )
      && isNotEmpty( series.fill )
      && isNotEmpty( series.max )
      && isNotEmpty( series.points )
    ) {
      const _points = [];
      let _pointsString = [];
      let _pathString = [];

      const _heightRatio = svgHeight / max;

      Object.values( series.points ).map( ( point ) => {
        // const data.max = max <= 0 ? 1 : max;
        const y = ( svgHeight - ( _heightRatio * point.adjustedValue ) ) + yGutter;
        // points are not necessarily spaced out evenly, need to find the distance from the left based on
        // the xAxis series, if no xAxis is passed in... need to assume even spacing
        const firstPointValue = Object.values( series.points )[0].timestamp;
        // eslint-disable-next-line max-len
        const lastPointValue = Object.values( series.points )[ Object.values( series.points ).length - 1].timestamp;
        const delta = lastPointValue - firstPointValue;
        const newWidthRatio = svgWidth / delta;
        const x = ( newWidthRatio * ( point.timestamp - firstPointValue ) ) + xGutter;

        const height = svgHeight;
        _points.push( { height, x, y, id: point.id, original: point } );
      } );

      _points.map( ( p, index ) => {

        let px1 = 0;
        let py1 = 0;
        let px2 = 0;
        let py2 = 0;

        if ( isNotEmpty( p.x ) && !isNaN( p.x ) ) {
          px1 = p.x;
        }
        if ( isNotEmpty( p.y ) && !isNaN( p.y ) ) {
          py1 = p.y;
        }

        let pathValue = `${px1},${py1}`;
        let xDelta = 1;
        const prevPoint = _points[ index - 1 ];

        if ( isNotEmpty( prevPoint ) ) {

          if ( isNotEmpty( prevPoint.x ) && !isNaN( prevPoint.x ) ) {
            px2 = prevPoint.x;
          }
          if ( isNotEmpty( prevPoint.y ) && !isNaN( prevPoint.y ) ) {
            py2 = prevPoint.y;
          }

          xDelta = px1 - px2;
        }

        // first point, no curve
        if ( index === 0 ) {
          pathValue = `M ${xGutter},${py1}`;
        // one of the points in the middle, not the last point
        } else if ( index !== _points.length - 1 ) {
          // eslint-disable-next-line max-len
          pathValue = `C ${px2 + ( xDelta * ( 1 / pathCurveRatio ) )},${py2} ${px1 - ( xDelta * ( 1 / pathCurveRatio ) )},${py1} ${px1},${py1}`;
        // the last point
        } else {
          // eslint-disable-next-line max-len
          pathValue = `C ${px2 + ( xDelta * ( 1 / pathCurveRatio ) )},${py2} ${px1},${py1} ${px1},${py1}`;
        }

        _pathString.push( pathValue );
        _pointsString.push( `${px1},${py1}` );
      } );

      _pathString = _pathString.join( ' ' );
      _pointsString = _pointsString.join( ' ' );

      setPoints( _points );
      setPointsString( _pointsString );
      setPathString( _pathString );
    }
  }, [ series, seriesKey, max, svgHeight, svgWidth ] );

  const svgContent = () => {
    return (
      <g className="svgAreaContentGroup">
        {/* gradient fill */}
        <path
          d={ `${pathString} L ${svgWidth + xGutter},${svgHeight - yGutter} L ${xGutter},${svgHeight - yGutter} z` }
          fill={ series.isTagFill ? `url(#gradient--tag--${series.fill})` : `url(#gradient--${series.fill})` }
          fillOpacity={0.1}
          stroke="none"
          className="areaChartFill"
        />
        {/* actual visible path line */}
        <path
          d={ pathString }
          fill="none"
          stroke={ series.isTagFill ? series.fill : globalColors[series.fill] }
          strokeWidth={ 2.5 }
          strokeOpacity={ isTag ? '0.8' : '1' }
        />
      </g>
    );
  };

  return (
    <React.Fragment>
      <React.Fragment>
        {
          ( isNotEmpty( points ) && isNotEmpty( pointsString ) ) &&
          <React.Fragment>
            <defs>
              {
                Object.values( chartGradients ).map( ( grad, index ) => {
                  return <React.Fragment key={index}>
                    { grad }
                  </React.Fragment>;
                } )
              }
            </defs>
            { svgContent() }
          </React.Fragment>
        }
      </React.Fragment>
    </React.Fragment>
  );
};

const MultiArea = ( {
  data,
  containerHeight=400,
  containerWidth=1_000,
  version='stacked',
} ) => {

  // eslint-disable-next-line no-nested-ternary
  const _containerHeight = containerHeight;

  // const containerWidth = 100;
  const svgHeight = _containerHeight - 10;
  const svgWidth = containerWidth - 10;
  const xOffset = 0;
  const xGutter = ( containerWidth - svgWidth ) / 2;
  const yGutter = ( _containerHeight - svgHeight ) / 2;

  const [ seriesData, setSeriesData ] = React.useState( null );
  const [ sortedSeriesData, setSortedSeriesData ] = React.useState( null );
  const [ insufficientData, setInsufficientData ] = React.useState( false );

  // when the data comes in, need to do some math to adjust the position of the points
  // to make it either stacked or overlapping, and then split up the data for each area
  React.useEffect( () => {
    if ( isNotEmpty( data ) && isNotEmpty( data.transformed ) && isNotEmpty( version ) ) {
      const _seriesData = {
        max: version === 'stacked' ? 1 : data.max,
        series: {},
      };

      if ( Object.keys( data.transformed ).length <= 1 ) {
        setInsufficientData( true );
      } else {
        setInsufficientData( false );
      }

      const [ referencePoint ] = Object.values( data.transformed );

      const _preferredTagOrder = [];

      // just set an arbitrary tag order so that the order is always the same from series to series
      if ( isNotEmpty( referencePoint ) && referencePoint.isTag && isNotEmpty( referencePoint.series ) ) {
        Object.entries( referencePoint.series ).map( ( [ assetTagID, series ] ) => {
          if ( series.isIncluded ) {
            _preferredTagOrder.push( assetTagID );
          }
        } );
      }

      if ( isNotEmpty( referencePoint ) && isNotEmpty( referencePoint.series ) ) {

        Object.entries( referencePoint.series ).map( ( [ seriesKey, seriesData ] ) => {
          _seriesData.series[seriesKey] = {
            seriesKey,
            fill: seriesData.fill,
            isTagFill: seriesData.isTagFill,
            max: seriesData.value,
            points: {},
            preferredIndex: referencePoint.isTag
              ? _preferredTagOrder.indexOf( seriesKey )
              : preferredCategoryOrder.indexOf( seriesKey ),
          };
        } );

        Object.entries( data.transformed ).map( ( [ timestamp, transformedData ], index ) => {

          const { pointTotal } = transformedData;

          // go through each of the series and map the data over to the transformed _seriesData object
          if ( isNotEmpty( transformedData.series ) ) {
            Object.entries( transformedData.series ).map( ( [ seriesKey, seriesData ] ) => {

              if ( isNotEmpty( _seriesData.series[seriesKey] ) ) {
                const { preferredIndex } = _seriesData.series[seriesKey];
                if ( isNotEmpty( preferredIndex ) ) {
                  let _seriesPoint = {};

                  // if this is a stacked version, we need to adjust the value by the values "below"
                  if ( version === 'stacked' ) {
                    let prev = 0;
                    // looping through all the previous and adding them all up to get the value of the current
                    Object.entries( transformedData.series ).map( ( [ _key, _data ] ) => {
                      const _pi = seriesData.isTagFill
                        ? _preferredTagOrder.indexOf( _key )
                        : preferredCategoryOrder.indexOf( _key );

                      if ( _pi < preferredIndex ) {
                        prev += ( _data.value / pointTotal );
                      }
                    } );

                    _seriesPoint = {
                      originalValue: seriesData.value,
                      adjustedValue: prev + ( seriesData.value / pointTotal ),
                      fill: seriesData.fill,
                      timestamp,
                      totalPoints: Object.keys( transformedData.series ).length,
                      originalIndex: index,
                    };
                  // otherwise just copy over the values
                  } else {
                    _seriesPoint = {
                      originalValue: seriesData.value,
                      adjustedValue: seriesData.value,
                      fill: seriesData.fill,
                      timestamp,
                      totalPoints: Object.keys( transformedData.series ).length,
                      originalIndex: index,
                    };
                  }
                  _seriesData.series[seriesKey].points[timestamp] = _seriesPoint;
                }
              }
            } );
          }
        } );
      }

      // eslint-disable-next-line
      let _adjustedSeriesMax = version === 'stacked' ? 1 : data.max;

      // adjust the max for each series
      if ( isNotEmpty( _seriesData ) && isNotEmpty( _seriesData.series ) ) {
        Object.values( _seriesData.series ).map( series => {
          let { max } = series;

          if ( isNotEmpty( series.points ) ) {
            Object.values( series.points ).map( p => {
              if ( p.adjustedValue > max ) {
                max = p.adjustedValue;
              }
            } );

            series.max = max;
            if ( max < _adjustedSeriesMax ) {
              _adjustedSeriesMax = max;
            }
          }
        } );

        _seriesData.adjustedMax = _adjustedSeriesMax;
      }

      if ( isNotEmpty( _seriesData ) && isNotEmpty( _seriesData.series ) ) {
        let _sortedSeriesData = Object.values( _seriesData.series );
        _sortedSeriesData = _sortedSeriesData.sort( ( a, b ) => b?.preferredIndex - a?.preferredIndex );
        setSortedSeriesData( _sortedSeriesData );
      }

      setSeriesData( _seriesData );
    }
  }, [ version, data ] );

  return (
    <React.Fragment>
      {
        isNotEmpty( sortedSeriesData ) &&
        <React.Fragment>
          {
            insufficientData
              ? <EmptyState message="Insufficient data" />
              : <React.Fragment>
                {
                  sortedSeriesData.map( ( _data, index ) => {
                    return <AreaSeries
                      series={_data}
                      key={index}
                      seriesKey={_data.seriesKey}
                      max={seriesData.max}
                      adjustedMax={seriesData.adjustedMax}
                      svgHeight={svgHeight}
                      svgWidth={svgWidth}
                      xOffset={xOffset}
                      xGutter={xGutter}
                      yGutter={yGutter}
                      isTag={ data.isTag }
                    />;
                  } )
                }
              </React.Fragment>
          }
        </React.Fragment>

      }
    </React.Fragment>
  );
};

export default MultiArea;