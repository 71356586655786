/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { isNotEmpty } from '../../../../../shared/Utilities';
import TaskItem from './TaskItem';

import './Step3.scss';

const Step3 = ( {
  setLoadingStep,
  plan,
  setPlan,
  getTasks,
  planTasks,
  setPlanTasks,
  isActive,
  users,
  activeIntegrations,
} ) => {

  const [ excludeNoRisk, setExcludeNoRisk ] = React.useState( true );
  const [ tanium, setTanium ] = React.useState( false );

  const handleFilterChange = async ( attribute, value ) => {

    let _params = {
      // eslint-disable-next-line camelcase
      exclude_no_risk: excludeNoRisk,
      tanium: tanium,
    };

    if ( attribute === 'excludeNoRisk' ) {
      setExcludeNoRisk( value );
      // eslint-disable-next-line camelcase
      _params = { ..._params, exclude_no_risk: value };
    }
    if ( attribute === 'tanium' ) {
      setTanium( value );
      _params = { ..._params, tanium: value };
    }
    const _planTasks = await getTasks( _params );
    setPlanTasks( _planTasks );
  };

  const hasTanium = () => isNotEmpty( activeIntegrations ) && isNotEmpty( activeIntegrations.tanium );

  return (
    <React.Fragment>
      <div className="candidateFilterForm">
        <label>
          {
            <div className={
              `checkboxFieldWrapper ${ excludeNoRisk ? 'checked' : ''}`
            }>
              <input
                type="checkbox"
                onChange={ () => handleFilterChange( 'excludeNoRisk', !excludeNoRisk ) }
                checked={ excludeNoRisk }
              />
            </div>
          }
          <span className="labelWrapper">Exclude tasks that have no risk?</span>
        </label>
        {
          hasTanium() &&
          <label>
            {
              <div className={
                `checkboxFieldWrapper ${ tanium ? 'checked' : ''}`
              }>
                <input
                  type="checkbox"
                  onChange={ () => handleFilterChange( 'tanium', !tanium ) }
                  checked={ tanium }
                />
              </div>
            }
            <span className="labelWrapper">Limit to Tanium tasks only?</span>
          </label>
        }
      </div>
      <div className={`panel planDraft ${ isActive && 'isActive' } remediationPanel`}>
        {
          isNotEmpty( planTasks?.selected ) &&
          <section>
            <h2>{ `Tasks to Include in plan (${planTasks?.selected?.length || 0})` }</h2>
            <ul>
              {
                planTasks?.selected.map( ( task, index ) => {
                  return  <TaskItem
                    task={task}
                    key={index}
                    plan={plan}
                    setPlan={setPlan}
                    planTasks={planTasks}
                    setPlanTasks={setPlanTasks}
                    setLoadingStep={setLoadingStep}
                    getTasks={getTasks}
                    users={users}
                    isSelected
                    activeIntegrations={activeIntegrations}
                    excludeNoRisk={excludeNoRisk}
                    tanium={tanium}
                  />;
                } )
              }
            </ul>
          </section>
        }
        <section>
          <h2>{ `Available Tasks (${planTasks?.candidates?.length || 0})` }</h2>
          {
            isNotEmpty( planTasks.candidates ) &&
            <ul>
              {
                planTasks.candidates.map( ( task, index ) => {
                  return  <TaskItem
                    task={task}
                    key={index}
                    plan={plan}
                    setPlan={setPlan}
                    planTasks={planTasks}
                    setPlanTasks={setPlanTasks}
                    setLoadingStep={setLoadingStep}
                    getTasks={getTasks}
                    users={users}
                    activeIntegrations={activeIntegrations}
                    excludeNoRisk={excludeNoRisk}
                    tanium={tanium}
                  />;
                } )
              }
            </ul>
          }
        </section>
      </div>
    </React.Fragment>
  );
};

export default Step3;