/* **************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { isNotEmpty, riskToRating } from '../../../shared/Utilities';
import { getArrowDirection } from './shared';
import './GraphEdge.scss';
import { getRiskFill } from '../../RecordDetails/shared';

const GraphEdge = ( {
  edge,
  nodes,
  focusEdges=[],
  setFocusEdges,
  focusNodes=[],
  setFocusNodes,
  externalHoverIDs,
  setSelectedItem,
  setSelectedItemType,
  setContextMenuItem,
  setContextMenuType,
  ignoreClick,
  setIgnoreClick,
} ) => {

  const [ pathVariables, setPathVariables ] = React.useState( null );
  const [ hovering, setHovering ] = React.useState( false );

  const handleEdgeRightClick = ( e, edge ) => {
    if ( isNotEmpty( e ) && e.button === 2  ) {
      e.stopPropagation();
      e.preventDefault();
      const _item = { ...edge, clickEvent: e };
      setContextMenuItem( _item );
      setContextMenuType( 'edge' );
      return false;
    }
  };

  const handleEdgeClick = ( e, edge ) => {

    if ( isNotEmpty( e ) && e.button === 0 ) {
      e.stopPropagation();
      e.preventDefault();
      const _item = { ...edge, clickEvent: e };

      // DO NOT REMOVE!!! used by devs to debug explore behavior -DMC 2023-07-27
      console.log( _item );

      const fromNode = nodes[_item.from_node];
      const toNode = nodes[_item.to_node];

      const allNodes = [ fromNode?.id, toNode?.id ];

      if ( ignoreClick ) {
        setIgnoreClick( false );
      } else if ( isNotEmpty( focusEdges ) && focusEdges.includes( _item.id ) ) {
        let _edges = [ ...focusEdges ];
        _edges = _edges.filter( e => e !== _item.id );
        setFocusEdges( _edges );
        if ( isNotEmpty( focusNodes ) ) {
          let _nodes = [ ...focusNodes ];
          _nodes = _nodes.filter( n => !allNodes.includes( n ) );
          setFocusNodes( _nodes );
        }
        setSelectedItem( null );
        setSelectedItemType( null );
      // not clicked yet, set it
      } else {
        setSelectedItem( _item );
        setSelectedItemType( 'edge' );
        setFocusEdges( [ _item.id ] );
        setFocusNodes( allNodes );
      }
    }
  };

  const shouldHighlight = edge => hovering || externalHoverIDs?.includes( edge.id ) || focusEdges?.includes( edge.id );

  React.useEffect( () => {
    if ( isNotEmpty( edge ) ) {

      const { x1, x2, y1, y2 } = edge;

      const strokeWidth = 8;
      const arrowWidth = strokeWidth * 6;
      const arrowDirection = getArrowDirection( x1, y1, x2, y2 );

      const width = x2 - x1;
      const height = y2 - y1;
      const M = `${x1}, ${y1}`;
      const C = `${x1 + ( width * ( 3 / 4 ) )}, ${y1} ${x1 + ( width / 4 )},${y2} ${x2}, ${y2}`;

      setPathVariables( {
        arrowWidth,
        strokeWidth,
        x1,
        x2,
        y1,
        y2,
        width,
        height,
        M,
        C,
        arrowDirection,
      } );
    }
  }, [ edge ] );

  return (
    <React.Fragment>
      {
        ( isNotEmpty( edge ) && isNotEmpty( pathVariables ) ) &&
        <g
          // eslint-disable-next-line max-len
          className={ `graphModelEdgeGroup risk-${riskToRating( edge.risk ) } ${ focusEdges?.includes( edge.id ) ? 'focused' : '' }` }
        >
          <path
            // eslint-disable-next-line max-len
            d={ `M ${pathVariables.M} C ${pathVariables.C}`}
            strokeWidth={ pathVariables.strokeWidth }
            stroke={ getRiskFill( edge, shouldHighlight( edge ) ) }
            fill="none"
            className="graphEdgePath"
            strokeDasharray={ edge.nofix ? '20 10' : null }
          />
          {/* wider hover path */}
          <path
            // eslint-disable-next-line max-len
            d={ `M ${pathVariables.M} C ${pathVariables.C}`}
            strokeWidth={ pathVariables.strokeWidth * 3 }
            fill="none"
            strokeLinecap="round"
            stroke={ getRiskFill( edge, shouldHighlight( edge ) ) }
            strokeOpacity={ shouldHighlight( edge ) ? 0.2 : 0 }
            className="edgeHoverPath"
            // need to prevent here before passed to the handler or it will still trigger the default right-click menu
            onContextMenu={ e => {
              e.preventDefault();
              e.stopPropagation();
              handleEdgeRightClick( e, edge );
              return false;
            } }
            onClick={ e  => {
              e.preventDefault();
              e.stopPropagation();
              handleEdgeClick( e, edge );
              return false;
            } }
            onMouseEnter={ () => setHovering( true ) }
            onMouseLeave={ () => setHovering( false ) }
          />
          {
            shouldHighlight( edge ) &&
              <circle
                cx={ pathVariables.x1 + ( pathVariables.width / 2 ) }
                cy={ pathVariables.y1 + ( pathVariables.height / 2 ) }
                r={ pathVariables.arrowWidth / 1.5 }
                fill={ getRiskFill( edge, shouldHighlight( edge ) ) }
                fillOpacity={ 0.2 }
                onContextMenu={ e => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleEdgeRightClick( e, edge );
                  return false;
                } }
                onClick={ e  => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleEdgeClick( e, edge );
                  return false;
                } }
                onMouseEnter={ () => setHovering( true ) }
                onMouseLeave={ () => setHovering( false ) }
              />
          }
          {/* arrow */}
          <svg
            x={ pathVariables.x1 + ( pathVariables.width / 2 ) - ( pathVariables.arrowWidth / 2 ) }
            y={ pathVariables.y1 + ( pathVariables.height / 2 ) - ( pathVariables.arrowWidth / 2 ) }
            width={ pathVariables.arrowWidth }
            height={ pathVariables.arrowWidth }
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx={ 16 }
              cy={ 16 }
              r={ 14 }
              stroke={ getRiskFill( edge, shouldHighlight( edge ) ) }
              fill="#fff"
              strokeWidth={ 2 }
              onContextMenu={ e => {
                e.preventDefault();
                e.stopPropagation();
                handleEdgeRightClick( e, edge );
                return false;
              } }
              onClick={ e  => {
                e.preventDefault();
                e.stopPropagation();
                handleEdgeClick( e, edge );
                return false;
              } }
              onMouseEnter={ () => setHovering( true ) }
              onMouseLeave={ () => setHovering( false ) }
            />
            <path
              className={ `edgeArrow edgeArrowDirection ${pathVariables.arrowDirection}` }
              // eslint-disable-next-line max-len
              d="M16 14.5H10V17.5H16V22L22 16L16 10V14.5Z"
              fill={ getRiskFill( edge, shouldHighlight( edge ) ) }
              onContextMenu={ e => {
                e.preventDefault();
                e.stopPropagation();
                handleEdgeRightClick( e, edge );
                return false;
              } }
              onClick={ e  => {
                e.preventDefault();
                e.stopPropagation();
                handleEdgeClick( e, edge );
                return false;
              } }
              onMouseEnter={ () => setHovering( true ) }
              onMouseLeave={ () => setHovering( false ) }
            />
          </svg>
        </g>
      }
    </React.Fragment>
  );
};

export default GraphEdge;