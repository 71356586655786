import {
  removeFromURLHash,
  decodeURLHash,
  encodeURLHash,
  isNotEmpty,
  isEmpty,
} from '../../../shared/Utilities';

export const typeOptions = [
  'scope',
  'path',
  'patch',
  'vulnerability',
  'node',
];

export const getEdgeRating = edge => {
  if ( edge?.nofix ) {
    return 'nofix';
  }
  if ( isEmpty( edge ) || edge.nofix ) {
    return 'default';
  }
  if ( edge.risk_percentile < 0.05 ) {
    return 'primary';
  }
  if ( edge.risk_percentile < 0.25 ) {
    return 'minimal';
  }
  if ( edge.risk_percentile < 0.75 ) {
    return 'low';
  }
  if ( edge.risk_percentile < 0.95 ) {
    return 'moderate';
  }
  return 'high';
};

export const getEdgeRatings = ( edgeIDs, allEdges ) => {
  const ratings = [];

  if ( isNotEmpty( edgeIDs ) && isNotEmpty( allEdges ) ) {
    edgeIDs.map( eID => {
      const edge = allEdges[eID];
      if ( isNotEmpty( edge ) ) {
        ratings.push( getEdgeRating( edge ) );
      }
    } );
  }
  return ratings;
};

export const selectExploreItems =  async ( type, items ) => {
  const hash = decodeURLHash();

  const existingSelected = hash[`explore_${type}`] || [];

  const selectedIDs = new Set( existingSelected );

  console.log( selectedIDs );

  const newIDs = items.map( i => i.id );

  newIDs.map( id => {
    selectedIDs.add( id );
  } );

  console.log( selectedIDs );
  encodeURLHash( { [`explore_${type}`]: Array.from( selectedIDs ) } );
};

export const deselectExploreItems = async ( type, items ) => {
  const hash = decodeURLHash();

  const existingSelected = hash[`explore_${type}`];
  let _existingIDs = [];
  const _newIDs = [];

  if ( isNotEmpty( existingSelected ) ) {
    _existingIDs = existingSelected;

    const removedIDs = items.map( i => i.id );

    _existingIDs.map( id => {
      if ( !removedIDs.includes( id ) ) {
        _newIDs.push( id );
      }
    } );
  }

  if ( isNotEmpty( _newIDs ) ) {
    encodeURLHash( { [`explore_${type}`]: _newIDs } );
  } else {
    removeFromURLHash( `explore_${type}` );
  }
};
