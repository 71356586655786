/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { HelpTrigger } from '../../../HelpDocumentation/ContextualHelp/index.js';
import { isNotEmpty, updateGlobalSettings } from '../../../../shared/Utilities';
import { CurrentUserContext } from '../../../../Contexts/CurrentUser.js';
import { canConfigure } from '../../../App/AccessControl.js';

const AutoProvisionSelect = ( { records, globalSettings, setGlobalSettings } ) => {

  const [ samlOptions, setSamlOptions ] = React.useState( {} );
  const [ samlValue, setSamlValue ] = React.useState( '' );
  const [ , , licenseInfo ] = React.useContext( CurrentUserContext );

  const handleChange = async ( e ) => {

    // eslint-disable-next-line camelcase
    const newValue = { autoprovision_auth_provider: e.target.value === '' ? null : e.target.value };

    const _updated = await updateGlobalSettings( 'global', newValue );
    setGlobalSettings( _updated.results );
  };

  React.useEffect( () => {

    if ( isNotEmpty( globalSettings ) ) {
      const currentAutoProvision = globalSettings.autoprovision_auth_provider;

      const options = {};

      if ( isNotEmpty( records ) ) {
        records.map( provider => {
          if ( provider.type === 'saml2' ) {
            options[provider.id] = provider.label;
          }
        } );
      }
      setSamlValue( currentAutoProvision || '' );

      setSamlOptions( options );
    }

  }, [ records, globalSettings ] );

  return (
    <React.Fragment>
      {
        isNotEmpty( samlOptions ) &&
      <label>
        <span className="labelWrapper">
          <span>Auto-provision authentication provider</span>
          <HelpTrigger helpKey="autoprovision_authentication_provider" />
        </span>
        <div className="selectFieldWrapper">
          <select
            value={samlValue}
            onChange={ handleChange }
            disabled={ !canConfigure( licenseInfo ) }
          >
            <option value="" >Disabled (none) </option>
            {
              Object.entries( samlOptions ).map( ( [ value, label ], index ) => {
                return  <option key={index} value={value} >
                  { label }
                </option>;
              } )
            }
          </select>
        </div>
      </label>
      }
    </React.Fragment>
  );
};

export default AutoProvisionSelect;