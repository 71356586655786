/* **************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import {
  getDimensionsAndOffset,
  globalColors,
  isEmpty,
  isEqual,
  isNotEmpty,
} from '../../../shared/Utilities';
import {
  GLOBAL_SCOPE,
  SCOPE_PADDING,
} from './shared';

import GraphScope from './GraphScope';
import GraphNode from './GraphNode';
import GraphEdge from './GraphEdge';
import EmptyLoading from '../../../shared/EmptyLoading';
import Dialog from '../../../shared/Dialog';
import RecordCard from '../../RecordDetails/RecordCard';

const Graph = ( {
  // saveGraphLayout,
  data,
  editItem,
  deleteItem,
  copyItem,
  selectItem,
  addSegment,
  findPathsFromHere,
  loadingGraph,
  firstClickedItem,
  setFirstClickedItem,
  externalHoverIDs,
  selectingSecondaryItem,
  setSelectingSecondaryItem,
  isPanning,
  setIsPanning,
  selectedItem,
  setSelectedItem,
  selectedItemType,
  setSelectedItemType,
  fitToScreen,
  setContextMenuItem,
  setContextMenuType,
  pathsFromHere,
  setPathsFromHere,
  showPathsFromHere,
  setShowPathsFromHere,
  setExternalHoverIDs,
  displayExploreItemNameFor,
  graphData,
  setGraphData,
  svgScale,
  setSVGScale,
  svgPanShift,
  setSVGPanShift,
  redrawGraph,
  defaultSVGScale,
  defaultSVGPanShift,
  // setDefaultSVGScale,
  // setDefaultSVGPanShift,
  svgDimensions,
  setSVGDimensions,
  collapsedRecordCard,
  setCollapsedRecordCard,
  collapsedGraphMenu,
  savedLayout,
  selectedPathID,
  setSelectedPathID,
} ) => {

  const [ draggingItem, setDraggingItem ] = React.useState( null );
  const [ draggingItemType, setDraggingItemType ] = React.useState( null );
  const [ focusNodes, setFocusNodes ] = React.useState( [] );
  const [ focusEdges, setFocusEdges ] = React.useState( [] );
  const [ showItemModal, setShowItemModal ] = React.useState( false );
  const [ ignoreClick, setIgnoreClick ] = React.useState( false );
  const [ ignorePanClick, setIgnorePanClick ] = React.useState( false );
  const [ isZoomed, setIsZoomed ] = React.useState( false );
  const [ isScrollZooming, setIsScrollZooming ] = React.useState( false );

  const svgRef = React.useRef( null );

  // Helpers --------------------- //
  // ----------------------------- //
  // calculates the dimensions of the rectangle needed to enclose the children
  const getEnclosingRectangle = ( children, includePadding=true ) => {

    let x = 0;
    let y = 0;
    let w = 0;
    let h = 0;

    if ( isNotEmpty( children ) ) {

      const [ firstChild ] = children;

      let x1 = firstChild.x;
      let y1 = firstChild.y;
      let x2 = firstChild.x + firstChild.w;
      let y2 = firstChild.y + firstChild.h;

      children.map( child => {
        const _x1 = child.x;
        const _x2 = child.x + child.w;
        const _y1 = child.y;
        const _y2 = child.y + child.h;
        if ( _x1 < x1 ) {
          x1 = _x1;
        }
        if ( _y1 < y1 ) {
          y1 = _y1;
        }
        if ( _x2 > x2 ) {
          x2 = _x2;
        }
        if ( _y2 > y2 ) {
          y2 = _y2;
        }
      } );

      x = x1;
      y = y1;
      w = x2 - x1;
      h = y2 - y1;

      if ( includePadding ) {
        x -= SCOPE_PADDING;
        y -= ( SCOPE_PADDING * 2 );
        w += ( SCOPE_PADDING * 2 );
        h += ( SCOPE_PADDING * 3 );
      }
    }
    return ( { x, y, w, h } );
  };

  // gets all parents and parents parents for nodes
  const getAllParents = item => {

    let childScope = {};
    let parentScope = {};

    // this is a node, it will 1 or 2 parents
    if ( item.childType && item.childType === 'node' && isNotEmpty( item.scope_id ) ) {

      const parent = graphData.scopes[item.scope_id];
      if ( isNotEmpty( parent ) ) {
        // if this node's parent is the global scope, it is the adversary node, no need to do anything else
        if ( parent.id === GLOBAL_SCOPE.id ) {
          return ( { childScope, parentScope } );
        } else if ( isNotEmpty( parent.parentScope ) ) {

          // this will have a child and parent
          if ( parent.parentScope.id !== GLOBAL_SCOPE.id ) {
            childScope = parent;
            const parentParent = graphData.scopes[parent.parentScope.id];
            if ( isNotEmpty( parentParent ) ) {
              parentScope = parentParent;
            }
          // this will only have a parent
          } else {
            parentScope = parent;
          }
        }
      }
      return ( { childScope, parentScope } );
    }
  };

  // anytime the nodes are moved ( by dragging a node, or a scope ), this will get called
  // want to have the nodes passed in so that it has the most up to date positions of everything
  // returns edges object
  const updateEdges = nodes => {

    const _edges = {};

    if ( isNotEmpty( graphData.edges ) && isNotEmpty( nodes ) ) {
      Object.values( graphData.edges ).map( edge => {
        const fromNode = nodes[edge.from_node];
        const toNode = nodes[edge.to_node];

        const newEdge = {
          ...edge,
          x1: fromNode.center.x,
          y1: fromNode.center.y,
          x2: toNode.center.x,
          y2: toNode.center.y,
        };

        _edges[edge.id] = newEdge;
      } );

      return _edges;
    }

  };

  // EVENT HANDLERS -------------- //
  // ----------------------------- //
  // zooms the graph in and out on mousewheel, bound to the svg
  const handleSVGWheel = e => {

    setIsScrollZooming( true );

    if ( isNotEmpty( e ) ) {

      let newScale = svgScale;

      e.preventDefault();

      const svgElOffset = getDimensionsAndOffset( svgRef.current );

      if ( e.deltaY < 0 ) {
        newScale = svgScale + 0.1;
        if ( newScale > defaultSVGScale * 3 ) {
          newScale = defaultSVGScale * 3;
        }
      // zooming out
      } else {
        newScale = svgScale - 0.1;
        if ( newScale < defaultSVGScale / 3 ) {
          newScale = defaultSVGScale / 3;
        }
      }

      const pointerPosition = {
        x: ( e.pageX - svgElOffset.left ) / svgElOffset.width,
        y: ( e.pageY - svgElOffset.top ) / svgElOffset.height,
      };

      const xShift = ( ( svgElOffset.width * newScale ) - ( svgElOffset.width * svgScale ) ) * pointerPosition.x;
      const yShift = ( ( svgElOffset.height * newScale ) - ( svgElOffset.height * svgScale ) ) * pointerPosition.y;

      setSVGPanShift( {
        x: svgPanShift.x - xShift,
        y: svgPanShift.y - yShift,
      } );

      setSVGScale( newScale );
      setIsScrollZooming( false );
    }
  };

  // if clicking on a node or scope, this will set that item, the mouse move will handle any functionality
  // if instead you are trying to select a node for the purposes of creating a new segment, or for finding paths,
  // the default drag/selection behavior will be ignored
  const handleItemMouseDown = e => {
    if ( isNotEmpty( e ) && e.button === 0 ) {
      e.stopPropagation();

      let item = graphData.nodes[e.target.id];
      let type = 'node';

      // we are tying to select this item as a secondaryItem
      if ( isNotEmpty( selectingSecondaryItem ) ) {
        let _item = selectedItem;

        if ( isEmpty( _item ) ) {
          _item = firstClickedItem;
        }

        if ( selectingSecondaryItem === 'segment' ) {
          addSegment( _item, item );
        } else if ( selectingSecondaryItem === 'paths' ) {
          findPathsFromHere( _item, item );
        }

      // default behavior most of the time
      } else {
        setDraggingItem( null );
        // probably a node, but could also be a scope
        if ( isEmpty( item ) ) {
          item = graphData.scopes[e.target.id];
          type = 'scope';
        }
        // eslint-disable-next-line max-len
        setDraggingItem( item );
        setDraggingItemType( type );
      }
    }
  };

  // clears the dragging item on mouseup
  const handleItemMouseUp = () => {
    setDraggingItem( null );
    setDraggingItemType( null );
  };

  // toggles panning mode on so that the mousemove listener will pan instead of drag, bound to the svg
  const handleSVGMouseDown = e => {
    if ( isNotEmpty( e ) && e.button === 0 ) {
      setIsPanning( true );
    }
  };

  // toggles panning mode off so that the mousemove listener will do nothing
  const handleSVGMouseUp = () => {
    setIsPanning( false );
    setContextMenuItem( null );
    setContextMenuType( null );
    // eslint-disable-next-line max-len
    // if we are dragging an item around, all we want to do is clear that item out
    if ( isNotEmpty( draggingItem ) ) {
      setDraggingItem( null );
    } else if ( selectingSecondaryItem !== 'paths' ) {

      if ( ignorePanClick ) {
        setIgnorePanClick( false );
      } else {
        setSelectedItem( null );
        setFocusEdges( [] );
        setFocusNodes( [] );
      }
    }
  };

  // there is always a mousemove listener, this will look for different flags and do 1 of four things:
  // 1) if isPanning === true, it will pass off to panGraph()
  // 2) if draggingItem !== null and it is a node, it passes off to dragNode()
  // 3) if draggingItem !== null and it is a scope, it passes off to dragScope()
  // 4) if none of those things, it will do nothing
  const handleSVGMouseMove = e => {
    // since we are dragging an item, we want to ignore the mouseUp
    // (actually happens on the onClick handler for an item)
    // this will then get reset once that click event finishes firing
    setIgnoreClick( false );

    if ( isNotEmpty( draggingItem ) ) {
      e.stopPropagation();
      e.preventDefault();
      if ( draggingItemType === 'node' ) {
        dragNode( e );
      } else if ( draggingItemType === 'scope' ) {
        dragScope( e );
      }
    // if we are not clicking on a node or a scope, we are trying to pan
    } else if ( isPanning ) {
      e.stopPropagation();
      e.preventDefault();
      panGraph( e );
    }
  };

  // When a user doubleclicks the empty part of the svg, the svg should zoom out to its initial zoom/pan state so that
  // it fits neetly on the page, this 'resets' any errant and/or intential zoom in
  const handleSVGDoubleClick = () => {
    resetGraphZoom();
  };

  // when the user right-clicks on the svg, pull up the same menu that is present in the graph options
  const handleSVGRightClick = e => {
    if ( isNotEmpty( e ) && e.button === 2 ) {
      e.stopPropagation();
      setContextMenuItem( { clickEvent: e } );
      setContextMenuType( 'svg' );
    }
    return false;
  };

  // if the user pans outside of the svg area, clear everything
  const handleSVGMouseLeave = () => {
    setIsPanning( false );
    setDraggingItem( null );
  };

  // Anytime the page resizes
  const handleWindowResize = () => {
    const totalWidth = window.innerWidth;
    const totalHeight = window.innerHeight;
    const navWidth = document.getElementById( 'navigationMenu' ).offsetWidth;
    const topBarHeight = document.getElementById( 'topBar' ).offsetHeight;

    // will need to dial this in once the page layout settles down
    const extraWidth = 0;
    const extraHeight = 0;

    setSVGDimensions( { w: totalWidth - navWidth - extraWidth, h: totalHeight - topBarHeight - extraHeight } );
  };

  // handles panning
  const panGraph = e => {

    // this detects the browser zoom level
    const pxRatio = window.devicePixelRatio || window.screen.availWidth / document.documentElement.clientWidth;

    setIgnorePanClick( true );
    const { movementX, movementY } = e;
    const newPanShift = {
      x: svgPanShift.x + ( movementX / pxRatio ),
      y: svgPanShift.y + ( movementY / pxRatio ),
    };
    setSVGPanShift( newPanShift );
  };

  // handles dragging a node, does several things
  // 1) moves the node itself
  // 2) moves/resizes the immediate parent
  // 3) moves/resizes the parent of the parent (if there is one)
  // 4) adjusts the appropriate edges
  const dragNode = e => {

    // since we are dragging an item, we want to ignore the mouseUp
    // (actually happens on the onClick handler for the svg)
    // this will then get reset once that click event finishes firing
    setIgnoreClick( true );

    const { pageX, pageY } = e;

    const svgContainer = getDimensionsAndOffset( svgRef.current );

    const newX = ( ( pageX - svgContainer?.left ) / svgScale ) - ( svgPanShift.x / svgScale );
    const newY = ( ( pageY - svgContainer?.top ) / svgScale ) - ( svgPanShift.y / svgScale );

    let xDiff = draggingItem.w / 2;
    let yDiff = draggingItem.center.y - draggingItem.y;

    const newNode = {
      ...draggingItem,
      x: newX - xDiff,
      y: newY - yDiff,
      center: {
        ...draggingItem.center,
        x: newX,
        y: newY,
      },
    };

    const parents = getAllParents( draggingItem );

    if ( isNotEmpty( parents ) ) {

      let newChildScope = {};
      let newParentScope = {};

      // if this has a childscope, it will also have a parent, movement is a little trickier, need to adjust
      // the dimensions of both the immediate parent and the one above that by the same amount
      if ( isNotEmpty( parents.childScope ) ) {
        let children = [ ...parents.childScope.children ];

        children = children.filter( c => c.id !== newNode.id );

        children = [
          ...children,
          { ...newNode },
        ];

        const childDimensions = getEnclosingRectangle( children );

        newChildScope = {
          ...parents.childScope,
          x: childDimensions.x,
          y: childDimensions.y,
          w: childDimensions.w,
          h: childDimensions.h,
          rectangle: {
            x: childDimensions.x,
            y: childDimensions.y,
            w: childDimensions.w,
            h: childDimensions.h,
          },
          children,
        };

        if ( isNotEmpty( childDimensions ) && isNotEmpty( parents.parentScope ) ) {
          let children = [ ...parents.parentScope.children ];

          const thisChild = children.find( c => c.id === newChildScope.id );

          children = children.filter( c => c.id !== thisChild.id );

          children = [
            ...children,
            newChildScope,
          ];

          const parentDimensions = getEnclosingRectangle( children );

          // need to enforce min width and heights!!!!
          newParentScope = {
            ...parents.parentScope,
            x: parentDimensions.x,
            y: parentDimensions.y,
            w: parentDimensions.w,
            h: parentDimensions.h,
            rectangle: {
              x: parentDimensions.x,
              y: parentDimensions.y,
              w: parentDimensions.w,
              h: parentDimensions.h,
            },
            children,
          };
        }

        if ( isNotEmpty( newChildScope ) && isNotEmpty( newParentScope ) ) {
          const _childScopes = { ...graphData.childScopes, [newChildScope.id]: newChildScope };
          const _parentScopes = { ...graphData.parentScopes, [newParentScope.id]: newParentScope };
          const _nodes = { ...graphData.nodes, [draggingItem.id]: newNode };
          const _edges = updateEdges( _nodes );
          const _scopes = {
            ...graphData.scopes,
            [newChildScope.id]: newChildScope,
            [newParentScope.id]: newParentScope,
          };

          setGraphData( {
            ...graphData,
            scopes: _scopes,
            parentScopes: _parentScopes,
            childScopes: _childScopes,
            nodes: _nodes,
            edges: _edges,
          } );
        }

      // this only has a parent, slightly easier, just need to adjust the dimensions of one parent
      } else if ( isNotEmpty( parents.parentScope ) ) {
        let children = [ ...parents.parentScope.children ];

        const thisChild = children.find( c => c.id === draggingItem.id );

        children = children.filter( c => c.id !== thisChild.id );

        xDiff = thisChild.w / 2;
        yDiff = thisChild.center.y - thisChild.y;

        children = [
          ...children,
          {
            ...thisChild,
            x: newX - xDiff,
            y: newY - yDiff,
            center: {
              ...thisChild.center,
              x: newX,
              y: newY,
            },
          },
        ];

        const parentDimensions = getEnclosingRectangle( children );

        newParentScope = {
          ...parents.parentScope,
          x: parentDimensions.x,
          y: parentDimensions.y,
          w: parentDimensions.w,
          h: parentDimensions.h,
          rectangle: {
            x: parentDimensions.x,
            y: parentDimensions.y,
            w: parentDimensions.w,
            h: parentDimensions.h,
          },
          children,
        };

        if ( isNotEmpty( newParentScope ) ) {
          const _parentScopes = { ...graphData.parentScopes, [newParentScope.id]: newParentScope };
          const _nodes = { ...graphData.nodes, [draggingItem.id]: newNode };
          const _edges = updateEdges( _nodes );
          const _scopes = {
            ...graphData.scopes,
            [newParentScope.id]: newParentScope,
          };

          setGraphData( {
            ...graphData,
            scopes: _scopes,
            nodes: _nodes,
            parentScopes: _parentScopes,
            edges: _edges,
          } );
        }
      // this is the adversary node, no scopes to move
      } else {
        const _nodes = { ...graphData.nodes, [draggingItem.id]: newNode };
        const _edges = updateEdges( _nodes );
        setGraphData( {
          ...graphData,
          nodes: _nodes,
          edges: _edges,
        } );
      }
    }
  };

  // handles dragging a scope, does several things
  // 1) moves the scope itself
  // 2) moves all children within the scope
  // 3) moves all the children of the children (if there are any)
  // 4) resizes parent scope (if there is one)
  // 5) adjusts the appropriate edges
  const dragScope = e => {

    // this detects the browser zoom level
    const pxRatio = window.devicePixelRatio || ( window.screen.availWidth / document.documentElement.clientWidth );

    // since we are dragging an item, we want to ignore the mouseUp
    // (actually happens on the onClick handler for the svg)
    // this will then get reset once that click event finishes firing
    setIgnoreClick( true );

    const { movementX, movementY } = e;

    const _movedNodes = {};
    const _movedScopes = {};

    const newScope = {
      ...draggingItem,
      x: draggingItem.x += ( ( movementX / svgScale ) / pxRatio ),
      y: draggingItem.y += ( ( movementY / svgScale ) / pxRatio ),
      rectangle: {
        ...draggingItem.rectangle,
        x: draggingItem.rectangle.x += ( ( movementX / svgScale ) / pxRatio ),
        y: draggingItem.rectangle.y += ( ( movementY / svgScale ) / pxRatio ),
      },
    };

    if ( isNotEmpty( draggingItem ) && isNotEmpty( draggingItem.scopeType ) ) {
      // this is a parent scope, don't have to move up... just down
      if ( draggingItem.scopeType === 'parentScope' ) {

        const _newScopeChildren = [];

        draggingItem.children.map( child => {
          if ( child.childType === 'scope' ) {

            const _childChildren = [];
            const _scope = {
              ...child,
              x: child.x += ( ( movementX / svgScale ) / pxRatio ),
              y: child.y += ( ( movementY / svgScale ) / pxRatio ),
              rectangle: {
                ...child.rectangle,
                x: child.rectangle.x += ( ( movementX / svgScale ) / pxRatio ),
                y: child.rectangle.y += ( ( movementY / svgScale ) / pxRatio ),
              },
            };

            // move all of this scopes children the same amount as well
            const _childScope = graphData.scopes[child.id];

            _childScope.children.map( node => {
              const _node = {
                ...node,
                x: node.x += ( ( movementX / svgScale ) / pxRatio ),
                y: node.y += ( ( movementY / svgScale ) / pxRatio ),
                center: {
                  ...node.center,
                  x: node.center.x += ( ( movementX / svgScale ) / pxRatio ),
                  y: node.center.y += ( ( movementY / svgScale ) / pxRatio ),
                },
              };

              _movedNodes[_node.id] = _node;
              _childChildren.push( _node );
            } );

            _scope.children = _childChildren;

            _movedScopes[_scope.id] = _scope;

            _newScopeChildren.push( _scope );

          } else if ( child.childType === 'node' ) {
            const _node = {
              ...child,
              x: child.x += ( ( movementX / svgScale ) / pxRatio ),
              y: child.y += ( ( movementY / svgScale ) / pxRatio ),
              center: {
                ...child.center,
                x: child.center.x += ( ( movementX / svgScale ) / pxRatio ),
                y: child.center.y += ( ( movementY / svgScale ) / pxRatio ),
              },
            };

            _movedNodes[_node.id] = _node;
            _newScopeChildren.push( _node );
          }
        } );

        newScope.children = _newScopeChildren;

        const _parentScopes = { ...graphData.parentScopes, [newScope.id]: newScope };
        const _childScopes = { ...graphData.childScopes, ..._movedScopes };
        const _nodes = { ...graphData.nodes, ..._movedNodes };
        const _edges = updateEdges( _nodes );
        const _scopes = { ..._parentScopes, ..._childScopes };

        setGraphData( {
          ...graphData,
          scopes: _scopes,
          parentScopes: _parentScopes,
          childScopes: _childScopes,
          nodes: _nodes,
          edges: _edges,
        } );
      // this is child scope, need to move up and down
      } else if ( draggingItem.scopeType === 'childScope' ) {
        const _newScopeChildren = [];

        // adjust all the children positions
        draggingItem.children.map( node => {
          const _node = {
            ...node,
            x: node.x += ( ( movementX / svgScale ) / pxRatio ),
            y: node.y += ( ( movementY / svgScale ) / pxRatio ),
            center: {
              ...node.center,
              x: node.center.x += ( ( movementX / svgScale ) / pxRatio ),
              y: node.center.y += ( ( movementY / svgScale ) / pxRatio ),
            },
          };

          _movedNodes[_node.id] = _node;
          _newScopeChildren.push( _node );
        } );

        // adjust the parentScope if needed (moving this child scope might need to increase the parent dimensions)
        const parentScope = graphData.scopes[draggingItem.parentScope.id];

        if ( isNotEmpty( parentScope ) ) {

          let children = [ ...parentScope.children ];

          children = children.filter( c => c.id !== newScope.id );

          children = [
            ...children,
            { ...newScope },
          ];

          const parentDimensions = getEnclosingRectangle( children );

          const newParentScope = {
            ...parentScope,
            x: parentDimensions.x,
            y: parentDimensions.y,
            w: parentDimensions.w,
            h: parentDimensions.h,
            rectangle: {
              x: parentDimensions.x,
              y: parentDimensions.y,
              w: parentDimensions.w,
              h: parentDimensions.h,
            },
            children,
          };
          const _parentScopes = { ...graphData.parentScopes, [newParentScope.id]: newParentScope };
          const _childScopes = { ...graphData.childScopes, [newScope.id]: newScope };
          const _nodes = { ...graphData.nodes, ..._movedNodes };
          const _edges = updateEdges( _nodes );
          const _scopes = { ..._parentScopes, ..._childScopes };

          setGraphData( {
            ...graphData,
            scopes: _scopes,
            parentScopes: _parentScopes,
            childScopes: _childScopes,
            nodes: _nodes,
            edges: _edges,
          } );
        }
      }
    }
  };
  // ----------------------------- //
  // End of EVENT HANDLERS ------- //

  // 1) setup up the resize listeners
  React.useEffect( () => {
    handleWindowResize();
    redrawGraph( savedLayout );
    window.addEventListener( 'resize', handleWindowResize );
    return () => {
      window.removeEventListener( 'resize', handleWindowResize );
    };
  }, [ data ] );

  // 2) rebinds the wheel listener whenever any relevant vars change, need to bind this here instead of directly
  // on the element so that { passive: false } can be passed in as an option,
  // otherwise preventDefault() cannot be called
  React.useEffect( () => {
    if ( isNotEmpty( svgRef ) && isNotEmpty( svgRef.current ) ) {
      svgRef.current.addEventListener( 'wheel', handleSVGWheel, { passive: false } );
    }
    return () => {
      if ( isNotEmpty( svgRef ) && isNotEmpty( svgRef.current )  ) {
        svgRef.current.removeEventListener( 'wheel', handleSVGWheel, { passive: false } );
      }
    };
  }, [ svgRef, defaultSVGScale, svgScale, svgPanShift, svgDimensions ] );

  // 3) When an item is clicked on ( set as selectedItem ), pull up the sidebar/modal for edit/info/etc.
  React.useEffect( () => {
    if ( isNotEmpty( selectedItem ) && isNotEmpty( selectedItemType ) ) {
      setPathsFromHere( null );
      setShowItemModal( true );
    } else {
      setFocusNodes( [] );
      setFocusEdges( [] );
      setShowItemModal( false );
    }
  }, [ selectedItem, selectedItemType ] );

  // 4) Whenever the page resizes, redraw the graph
  React.useEffect( () => {
    redrawGraph( savedLayout );
  }, [ svgDimensions ] );

  // when the user closes the recordCard
  const onCloseCallback = () => {
    setSelectedItem( null );
    setSelectingSecondaryItem( null );
    setPathsFromHere( null );
    setShowPathsFromHere( false );
  };

  const hasPannedOrZoomed = () => !isEqual( defaultSVGPanShift, svgPanShift ) || !isEqual( defaultSVGScale, svgScale );

  // when the graph is zoomed in, the user can click the button that
  // appears and it will reset to the default (page load)
  const resetGraphZoom = () => {
    const rect = getEnclosingRectangle( Object.values( graphData.parentScopes ), false );
    fitToScreen( rect, false, null, true );
  };

  return (
    <React.Fragment>
      <EmptyLoading
        payload={graphData}
        loading={ loadingGraph }
        emptyMessage="Your graph is currently empty, use the filters to select items to add"
      />
      <Dialog
        visible={ isNotEmpty( selectingSecondaryItem ) }
        setVisible={ () => setSelectingSecondaryItem( null ) }
        content="Please select another node in the graph"
        onCancel={ () => setSelectingSecondaryItem( null ) }
        type="info"
        elementClass="pathsFromHereDialog"
      />
      {
        ( isZoomed || hasPannedOrZoomed() ) &&
        <button className="resetZoomButton" onClick={ resetGraphZoom } >
          Reset Graph
        </button>
      }
      <svg
        id="manualPackingGraph"
        // eslint-disable-next-line max-len
        className={ `${isEmpty( graphData ) ? 'emptyGraph' : ''} ${ isScrollZooming ? 'isScrollZooming' : '' } ${ isPanning ? 'isPanning' : '' }` }
        width={ svgDimensions.w }
        height={ svgDimensions.h }
        ref={ svgRef }
        onMouseDown={ handleSVGMouseDown }
        onMouseUp={ handleSVGMouseUp }
        onMouseMove={ handleSVGMouseMove }
        onMouseLeave={ handleSVGMouseLeave }
        onDoubleClick={ e => {
          e.preventDefault();
          e.stopPropagation();
          handleSVGDoubleClick( e );
          return false;
        }}
        // need to prevent here before passed to the handler or it will still trigger the default right-click menu
        onContextMenu={ e => {
          e.preventDefault();
          e.stopPropagation();
          handleSVGRightClick( e );
          return false;
        } }
      >
        <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <pattern id="smallGrid" width="10" height="10" patternUnits="userSpaceOnUse">
              <path
                d="M 10 0 L 0 0 0 10"
                fill="none"
                strokeOpacity={0.4}
                stroke={ globalColors['grey--icon'] }
                strokeWidth="0.5"
              />
            </pattern>
            <pattern id="grid" width="100" height="100" patternUnits="userSpaceOnUse">
              <rect width="100" height="100" fill="url(#smallGrid)"/>
              <path
                d="M 100 0 L 0 0 0 100"
                fill="none"
                strokeOpacity={0.4}
                stroke={ globalColors['grey--icon'] }
                strokeWidth="1"
              />
            </pattern>
          </defs>

          <rect className="gaphGridBG" width="100%" height="100%" fill="url(#grid)" />
        </svg>
        {
          isNotEmpty( graphData ) &&
          <g
            id="panZoomWrapper"
            // eslint-disable-next-line max-len
            transform={ `translate(${svgPanShift.x} ${svgPanShift.y}) scale(${svgScale})` }
          >
            {
              isNotEmpty( graphData.parentScopes ) &&
              Object.values( graphData.parentScopes ).map( ( parentScope, index ) => {
                return <GraphScope
                  key={ `${index}_${parentScope.id}` }
                  scope={parentScope}
                  handleItemMouseDown={ handleItemMouseDown }
                  handleItemMouseUp={ handleItemMouseUp }
                  externalHoverIDs={ externalHoverIDs }
                  selectedItem={ selectedItem }
                  setSelectedItem={ setSelectedItem }
                  setSelectedItemType={ setSelectedItemType }
                  ignoreClick={ ignoreClick }
                  setIgnoreClick={ setIgnoreClick }
                  setSVGScale={ setSVGScale }
                  svgPanShift={ svgPanShift }
                  setSVGPanShift={ setSVGPanShift }
                  svgDimensions={ svgDimensions }
                  collapsedGraphMenu={ collapsedGraphMenu }
                  setIsZoomed={ setIsZoomed }
                  setContextMenuItem={ setContextMenuItem }
                  setContextMenuType={ setContextMenuType }
                />;
              } )
            }
            {
              isNotEmpty( graphData.childScopes ) &&
              Object.values( graphData.childScopes ).map( ( childScope, index ) => {
                return <GraphScope
                  key={ `${index}_${childScope.id}` }
                  scope={childScope}
                  handleItemMouseDown={ handleItemMouseDown }
                  handleItemMouseUp={ handleItemMouseUp }
                  externalHoverIDs={ externalHoverIDs }
                  selectedItem={ selectedItem }
                  setSelectedItem={ setSelectedItem }
                  setSelectedItemType={ setSelectedItemType }
                  ignoreClick={ ignoreClick }
                  setIgnoreClick={ setIgnoreClick }
                  setSVGScale={ setSVGScale }
                  svgPanShift={ svgPanShift }
                  setSVGPanShift={ setSVGPanShift }
                  svgDimensions={ svgDimensions }
                  collapsedGraphMenu={ collapsedGraphMenu }
                  setIsZoomed={ setIsZoomed }
                  setContextMenuItem={ setContextMenuItem }
                  setContextMenuType={ setContextMenuType }
                />;
              } )
            }
            {
              isNotEmpty( graphData.edges ) &&
              Object.values( graphData.edges ).map( ( edge, index ) => {
                return <GraphEdge
                  key={ `${index}_${edge.id}` }
                  edge={ edge }
                  nodes={ graphData.nodes }
                  focusEdges={ focusEdges }
                  setFocusEdges={ setFocusEdges }
                  focusNodes={ focusNodes }
                  setFocusNodes={ setFocusNodes }
                  externalHoverIDs={ externalHoverIDs }
                  setSelectedItem={ setSelectedItem }
                  setSelectedItemType={ setSelectedItemType }
                  setIsZoomed={ setIsZoomed }
                  ignoreClick={ ignoreClick }
                  setIgnoreClick={ setIgnoreClick }
                  setContextMenuItem={ setContextMenuItem }
                  setContextMenuType={ setContextMenuType }
                />;
              } )
            }
            {
              isNotEmpty( graphData.nodes ) &&
              Object.values( graphData.nodes ).map( ( node, index ) => {
                return <GraphNode
                  key={ `${index}_${node.id}` }
                  node={ node }
                  handleItemMouseDown={ handleItemMouseDown }
                  handleItemMouseUp={ handleItemMouseUp }
                  focusNodes={ focusNodes }
                  setFocusNodes={ setFocusNodes }
                  focusEdges={ focusEdges }
                  setFocusEdges={ setFocusEdges }
                  externalHoverIDs={ externalHoverIDs }
                  setSelectedItem={ setSelectedItem }
                  setSelectedItemType={ setSelectedItemType }
                  selectingSecondaryItem={selectingSecondaryItem}
                  setSelectingSecondaryItem={setSelectingSecondaryItem}
                  ignoreClick={ ignoreClick }
                  setIgnoreClick={ setIgnoreClick }
                  setSVGScale={ setSVGScale }
                  svgPanShift={ svgPanShift }
                  setSVGPanShift={ setSVGPanShift }
                  svgDimensions={ svgDimensions }
                  collapsedGraphMenu={ collapsedGraphMenu }
                  setIsZoomed={ setIsZoomed }
                  setContextMenuItem={ setContextMenuItem }
                  setContextMenuType={ setContextMenuType }
                  firstClickedItem={firstClickedItem}
                  setFirstClickedItem={setFirstClickedItem}
                />;
              } )
            }
          </g>
        }
      </svg>
      <RecordCard
        record={selectedItem}
        type={selectedItemType}
        context="explore"
        show={ showItemModal }
        setShow={ setShowItemModal }
        options={ {
          isDraggable: true,
          isDismissable: true,
          isCollapsible: true,
        } }
        onCloseCallback={ onCloseCallback }
        editItem={editItem}
        deleteItem={deleteItem}
        copyItem={copyItem}
        selectItem={selectItem}
        addSegment={addSegment}
        setSelectingSecondaryItem={setSelectingSecondaryItem}
        pathsFromHere={pathsFromHere}
        showPathsFromHere={showPathsFromHere}
        setExternalHoverIDs={setExternalHoverIDs}
        displayExploreItemNameFor={displayExploreItemNameFor}
        collapsed={collapsedRecordCard}
        setCollapsed={setCollapsedRecordCard}
        selectedPathID={ selectedPathID }
        setSelectedPathID={ setSelectedPathID }
      />
    </React.Fragment>
  );
};

export default Graph;