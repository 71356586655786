/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import InlineSVG from '../../../shared/InlineSVG';

import './SubordinateScannerItem.scss';
import { CurrentUserContext } from '../../../Contexts/CurrentUser';
import { canConfigure } from '../../App/AccessControl';

const ScannerStatus = ( { scanner } ) => {
  return (
    <React.Fragment>
      {
        scanner.status === 'unregistered' &&
        <InlineSVG type="warning" version="status--red" elementClass="unregistered" />
      }
      {
        scanner.status === 'online' &&
        <InlineSVG type="online" version="status--green" elementClass="online" />
      }
      {
        scanner.status === 'offline' &&
        <InlineSVG type="offline" elementClass="offline" />
      }
    </React.Fragment>
  );
};

const SubordinateScannerItem = ( { scanner, showRegistrationModal } ) => {

  const [ , , licenseInfo ] = React.useContext( CurrentUserContext );

  return (
    <React.Fragment>
      <section className="mainDetailsSection">
        <div className="column">
          <h2>
            <ScannerStatus scanner={scanner} />
            { scanner.label }
            {
              scanner.status === 'unregistered' &&
              <button
                disabled={ !canConfigure( licenseInfo ) }
                onClick={ () => showRegistrationModal( scanner ) }>
                Register Now
              </button>
            }
          </h2>
          <span>
            <strong>Type: </strong>
            { scanner.type }
          </span>
        </div>
      </section>
      <section>
        <strong className="sectionLabel">
          Assigned to Scan Groups
        </strong>
        <span className="sectionDetails">
          { scanner.scanGroupLabels.length ? scanner.scanGroupLabels.join( ', ' ) : 'None' }
        </span>
      </section>
    </React.Fragment>
  );
};

export default SubordinateScannerItem;