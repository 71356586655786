/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

/* eslint-disable camelcase */

import {
  isFalsey,
  isNotEmpty,
} from '../../shared/Utilities';

export const licenseFeatureKeys = [
  'f_remediation',
  'f_risk_acceptance',
  'f_general_configuration',
  'f_explore_model',
  'f_credentialed_scan',
  'f_subordinates',
  'f_third_party_manual',
  'f_customer_api',
  'f_auth_providers',
  'f_cloud_integrations',
  'f_smtp_configuration',
  'f_certificate_configuration',
  'f_outbound_proxy',
  'f_custom_dashboards',
  'f_analytics',
  'f_multitenant_cluster',
];


export const pageToFeatureKeyMap = {
  remediation_plans: 'f_remediation',
  remediation_settings: 'f_remediation',
  remediation_ticketing: 'f_remediation',

  accepted_risk_plans: 'f_risk_acceptance',

  explore_model: 'f_explore_model',

  agentless_settings: 'f_credentialed_scan',
  credentials: 'f_credentialed_scan',
  scan_groups: 'f_credentialed_scan',

  subordinates: 'f_subordinates',

  providers: 'f_auth_providers',

  cloud_scanning: 'f_cloud_integrations',

  smtp: 'f_smtp_configuration',

  certificates: 'f_certificate_configuration',

  outbound_proxy: 'f_outbound_proxy',
};

// all sections of the app that require license flags to visit,
// and the associated option for the license info
/* eslint-disable camelcase */
const licenseAccessRouteMap = {
  remediation: 'license_option_remediation',
};

const licenseAccessReportOrPageMap = {
  remediation_plans: 'license_option_remediation',
  remediation_settings: 'license_option_remediation',
};

const licenseAccessFeatureMap = {
  remediationWorkflow: 'license_option_remediation',
};
/* eslint-enable camelcase */

// nice shortcut method for the general configuation featurekey
export const canConfigure = ( licenseInfo ) => {
  return hasFeatureAccess( 'f_general_configuration', licenseInfo );
};

// nice shortcut method for if the feature is enabled or not
export const hasFeatureAccess = ( featureKey, licenseInfo ) => {
  return featureAccessLevel( featureKey, licenseInfo ) === 'enabled';
};

export const featureAccessLevel = ( featureKey='', licenseInfo ) => {
  if (
    isNotEmpty( featureKey )
    && licenseFeatureKeys.includes( featureKey )
    && isNotEmpty( licenseInfo )
    && isNotEmpty( licenseInfo.license_effective_edition )
  ) {
    return licenseInfo.license_effective_edition[featureKey];
  }
  return 'enabled';
};

export const hasAccess = ( licenseInfo, currentLocation, restrictBy='route', featureKey='' ) => {
  if ( isNotEmpty( licenseInfo ) ) {
    switch ( restrictBy ) {
    case 'route':
      if ( isNotEmpty( currentLocation ) ) {
        const accessKey = licenseInfo[licenseAccessRouteMap[currentLocation.route]];
        // this accessKey does not exist, therefore it is not a restricted feature, allow access
        if ( accessKey === undefined ) {
          return true;
        }
        // this accessKey does exist and it is set to false, restrict access
        if ( isFalsey( accessKey ) ) {
          return false;
        }
        // this accessKey exists and is set to true
        return true;
      }
      return false;
    case 'reportOrPage':
      if ( isNotEmpty( currentLocation ) ) {
        const reportOrPage = currentLocation.page ? currentLocation.page : currentLocation.report;
        const accessKey = licenseInfo[licenseAccessReportOrPageMap[reportOrPage]];
        // this accessKey does not exist, therefore it is not a restricted feature, allow access
        if ( accessKey === undefined ) {
          return true;
        }
        // this accessKey does exist and it is set to false, restrict access
        if ( isFalsey( accessKey ) ) {
          return false;
        }
        // this accessKey exists and is set to true
        return true;
      }
      return false;
    case 'feature':
      if ( isNotEmpty( featureKey ) ) {
        const accessKey = licenseInfo[licenseAccessFeatureMap[featureKey]];
        // this accessKey does not exist, therefore it is not a restricted feature, allow access
        if ( accessKey === undefined ) {
          return true;
        }
        // this accessKey does exist and it is set to false, restrict access
        if ( isFalsey( accessKey ) ) {
          return false;
        }
        // this accessKey exists and is set to true
        return true;
      }
      return false;
    default:
      return false;
    }
  // they have no license
  }
  return false;
};