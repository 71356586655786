/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { complianceFrameworkLabelMap, isEmpty, isNotEmpty } from '../../../shared/Utilities';
import { getFieldValues } from '../../../shared/Form/Shared';
import Modal from '../../../shared/Modal';
import Form from '../../../shared/Form';
import { v4 as uuidv4 } from 'uuid';

import './WidgetEditorModal.scss';
import EmptyState from '../../../shared/EmptyState';
import { TagsContext } from '../../../Contexts/Tags';
import { HelpTrigger } from '../../HelpDocumentation/ContextualHelp';
import { makeRequest } from '../../../../legacy/io';

const WidgetSelectorBody = ( {
  selectedWidgetVariant,
  setSelectedWidgetVariant,
  selectedWidgetCategory,
  setRecordLabel,
  currentWidget,
  setUpdatedSettings,
  groupedComplianceData,
  complianceFieldsOptions,
} ) => {

  const [ tags ] = React.useContext( TagsContext );

  const [ variantOptions, setVariantOptions ] = React.useState( null );
  const [ variantFields, setVariantFields ] = React.useState( null );

  // for the specific remediation plan select field
  const [ remediationPlanOptions, setRemediationPlanOptions ] = React.useState( null );

  /* eslint-disable camelcase */
  const EMPTY_VARIANT_FIELDS = {
    // risk insight/standard report variants ( hosts, patches, vulnerabilities, users )
    hosts_specific: {
      fields: [
        {
          type: 'searchResults',
          selectCallback: ( result, field, onChange ) => saveRecordLabel( result, field, onChange ),
          attribute: 'record_id_host',
          label: 'Specific Host',
          placeholder: 'Find host by name...',
          recordType: 'host',
          required: true,
          defaultValue: '',
        },
        {
          type: 'checkbox',
          label: 'Include risk scores and ratings?',
          attribute: 'include_risk',
          defaultValue: true,
          required: false,
        },
        {
          type: 'checkbox',
          label: 'Include Vulnerability Instances Information?',
          attribute: 'include_instances',
          defaultValue: true,
          required: false,
        },
        {
          type: 'checkbox',
          label: 'Include CVSS score(s) and information?',
          attribute: 'include_cvss',
          defaultValue: false,
          required: false,
        },
        {
          type: 'hidden',
          attribute: 'report_type',
          required: true,
          defaultValue: 'host',
        },
      ],
    },
    hosts_top_details: {
      fields: [
        {
          type: 'select',
          label: 'Record Ordering Method',
          attribute: 'order_by',
          defaultValue: 'filtered_risk',
          required: true,
          options: {
            // eslint-disable-next-line camelcase
            filtered_risk: 'Risk',
            // eslint-disable-next-line camelcase
            num_vulnerabilities: 'Amount of Vulnerabilities',
          },
        },
        {
          type: 'select',
          label: 'Number of Results',
          attribute: 'item_count',
          defaultValue: 3,
          required: true,
          options: {
            3: '3',
            5: '5',
          },
        },
        {
          type: 'select',
          label: 'Widget Orientation',
          attribute: 'orientation',
          defaultValue: 'vertical',
          required: true,
          options: {
            vertical: 'Vertical',
            horizontal: 'Horizontal',
          },
        },
        {
          type: 'checkbox',
          label: 'Include risk scores and ratings?',
          attribute: 'include_risk',
          defaultValue: true,
          required: false,
        },
        {
          type: 'checkbox',
          label: 'Include Vulnerability Instances Information?',
          attribute: 'include_instances',
          defaultValue: true,
          required: false,
        },
        {
          type: 'checkbox',
          label: 'Include CVSS score(s) and information?',
          attribute: 'include_cvss',
          defaultValue: false,
          required: false,
        },
        {
          type: 'hidden',
          attribute: 'report_type',
          required: true,
          defaultValue: 'hosts',
        },
      ],
    },
    hosts_priority: {
      fields: [
        {
          type: 'select',
          label: 'Tag(s)',
          attribute: 'asset_tag_ids',
          asMultiple: true,
          value: [],
          allowBlank: true,
          help: <HelpTrigger helpKey="tag_dashboard" />,
        },
        {
          type: 'select',
          label: 'Widget Version',
          attribute: 'version',
          defaultValue: 'table',
          required: true,
          options: {
            table: 'Table',
            list: 'List',
            barchart: 'Bar Chart',
          },
        },
        {
          includeIf: { attribute: 'version', value: 'table' },
          type: 'checkbox',
          label: 'Include risk scores and ratings?',
          attribute: 'include_risk',
          defaultValue: true,
          required: false,
        },
        {
          includeIf: { attribute: 'version', value: 'table' },
          type: 'checkbox',
          label: 'Include last user?',
          attribute: 'include_user',
          defaultValue: false,
        },
        {
          includeIf: { attribute: 'version', value: 'table' },
          type: 'checkbox',
          label: 'Include OS Type?',
          attribute: 'include_os_type',
          defaultValue: false,
        },
        {
          type: 'select',
          label: 'Record Ordering Method',
          attribute: 'order_by',
          defaultValue: 'filtered_risk',
          required: true,
          options: {
            // eslint-disable-next-line camelcase
            filtered_risk: 'Risk',
            // eslint-disable-next-line camelcase
            num_vulnerabilities: 'Amount of Vulnerabilities',
          },
        },
        {
          type: 'select',
          label: 'Number of Results',
          attribute: 'item_count',
          defaultValue: 25,
          required: true,
          options: {
            10: '10',
            25: '25',
            50: '50',
            100: '100',
          },
        },
        {
          type: 'hidden',
          attribute: 'report_type',
          required: true,
          defaultValue: 'hosts',
        },
      ],
    },
    hosts_global: {
      fields: [
        {
          type: 'checkbox',
          label: 'Include Description?',
          attribute: 'include_description',
          defaultValue: true,
          required: false,
        },
        {
          type: 'hidden',
          attribute: 'report_type',
          required: true,
          defaultValue: 'hosts',
        },
      ],
    },
    hosts_over_time: {
      fields: [
        {
          includeIf: { attribute: 'include_tag_breakdown', value: true },
          type: 'checkbox',
          label: 'Include Description?',
          attribute: 'include_description',
          defaultValue: true,
          required: false,
        },
        {
          type: 'select',
          label: 'Duration',
          attribute: 'duration',
          defaultValue: 'year',
          options: {
            week: 'This Week',
            month: 'This Month',
            quarter: 'This Quarter',
            year: 'This Year',
          },
          required: true,
        },
        {
          type: 'checkbox',
          label: 'Include Tag Breakdown?',
          attribute: 'include_tag_breakdown',
          defaultValue: false,
          required: false,
        },
        {
          includeIf: { attribute: 'include_tag_breakdown', value: true },
          type: 'select',
          label: 'Tag(s)',
          attribute: 'asset_tag_ids',
          asMultiple: true,
          value: [],
          allowBlank: true,
          help: <HelpTrigger helpKey="tag_dashboard" />,
        },
        // {
        //   includeIf: { attribute: 'include_tag_breakdown', value: true },
        //   type: 'select',
        //   label: 'Stacked Area Chart Version',
        //   attribute: 'count_version_tag',
        //   defaultValue: 'percent',
        //   required: true,
        //   options: {
        //     percent: 'Percentage of Total',
        //     count: 'Raw counts',
        //   },
        // },
        {
          type: 'checkbox',
          label: 'Include Chart Legend?',
          attribute: 'include_legend',
          defaultValue: true,
          required: false,
        },
        {
          type: 'hidden',
          attribute: 'report_type',
          required: true,
          defaultValue: 'hosts',
        },
      ],
    },
    hosts_comparison: {
      fields: [
        {
          includeIf: [
            { attribute: 'include_tag_breakdown', value: true },
            { attribute: 'comparison_version', value: 'barchart' },
          ],
          type: 'checkbox',
          label: 'Include Description?',
          attribute: 'include_description',
          defaultValue: true,
          required: false,
        },
        {
          type: 'select',
          label: 'Widget Version',
          attribute: 'comparison_version',
          defaultValue: 'barchart',
          options: {
            barchart: 'Bar Chart',
            number: 'Number w/text',
          },
          required: true,
        },
        {
          type: 'select',
          label: 'Compare against',
          attribute: 'comparison_date',
          defaultValue: 'month',
          options: {
            day: 'Yesterday',
            week: 'Last Week',
            month: 'Last Month',
            quarter: 'Last Quarter',
            year: 'Last Year',
          },
          required: true,
        },
        {
          type: 'checkbox',
          label: 'Include Tag Breakdown?',
          attribute: 'include_tag_breakdown',
          defaultValue: false,
          required: false,
        },
        {
          includeIf: { attribute: 'include_tag_breakdown', value: true },
          type: 'select',
          label: 'Tag(s)',
          attribute: 'asset_tag_ids',
          asMultiple: true,
          value: [],
          allowBlank: true,
          help: <HelpTrigger helpKey="tag_dashboard" />,
        },
        {
          type: 'hidden',
          attribute: 'report_type',
          required: true,
          defaultValue: 'hosts',
        },
      ],
    },
    hosts_total: {
      fields: [
        {
          type: 'hidden',
          attribute: 'report_type',
          required: true,
          defaultValue: 'hosts',
        },
      ],
    },

    patches_specific: {
      fields: [
        {
          type: 'searchResults',
          selectCallback: ( result, field, onChange ) => saveRecordLabel( result, field, onChange ),
          attribute: 'record_id_patch',
          label: 'Specific Patch',
          placeholder: 'Find patch by name...',
          recordType: 'patch',
          required: true,
          defaultValue: '',
        },
        {
          type: 'checkbox',
          label: 'Include risk scores and ratings?',
          attribute: 'include_risk',
          defaultValue: true,
          required: false,
        },
        {
          type: 'checkbox',
          label: 'Include Vulnerability Instances Information?',
          attribute: 'include_instances',
          defaultValue: true,
          required: false,
        },
        {
          type: 'checkbox',
          label: 'Include CVSS score(s) and information?',
          attribute: 'include_cvss',
          defaultValue: false,
          required: false,
        },
        {
          type: 'hidden',
          attribute: 'report_type',
          required: true,
          defaultValue: 'patch',
        },
      ],
    },
    patches_priority: {
      fields: [
        {
          type: 'select',
          label: 'Tag(s)',
          attribute: 'asset_tag_ids',
          asMultiple: true,
          value: [],
          allowBlank: true,
          help: <HelpTrigger helpKey="tag_dashboard" />,
        },
        {
          type: 'select',
          label: 'Widget Version',
          attribute: 'version',
          defaultValue: 'table',
          required: true,
          options: {
            table: 'Table',
            list: 'List',
            barchart: 'Bar Chart',
          },
        },
        {
          includeIf: { attribute: 'version', value: 'table' },
          type: 'checkbox',
          label: 'Include risk scores and ratings?',
          attribute: 'include_risk',
          defaultValue: true,
          required: false,
        },
        {
          includeIf: [
            { attribute: 'version', value: 'table' },
            { attribute: 'version', value: 'list' },
          ],
          type: 'checkbox',
          label: 'Include CVSS score(s) and information?',
          attribute: 'include_cvss',
          defaultValue: false,
          required: false,
        },
        {
          type: 'select',
          label: 'Number of Results',
          attribute: 'item_count',
          defaultValue: 25,
          required: true,
          options: {
            10: '10',
            25: '25',
            50: '50',
            100: '100',
          },
        },
        {
          type: 'hidden',
          attribute: 'report_type',
          required: true,
          defaultValue: 'patches',
        },
      ],
    },
    patches_top_details: {
      fields: [
        {
          type: 'select',
          label: 'Record Ordering Method',
          attribute: 'order_by',
          defaultValue: 'filtered_risk',
          required: true,
          options: {
            // eslint-disable-next-line camelcase
            filtered_risk: 'Risk',
            // eslint-disable-next-line camelcase
            num_vulnerabilities: 'Amount of Vulnerabilities',
            // eslint-disable-next-line camelcase
            num_hosts: 'Amount of Affected Hosts',
          },
        },
        {
          type: 'select',
          label: 'Number of Results',
          attribute: 'item_count',
          defaultValue: 3,
          required: true,
          options: {
            3: '3',
            5: '5',
          },
        },
        {
          type: 'select',
          label: 'Widget Orientation',
          attribute: 'orientation',
          defaultValue: 'vertical',
          required: true,
          options: {
            vertical: 'Vertical',
            horizontal: 'Horizontal',
          },
        },
        {
          type: 'checkbox',
          label: 'Include risk scores and ratings?',
          attribute: 'include_risk',
          defaultValue: true,
          required: false,
        },
        {
          type: 'checkbox',
          label: 'Include Vulnerability Instances Information?',
          attribute: 'include_instances',
          defaultValue: true,
          required: false,
        },
        {
          type: 'checkbox',
          label: 'Include CVSS score(s) and information?',
          attribute: 'include_cvss',
          defaultValue: false,
          required: false,
        },
        {
          type: 'hidden',
          attribute: 'report_type',
          required: true,
          defaultValue: 'patches',
        },
      ],
    },
    patches_global: {
      fields: [
        {
          type: 'checkbox',
          label: 'Include Description?',
          attribute: 'include_description',
          defaultValue: true,
          required: false,
        },
        {
          type: 'hidden',
          attribute: 'report_type',
          required: true,
          defaultValue: 'patches',
        },
      ],
    },
    patches_over_time: {
      fields: [
        {
          includeIf: { attribute: 'include_tag_breakdown', value: true },
          type: 'checkbox',
          label: 'Include Description?',
          attribute: 'include_description',
          defaultValue: true,
          required: false,
        },
        {
          type: 'select',
          label: 'Duration',
          attribute: 'duration',
          defaultValue: 'year',
          options: {
            week: 'This Week',
            month: 'This Month',
            quarter: 'This Quarter',
            year: 'This Year',
          },
          required: true,
        },
        {
          type: 'checkbox',
          label: 'Include Tag Breakdown?',
          attribute: 'include_tag_breakdown',
          defaultValue: false,
          required: false,
        },
        {
          includeIf: { attribute: 'include_tag_breakdown', value: true },
          type: 'select',
          label: 'Tag(s)',
          attribute: 'asset_tag_ids',
          asMultiple: true,
          value: [],
          allowBlank: true,
          help: <HelpTrigger helpKey="tag_dashboard" />,
        },
        // {
        //   includeIf: { attribute: 'include_tag_breakdown', value: true },
        //   type: 'select',
        //   label: 'Stacked Area Chart Version',
        //   attribute: 'count_version_tag',
        //   defaultValue: 'percent',
        //   required: true,
        //   options: {
        //     percent: 'Percentage of Total',
        //     count: 'Raw counts',
        //   },
        // },
        {
          type: 'checkbox',
          label: 'Include Chart Legend?',
          attribute: 'include_legend',
          defaultValue: true,
          required: false,
        },
        {
          type: 'hidden',
          attribute: 'report_type',
          required: true,
          defaultValue: 'patches',
        },
      ],
    },
    patches_comparison: {
      fields: [
        {
          includeIf: [
            { attribute: 'include_tag_breakdown', value: true },
            { attribute: 'comparison_version', value: 'barchart' },
          ],
          type: 'checkbox',
          label: 'Include Description?',
          attribute: 'include_description',
          defaultValue: true,
          required: false,
        },
        {
          type: 'select',
          label: 'Widget Version',
          attribute: 'comparison_version',
          defaultValue: 'barchart',
          options: {
            barchart: 'Bar Chart',
            number: 'Number w/text',
          },
          required: true,
        },
        {
          type: 'select',
          label: 'Compare against',
          attribute: 'comparison_date',
          defaultValue: 'month',
          options: {
            day: 'Yesterday',
            week: 'Last Week',
            month: 'Last Month',
            quarter: 'Last Quarter',
            year: 'Last Year',
          },
          required: true,
        },
        {
          type: 'checkbox',
          label: 'Include Tag Breakdown?',
          attribute: 'include_tag_breakdown',
          defaultValue: false,
          required: false,
        },
        {
          includeIf: { attribute: 'include_tag_breakdown', value: true },
          type: 'select',
          label: 'Tag(s)',
          attribute: 'asset_tag_ids',
          asMultiple: true,
          value: [],
          allowBlank: true,
          help: <HelpTrigger helpKey="tag_dashboard" />,
        },
        {
          type: 'hidden',
          attribute: 'report_type',
          required: true,
          defaultValue: 'patches',
        },
      ],
    },
    patches_total: {
      fields: [
        {
          type: 'hidden',
          attribute: 'report_type',
          required: true,
          defaultValue: 'patches',
        },
      ],
    },

    vulnerabilities_specific: {
      fields: [
        {
          type: 'searchResults',
          selectCallback: ( result, field, onChange ) => saveRecordLabel( result, field, onChange ),
          attribute: 'record_id_vulnerability',
          label: 'Specific Vulnerability',
          placeholder: 'Find vulnerability by name...',
          recordType: 'vulnerability',
          required: true,
          defaultValue: '',
        },
        {
          type: 'checkbox',
          label: 'Include risk scores and ratings?',
          attribute: 'include_risk',
          defaultValue: true,
          required: false,
        },
        {
          type: 'checkbox',
          label: 'Include Vulnerability Instances Information?',
          attribute: 'include_instances',
          defaultValue: true,
          required: false,
        },
        {
          type: 'checkbox',
          label: 'Include CVSS score(s) and information?',
          attribute: 'include_cvss',
          defaultValue: false,
          required: false,
        },
        {
          type: 'hidden',
          attribute: 'report_type',
          required: true,
          defaultValue: 'vulnerability',
        },
      ],
    },
    vulnerabilities_priority: {
      fields: [
        {
          type: 'select',
          label: 'Tag(s)',
          attribute: 'asset_tag_ids',
          asMultiple: true,
          value: [],
          allowBlank: true,
          help: <HelpTrigger helpKey="tag_dashboard" />,
        },
        {
          type: 'select',
          label: 'Widget Version',
          attribute: 'version',
          defaultValue: 'table',
          required: true,
          options: {
            table: 'Table',
            list: 'List',
            barchart: 'Bar Chart',
          },
        },
        {
          includeIf: { attribute: 'version', value: 'table' },
          type: 'checkbox',
          label: 'Include risk scores and ratings?',
          attribute: 'include_risk',
          defaultValue: true,
          required: false,
        },
        {
          includeIf: { attribute: 'version', value: 'table' },
          type: 'checkbox',
          label: 'Include CVSS score(s) and information?',
          attribute: 'include_cvss',
          defaultValue: false,
          required: false,
        },
        {
          type: 'select',
          label: 'Record Ordering Method',
          attribute: 'order_by',
          defaultValue: 'filtered_risk',
          required: true,
          options: {
            // eslint-disable-next-line camelcase
            filtered_risk: 'Risk',
            // eslint-disable-next-line camelcase
            cvss_base_score: 'CVSS Score',
            // eslint-disable-next-line camelcase
            exploit_status: 'Exploit Status',
            // eslint-disable-next-line camelcase
            num_hosts: 'Affected Hosts',

            // eslint-disable-next-line camelcase
            cvss_exploit: 'CVSS Score followed by Exploit Status',
            // eslint-disable-next-line camelcase
            cvss_hosts: 'CVSS Score followed by Affected Hosts',

            // eslint-disable-next-line camelcase
            exploit_cvss: 'Exploit Status followed by CVSS Score',
            // eslint-disable-next-line camelcase
            exploit_hosts: 'Exploit Status followed by Affected Hosts',

            // eslint-disable-next-line camelcase
            hosts_cvss: 'Affected Hosts followed by CVSS Score',
            // eslint-disable-next-line camelcase
            hosts_exploit: 'Affected Hosts followed by Exploit Status',
          },
        },
        {
          type: 'select',
          label: 'Number of Results',
          attribute: 'item_count',
          defaultValue: 25,
          required: true,
          options: {
            10: '10',
            25: '25',
            50: '50',
            100: '100',
          },
        },
        {
          type: 'hidden',
          attribute: 'report_type',
          required: true,
          defaultValue: 'vulnerabilities',
        },
      ],
    },
    vulnerabilities_top_details: {
      fields: [
        {
          type: 'select',
          label: 'Record Ordering Method',
          attribute: 'order_by',
          defaultValue: 'filtered_risk',
          required: true,
          options: {
            // eslint-disable-next-line camelcase
            filtered_risk: 'Risk',
            // eslint-disable-next-line camelcase
            cvss_base_score: 'CVSS Score',
            // eslint-disable-next-line camelcase
            exploit_status: 'Exploit Status',
            // eslint-disable-next-line camelcase
            num_hosts: 'Affected Hosts',

            // eslint-disable-next-line camelcase
            cvss_exploit: 'CVSS Score followed by Exploit Status',
            // eslint-disable-next-line camelcase
            cvss_hosts: 'CVSS Score followed by Affected Hosts',

            // eslint-disable-next-line camelcase
            exploit_cvss: 'Exploit Status followed by CVSS Score',
            // eslint-disable-next-line camelcase
            exploit_hosts: 'Exploit Status followed by Affected Hosts',

            // eslint-disable-next-line camelcase
            hosts_cvss: 'Affected Hosts followed by CVSS Score',
            // eslint-disable-next-line camelcase
            hosts_exploit: 'Affected Hosts followed by Exploit Status',
          },
        },
        {
          type: 'select',
          label: 'Number of Results',
          attribute: 'item_count',
          defaultValue: 3,
          required: true,
          options: {
            3: '3',
            5: '5',
          },
        },
        {
          type: 'select',
          label: 'Widget Orientation',
          attribute: 'orientation',
          defaultValue: 'vertical',
          required: true,
          options: {
            vertical: 'Vertical',
            horizontal: 'Horizontal',
          },
        },
        {
          type: 'checkbox',
          label: 'Include risk scores and ratings?',
          attribute: 'include_risk',
          defaultValue: true,
          required: false,
        },
        {
          type: 'checkbox',
          label: 'Include Vulnerability Instances Information?',
          attribute: 'include_instances',
          defaultValue: true,
          required: false,
        },
        {
          type: 'checkbox',
          label: 'Include CVSS score(s) and information?',
          attribute: 'include_cvss',
          defaultValue: false,
          required: false,
        },
        {
          type: 'hidden',
          attribute: 'report_type',
          required: true,
          defaultValue: 'vulnerabilities',
        },
      ],
    },
    vulnerabilities_global: {
      fields: [
        {
          type: 'checkbox',
          label: 'Include Description?',
          attribute: 'include_description',
          defaultValue: true,
          required: false,
        },
        {
          type: 'hidden',
          attribute: 'report_type',
          required: true,
          defaultValue: 'vulnerabilities',
        },
      ],
    },
    vulnerabilities_over_time: {
      fields: [
        {
          includeIf: { attribute: 'include_tag_breakdown', value: true },
          type: 'checkbox',
          label: 'Include Description?',
          attribute: 'include_description',
          defaultValue: true,
          required: false,
        },
        {
          type: 'select',
          label: 'Duration',
          attribute: 'duration',
          defaultValue: 'year',
          options: {
            week: 'This Week',
            month: 'This Month',
            quarter: 'This Quarter',
            year: 'This Year',
          },
          required: true,
        },
        {
          type: 'checkbox',
          label: 'Include Tag Breakdown?',
          attribute: 'include_tag_breakdown',
          defaultValue: false,
          required: false,
        },
        {
          includeIf: { attribute: 'include_tag_breakdown', value: true },
          type: 'select',
          label: 'Tag(s)',
          attribute: 'asset_tag_ids',
          asMultiple: true,
          value: [],
          allowBlank: true,
          help: <HelpTrigger helpKey="tag_dashboard" />,
        },
        // {
        //   includeIf: { attribute: 'include_tag_breakdown', value: true },
        //   type: 'select',
        //   label: 'Stacked Area Chart Version',
        //   attribute: 'count_version_tag',
        //   defaultValue: 'percent',
        //   required: true,
        //   options: {
        //     percent: 'Percentage of Total',
        //     count: 'Raw counts',
        //   },
        // },
        {
          type: 'checkbox',
          label: 'Include Chart Legend?',
          attribute: 'include_legend',
          defaultValue: true,
          required: false,
        },
        {
          type: 'hidden',
          attribute: 'report_type',
          required: true,
          defaultValue: 'vulnerabilities',
        },
      ],
    },
    vulnerabilities_comparison: {
      fields: [
        {
          includeIf: [
            { attribute: 'include_tag_breakdown', value: true },
            { attribute: 'comparison_version', value: 'barchart' },
          ],
          type: 'checkbox',
          label: 'Include Description?',
          attribute: 'include_description',
          defaultValue: true,
          required: false,
        },
        {
          type: 'select',
          label: 'Widget Version',
          attribute: 'comparison_version',
          defaultValue: 'barchart',
          options: {
            barchart: 'Bar Chart',
            number: 'Number w/text',
          },
          required: true,
        },
        {
          type: 'select',
          label: 'Compare against',
          attribute: 'comparison_date',
          defaultValue: 'month',
          options: {
            day: 'Yesterday',
            week: 'Last Week',
            month: 'Last Month',
            quarter: 'Last Quarter',
            year: 'Last Year',
          },
          required: true,
        },
        {
          type: 'checkbox',
          label: 'Include Tag Breakdown?',
          attribute: 'include_tag_breakdown',
          defaultValue: false,
          required: false,
        },
        {
          includeIf: { attribute: 'include_tag_breakdown', value: true },
          type: 'select',
          label: 'Tag(s)',
          attribute: 'asset_tag_ids',
          asMultiple: true,
          value: [],
          allowBlank: true,
          help: <HelpTrigger helpKey="tag_dashboard" />,
        },
        {
          type: 'hidden',
          attribute: 'report_type',
          required: true,
          defaultValue: 'vulnerabilities',
        },
      ],
    },
    vulnerabilities_total: {
      fields: [
        {
          type: 'hidden',
          attribute: 'report_type',
          required: true,
          defaultValue: 'vulnerabilities',
        },
      ],
    },

    // comparison variants
    compliance_current: {
      fields: [
        {
          type: 'checkbox',
          label: 'Include Description?',
          attribute: 'include_description',
          defaultValue: true,
          required: false,
        },
        {
          type: 'select',
          label: 'Regulation/Framework',
          attribute: 'regulation',
          defaultValue: '',
          options: {},
          required: true,
        },
        {
          type: 'select',
          label: 'Number of Controls',
          attribute: 'item_count',
          defaultValue: 15,
          options: {
            10: '10',
            15: '15',
            25: '25',
            50: '50',
          },
          required: true,
        },
        {
          type: 'select',
          label: 'Highest/Lowest Passing Controls',
          attribute: 'control_segment',
          defaultValue: 'highest',
          options: {
            highest: 'Highest Passing Controls',
            lowest: 'Lowest Passing Controls',
          },
          required: true,
        },
        {
          type: 'hidden',
          attribute: 'report_type',
          required: true,
          defaultValue: 'compliance',
        },
      ],
    },
    compliance_over_time: {
      fields: [
        {
          type: 'checkbox',
          label: 'Include Description?',
          attribute: 'include_description',
          defaultValue: true,
          required: false,
        },
        {
          type: 'select',
          label: 'Duration',
          attribute: 'duration',
          defaultValue: 'year',
          options: {
            week: 'This Week',
            month: 'This Month',
            quarter: 'This Quarter',
            year: 'This Year',
          },
          required: true,
        },
        {
          type: 'select',
          label: 'Regulation/Framework',
          attribute: 'regulation',
          defaultValue: '',
          options: {},
          required: true,
        },
        {
          type: 'select',
          label: 'Tracking Version',
          attribute: 'tracking_version',
          defaultValue: 'passing',
          options: {
            passing: 'Average Passing Rate Over Time',
            failing: 'Average Failure Rate Over Time',
          },
          required: true,
        },
        {
          type: 'hidden',
          attribute: 'report_type',
          required: true,
          defaultValue: 'compliance',
        },
      ],
    },
    compliance_comparison: {
      fields: [
        {
          includeIf: { attribute: 'comparison_version', value: 'barchart' },
          type: 'checkbox',
          label: 'Include Description?',
          attribute: 'include_description',
          defaultValue: true,
          required: false,
        },
        {
          type: 'select',
          label: 'Widget Version',
          attribute: 'comparison_version',
          defaultValue: 'barchart',
          options: {
            barchart: 'Bar Chart',
            number: 'Average Passing Rate w/text',
          },
          required: true,
        },
        {
          type: 'select',
          label: 'Compare against',
          attribute: 'comparison_date',
          defaultValue: 'month',
          options: {
            day: 'Yesterday',
            week: 'Last Week',
            month: 'Last Month',
            quarter: 'Last Quarter',
            year: 'Last Year',
          },
          required: true,
        },
        {
          type: 'select',
          label: 'Regulation/Framework',
          attribute: 'regulation',
          defaultValue: '',
          options: {},
          required: true,
        },
        {
          type: 'hidden',
          attribute: 'report_type',
          required: true,
          defaultValue: 'compliance',
        },
      ],
    },
    compliance_specific: {
      fields: [],
    },
    compliance_grade: {
      fields: [
        {
          type: 'select',
          label: 'Regulation/Framework',
          attribute: 'regulation',
          defaultValue: '',
          options: {},
          required: true,
        },
      ],
    },

    users_priority: {
      fields: [
        {
          type: 'select',
          label: 'Widget Version',
          attribute: 'version',
          defaultValue: 'table',
          required: true,
          options: {
            table: 'Table',
            list: 'List',
            barchart: 'Bar Chart',
          },
        },
        {
          includeIf: [
            { attribute: 'version', value: 'table' },
            { attribute: 'version', value: 'list' },
          ],
          type: 'select',
          label: 'Rating Version',
          attribute: 'rating_version',
          defaultValue: 'risk',
          required: true,
          options: {
            risk: 'Risk',
            cvss: 'CVSS Score(s)',
            both: 'Both Risk and CVSS',
          },
        },
        {
          type: 'select',
          label: 'Number of Results',
          attribute: 'item_count',
          defaultValue: 25,
          required: true,
          options: {
            10: '10',
            25: '25',
            50: '50',
            100: '100',
          },
        },
        {
          includeIf: { attribute: 'version', value: 'table' },
          type: 'checkbox',
          label: 'Include rating?',
          attribute: 'include_rating',
          defaultValue: true,
        },
        {
          type: 'hidden',
          attribute: 'report_type',
          required: true,
          defaultValue: 'users',
        },
      ],
    },

    // paths variants
    paths_specific: {
      fields: [
        {
          type: 'checkbox',
          label: 'Include Description?',
          attribute: 'include_description',
          defaultValue: true,
          required: false,
        },
        {
          type: 'select',
          label: 'Specific Record Type',
          attribute: 'report_type',
          required: true,
          defaultValue: 'host',
          options: {
            host: 'Host',
            patch: 'Patch',
            vulnerability: 'Vulnerability',
            domain_user: 'User',
          },
        },
        {
          includeIf: { attribute: 'report_type', value: 'host' },
          type: 'searchResults',
          selectCallback: ( result, field, onChange ) => saveRecordLabel( result, field, onChange ),
          attribute: 'record_id_host',
          label: 'Specific Host',
          placeholder: 'Find host by name...',
          recordType: 'scope',
          required: true,
          defaultValue: '',
        },
        {
          includeIf: { attribute: 'report_type', value: 'patch' },
          type: 'searchResults',
          selectCallback: ( result, field, onChange ) => saveRecordLabel( result, field, onChange ),
          attribute: 'record_id_patch',
          label: 'Specific Patch',
          placeholder: 'Find patch by name...',
          recordType: 'patch',
          required: true,
          defaultValue: '',
        },
        {
          includeIf: { attribute: 'report_type', value: 'vulnerability' },
          type: 'searchResults',
          selectCallback: ( result, field, onChange ) => saveRecordLabel( result, field, onChange ),
          attribute: 'record_id_vulnerability',
          label: 'Specific Vulnerability',
          placeholder: 'Find vulnerability by name...',
          recordType: 'vulnerability',
          required: true,
          defaultValue: '',
        },
        {
          includeIf: { attribute: 'report_type', value: 'domain_user' },
          type: 'searchResults',
          selectCallback: ( result, field, onChange ) => saveRecordLabel( result, field, onChange ),
          attribute: 'record_id_user',
          label: 'Specific User',
          placeholder: 'Find user by name...',
          recordType: 'domain_user',
          required: true,
          defaultValue: '',
        },
        {
          type: 'select',
          label: 'Number of Paths',
          attribute: 'item_count',
          required: true,
          defaultValue: 3,
          options: {
            1: '1',
            2: '2',
            3: '3',
            4: '4',
            5: '5',
          },
        },
      ],
    },
    paths_global: {
      fields: [
        {
          type: 'checkbox',
          label: 'Include Description?',
          attribute: 'include_description',
          defaultValue: true,
          required: false,
        },
        {
          type: 'select',
          label: 'Number of Paths',
          attribute: 'item_count',
          required: true,
          defaultValue: 5,
          options: {
            1: '1',
            2: '2',
            3: '3',
            4: '4',
            5: '5',
          },
        },
      ],
    },

    // vuln. instances variants
    vulnerability_instances_global: {
      fields: [
        {
          type: 'checkbox',
          label: 'Include Description?',
          attribute: 'include_description',
          defaultValue: true,
          required: false,
        },
        {
          type: 'select',
          label: 'Widget Version',
          attribute: 'version',
          defaultValue: 'full',
          required: true,
          options: {
            full: 'Full Version',
            truncated: 'Truncated Version (3 main categories only)',
          },
        },
      ],
    },
    vulnerability_instances_over_time: {
      fields: [
        {
          type: 'checkbox',
          label: 'Include Description?',
          attribute: 'include_description',
          defaultValue: true,
          required: false,
        },
        {
          type: 'select',
          label: 'Duration',
          attribute: 'duration',
          defaultValue: 'year',
          options: {
            week: 'This Week',
            month: 'This Month',
            quarter: 'This Quarter',
            year: 'This Year',
          },
          required: true,
        },
        {
          type: 'select',
          label: 'Data Breakdown Version',
          attribute: 'category_version',
          defaultValue: 'specific_categories',
          required: true,
          options: {
            specific_categories: 'Breakdown counts by specific categories',
            tag: 'Breakdown counts by tag(s)',
            all: 'Include only total instances count (no breakdown)',
          },
        },
        {
          includeIf: { attribute: 'category_version', value: 'specific_categories' },
          type: 'select',
          asMultiple: true,
          label: 'Included Categories',
          attribute: 'included_categories',
          defaultValue: [ 'prioritized', 'deprioritized', 'for_review' ],
          required: true,
          options: {
            // parent categories
            deprioritized: 'Deprioritized',
            for_review: 'For Review',
            prioritized: 'Prioritized',

            // child categories
            carries_risk: 'Confirmed Risk',
            not_exploitable: 'Mitigated',
            dos_only: 'Purely Denial of Service',
            unreachable: 'No Attack Path',
            missing_capability:  'Missing Capability',
            insufficient_information: 'Insufficient Information',
            cannot_model: 'Cannot Model',
            unrecognized: 'Unrecognized',
            missing_host: 'Not Scanned by DeepSurface',
            overridden: 'Overridden',
          },
        },
        {
          includeIf: { attribute: 'category_version', value: 'tag' },
          type: 'select',
          label: 'Tag(s)',
          attribute: 'asset_tag_ids',
          asMultiple: true,
          value: [],
          allowBlank: true,
          help: <HelpTrigger helpKey="tag_dashboard" />,
        },
        // {
        //   includeIf: { attribute: 'category_version', value: 'tag' },
        //   type: 'select',
        //   label: 'Stacked Area Chart Version',
        //   attribute: 'count_version_tag',
        //   defaultValue: 'percent',
        //   required: true,
        //   options: {
        //     percent: 'Percentage of Total',
        //     count: 'Raw counts',
        //   },
        // },
        // {
        //   includeIf: { attribute: 'category_version', value: 'specific_categories' },
        //   type: 'select',
        //   label: 'Stacked Area Chart Version',
        //   attribute: 'count_version',
        //   defaultValue: 'percent',
        //   required: true,
        //   options: {
        //     percent: 'Percentage of Total',
        //     count: 'Raw counts',
        //   },
        // },
        {
          type: 'checkbox',
          label: 'Include Chart Legend?',
          attribute: 'include_legend',
          defaultValue: true,
          required: false,
        },
        {
          type: 'hidden',
          attribute: 'report_type',
          required: true,
          defaultValue: 'vulnerability_instances',
        },
      ],
    },
    vulnerability_instances_comparison: {
      fields: [
        {
          includeIf: { attribute: 'comparison_version', value: 'barchart' },
          type: 'checkbox',
          label: 'Include Description?',
          attribute: 'include_description',
          defaultValue: true,
          required: false,
        },
        {
          type: 'select',
          label: 'Widget Version',
          attribute: 'comparison_version',
          defaultValue: 'barchart',
          options: {
            barchart: 'Bar Chart',
            number: 'Number w/text',
          },
          required: true,
        },
        {
          includeIf: { attribute: 'comparison_version', value: 'barchart' },
          type: 'select',
          label: 'Categories Version',
          attribute: 'category_version',
          defaultValue: 'specific_categories',
          required: true,
          options: {
            specific_categories: 'Breakdown counts by specific categories',
            tag: 'Breakdown counts by tag(s)',
            all: 'Include only total instances count (no breakdown)',
          },
        },
        {
          includeIf: [
            { attribute: 'category_version', value: 'specific_categories' },
            { attribute: 'comparison_version', value: 'barchart' },
          ],
          type: 'select',
          asMultiple: true,
          label: 'Included Categories',
          attribute: 'included_categories',
          defaultValue: [ 'prioritized', 'deprioritized', 'for_review' ],
          required: true,
          options: {
            // parent categories
            deprioritized: 'Deprioritized',
            for_review: 'For Review',
            prioritized: 'Prioritized',

            // child categories
            carries_risk: 'Confirmed Risk',
            not_exploitable: 'Mitigated',
            dos_only: 'Purely Denial of Service',
            unreachable: 'No Attack Path',
            missing_capability:  'Missing Capability',
            insufficient_information: 'Insufficient Information',
            cannot_model: 'Cannot Model',
            unrecognized: 'Unrecognized',
            missing_host: 'Not Scanned by DeepSurface',
            overridden: 'Overridden',
          },
        },
        {
          includeIf: [
            { attribute: 'category_version', value: 'tag' },
            { attribute: 'comparison_version', value: 'barchart' },
          ],
          type: 'select',
          label: 'Tag(s)',
          attribute: 'asset_tag_ids',
          asMultiple: true,
          value: [],
          allowBlank: true,
          help: <HelpTrigger helpKey="tag_dashboard" />,
        },
        {
          type: 'select',
          label: 'Compare against',
          attribute: 'comparison_date',
          defaultValue: 'month',
          options: {
            day: 'Yesterday',
            week: 'Last Week',
            month: 'Last Month',
            quarter: 'Last Quarter',
            year: 'Last Year',
          },
          required: true,
        },
        {
          type: 'hidden',
          attribute: 'report_type',
          required: true,
          defaultValue: 'vulnerability_instances',
        },
      ],
    },
    vulnerability_instances_exploit_status_breakdown: {
      fields: [
        {
          type: 'checkbox',
          label: 'Include Description?',
          attribute: 'include_description',
          defaultValue: true,
          required: false,
        },
      ],
    },
    vulnerability_instances_cvss_breakdown: {
      fields: [
        {
          type: 'checkbox',
          label: 'Include Description?',
          attribute: 'include_description',
          defaultValue: true,
          required: false,
        },
        {
          type: 'select',
          label: 'Widget Version',
          attribute: 'version',
          defaultValue: 'barchart',
          required: true,
          options: {
            barchart: 'Bar Chart',
            donut: 'Donut Chart',
          },
        },
      ],
    },
    vulnerability_instances_tag_breakdown: {
      fields: [
        {
          type: 'checkbox',
          label: 'Include Description?',
          attribute: 'include_description',
          defaultValue: true,
          required: false,
        },
        {
          type: 'select',
          label: 'Tag(s)',
          attribute: 'asset_tag_ids',
          asMultiple: true,
          value: [],
          allowBlank: true,
          help: <HelpTrigger helpKey="tag_dashboard" />,
        },
      ],
    },
    vulnerability_instances_os_family_breakdown: {
      fields: [],
    },
    vulnerability_instances_vulnerability_age_breakdown: {
      fields: [
        {
          type: 'checkbox',
          label: 'Include Description?',
          attribute: 'include_description',
          defaultValue: true,
          required: false,
        },
      ],
    },
    vulnerability_instances_table: {
      fields: [
        {
          type: 'checkbox',
          label: 'Include Description?',
          attribute: 'include_description',
          defaultValue: true,
          required: false,
        },
        {
          type: 'select',
          label: 'Group Instances by',
          attribute: 'group_by',
          defaultValue: 'host',
          required: true,
          options: {
            host: 'Host',
            patch: 'Patch',
            vulnerability: 'Vulnerability',
            signature: 'Signature',
          },
        },
        {
          type: 'select',
          label: 'Number of Results',
          attribute: 'item_count',
          defaultValue: 25,
          required: true,
          options: {
            10: '10',
            25: '25',
            50: '50',
            100: '100',
          },
        },
      ],
    },
    vulnerability_instances_category: {
      fields: [
        // {
        //   type: 'checkbox',
        //   label: 'Include Description?',
        //   attribute: 'include_description',
        //   defaultValue: false,
        //   required: false,
        // },
        {
          type: 'select',
          label: 'Instances Category',
          attribute: 'category',
          defaultValue: 'carries_risk',
          required: true,
          options: {
            deprioritized: 'Deprioritized',
            dos_only: 'Purely Denial of Service',
            not_exploitable: 'Mitigated',
            overridden: 'Overridden',

            for_review: 'For Review',
            unsupported: 'Unsupported',
            unrecognized: 'Unrecognized',
            missing_host: 'Not Scanned by DeepSurface',
            cannot_model: 'Cannot Model',
            insufficient_information: 'Insufficient Information',
            missing_capability: 'Missing Capability',

            prioritized: 'Prioritized',
            unreachable: 'No Attack Path',
            carries_risk: 'Carries Risk',
          },
        },
      ],
    },

    // risk variants
    risk_score: {
      fields: [],
    },
    risk_grade: {
      fields: [],
    },
    risk_peer_percentile: {
      fields: [
        // {
        //   type: 'checkbox',
        //   label: 'Include Description?',
        //   attribute: 'include_description',
        //   defaultValue: false,
        //   required: false,
        // },
      ],
    },
    risk_global: {
      fields: [
        {
          type: 'checkbox',
          label: 'Include Description?',
          attribute: 'include_description',
          defaultValue: true,
          required: false,
        },
        {
          type: 'select',
          label: 'Record Type',
          attribute: 'report_type',
          defaultValue: 'hosts',
          required: true,
          options: {
            hosts: 'Hosts',
            patches: 'Patches',
            vulnerabilities: 'Vulnerabilities',
          },
        },
      ],
    },
    risk_over_time: {
      fields: [
        {
          type: 'checkbox',
          label: 'Include Description?',
          attribute: 'include_description',
          defaultValue: true,
          required: false,
        },
        {
          type: 'checkbox',
          label: 'Include Instance Changes Chart?',
          attribute: 'include_escalations',
          defaultValue: true,
          required: false,
        },
        {
          type: 'checkbox',
          label: 'Include Host Changes Chart?',
          attribute: 'include_hosts',
          defaultValue: true,
          required: false,
        },
        {
          type: 'checkbox',
          label: 'Include Patch Tuesday Indicator?',
          attribute: 'patch_tuesday',
          defaultValue: false,
          required: false,
        },
        {
          type: 'select',
          label: 'Duration',
          attribute: 'duration',
          defaultValue: 90,
          required: true,
          options: {
            7: 'This Week',
            14: 'Last 2 Weeks',
            30: 'This Month',
            90: 'This Quarter',
            365: 'This Year',
            100000000: 'All Time',
          },
        },
        {
          type: 'checkbox',
          label: 'Include Chart Legend?',
          attribute: 'include_legend',
          defaultValue: true,
          required: false,
        },
      ],
    },
    risk_peer_percentile_over_time: {
      fields: [
        {
          type: 'checkbox',
          label: 'Include Description?',
          attribute: 'include_description',
          defaultValue: true,
          required: false,
        },
      ],
    },
    risk_target: {
      fields: [
        // {
        //   type: 'checkbox',
        //   label: 'Include Description?',
        //   attribute: 'include_description',
        //   defaultValue: false,
        //   required: false,
        // },
      ],
    },
    risk_simplified_over_time: {
      fields: [
        {
          includeIf: { attribute: 'include_tag_breakdown', value: true },
          type: 'checkbox',
          label: 'Include Description?',
          attribute: 'include_description',
          defaultValue: true,
          required: false,
        },
        {
          type: 'select',
          label: 'Duration',
          attribute: 'duration',
          defaultValue: 'year',
          options: {
            week: 'This Week',
            month: 'This Month',
            quarter: 'This Quarter',
            year: 'This Year',
          },
          required: true,
        },
        {
          type: 'checkbox',
          label: 'Include Tag Breakdown?',
          attribute: 'include_tag_breakdown',
          defaultValue: false,
          required: false,
        },
        {
          includeIf: { attribute: 'include_tag_breakdown', value: true },
          type: 'select',
          label: 'Tag(s)',
          attribute: 'asset_tag_ids',
          asMultiple: true,
          value: [],
          allowBlank: true,
          help: <HelpTrigger helpKey="tag_dashboard" />,
        },
        // {
        //   includeIf: { attribute: 'include_tag_breakdown', value: true },
        //   type: 'select',
        //   label: 'Stacked Area Chart Version',
        //   attribute: 'count_version_tag',
        //   defaultValue: 'percent',
        //   required: true,
        //   options: {
        //     percent: 'Percentage of Total',
        //     count: 'Raw counts',
        //   },
        // },
        {
          type: 'checkbox',
          label: 'Include Chart Legend?',
          attribute: 'include_legend',
          defaultValue: true,
          required: false,
        },
        {
          type: 'hidden',
          attribute: 'report_type',
          required: true,
          defaultValue: 'risk',
        },
      ],
    },
    risk_comparison: {
      fields: [
        {
          includeIf: [
            { attribute: 'include_tag_breakdown', value: true },
            { attribute: 'comparison_version', value: 'barchart' },
          ],
          type: 'checkbox',
          label: 'Include Description?',
          attribute: 'include_description',
          defaultValue: true,
          required: false,
        },
        {
          type: 'select',
          label: 'Widget Version',
          attribute: 'comparison_version',
          defaultValue: 'barchart',
          options: {
            barchart: 'Bar Chart',
            number: 'Number w/text',
          },
          required: true,
        },
        {
          type: 'select',
          label: 'Compare against',
          attribute: 'comparison_date',
          defaultValue: 'month',
          options: {
            day: 'Yesterday',
            week: 'Last Week',
            month: 'Last Month',
            quarter: 'Last Quarter',
            year: 'Last Year',
          },
          required: true,
        },
        {
          type: 'checkbox',
          label: 'Include Tag Breakdown?',
          attribute: 'include_tag_breakdown',
          defaultValue: false,
          required: false,
        },
        {
          includeIf: { attribute: 'include_tag_breakdown', value: true },
          type: 'select',
          label: 'Tag(s)',
          attribute: 'asset_tag_ids',
          asMultiple: true,
          value: [],
          allowBlank: true,
          help: <HelpTrigger helpKey="tag_dashboard" />,
        },
        {
          type: 'hidden',
          attribute: 'report_type',
          required: true,
          defaultValue: 'risk',
        },
      ],
    },

    // remediation variants
    remediation_plans: {
      fields: [],
    },
    remediation_specific_plan: {
      fields: [
        {
          type: 'select',
          label: 'Specific Plan',
          attribute: 'plan_id',
          defaultValue: '',
          options: {},
          required: true,
        },
      ],
    },

    scanning_age_breakdown: {
      fields: [
        {
          type: 'select',
          label: 'Scanning Method',
          attribute: 'scanning_type',
          defaultValue: 'agent',
          required: true,
          options: {
            agent: 'User Managed/Agent',
            agentless: 'Credentialed',
          },
        },
      ],
    },

    scanning_scan_group_breakdown: {
      fields: [
        {
          type: 'checkbox',
          label: 'Include Description?',
          attribute: 'include_description',
          defaultValue: true,
          required: false,
        },
      ],
    },

    scanning_agent_version_breakdown: {
      fields: [
        {
          type: 'checkbox',
          label: 'Include Description?',
          attribute: 'include_description',
          defaultValue: true,
          required: false,
        },
      ],
    },
    scanning_total: {
      fields: [
        {
          type: 'select',
          label: 'Scanning Method',
          attribute: 'scanning_type',
          defaultValue: 'agent',
          required: true,
          options: {
            agent: 'User Managed/Agent',
            agentless: 'Credentialed',
            both: 'Both',
          },
        },
      ],
    },

    // text variants
    text_free_form: {
      fields: [
        {
          type: 'text',
          label: 'Header',
          attribute: 'heading',
          placeholder: 'Header (optional)',
          defaultValue: '',
        },
        {
          type: 'textarea',
          label: 'Text',
          attribute: 'content',
          defaultValue: '',
        },
      ],
    },
  };
  /* eslint-enable camelcase */

  const saveRecordLabel = ( result, field, onChange ) => {
    if ( isNotEmpty( result ) ) {
      if ( result.identifier ) {
        if ( result.vendor ) {
          setRecordLabel( `${result.vendor} ${result.identifier}` );
        } else {
          setRecordLabel( result.identifier );
        }
        setRecordLabel( result.label );
      }
    }
    onChange( field, result.id );
  };

  React.useEffect( () => {
    if ( isNotEmpty( selectedWidgetCategory ) && isNotEmpty( selectedWidgetCategory.options ) ) {
      setVariantOptions( selectedWidgetCategory.options );
      setSelectedWidgetVariant( Object.values( selectedWidgetCategory.options )[0] );

      const _fields = EMPTY_VARIANT_FIELDS[Object.values( selectedWidgetCategory.options )[0].key];

      if ( isNotEmpty( _fields ) && isNotEmpty( _fields.fields ) ) {

        if ( isNotEmpty( complianceFieldsOptions ) ) {
          const regulationField = _fields.fields.find( i => i.attribute === 'regulation' );

          if ( isNotEmpty( regulationField ) ) {
            const regulationOptions = {};

            Object.keys( complianceFieldsOptions ).map( regulationKey => {
              regulationOptions[regulationKey] = complianceFrameworkLabelMap[regulationKey] || regulationKey;
            } );

            regulationField.options = regulationOptions;

            const [ defaultRegulationKey ] = Object.keys( complianceFieldsOptions );

            regulationField.defaultValue = defaultRegulationKey;
          }
        }

        // work through all the assetTag data and set filter options
        if ( isNotEmpty( tags ) ) {
          const tagFilter = _fields.fields.find( i => i.attribute === 'asset_tag_ids' );

          if ( isNotEmpty( tagFilter ) ) {
            tagFilter.options = tags;
          }
        }

        setVariantFields( _fields.fields );
      } else {
        setVariantFields( null );
      }
    } else {
      setVariantOptions( null );
      setSelectedWidgetVariant( null );
      setVariantFields( null );
    }
  }, [ selectedWidgetCategory, tags, groupedComplianceData ] );

  const handleVariantClick = variant => {
    if ( isNotEmpty( variant ) ) {
      if ( isNotEmpty( selectedWidgetVariant ) && selectedWidgetVariant.key === variant.key ) {
        setSelectedWidgetVariant( null );
        setVariantFields( null );
      } else {
        setSelectedWidgetVariant( variant );
        const _fields = EMPTY_VARIANT_FIELDS[variant.key];

        if ( isNotEmpty( _fields ) && isNotEmpty( _fields.fields ) ) {
          // work through all the assetTag data and set filter options
          if ( isNotEmpty( tags ) ) {
            const tagFilter = _fields.fields.find( i => i.attribute === 'asset_tag_ids' );

            if ( isNotEmpty( tagFilter ) ) {
              tagFilter.options = tags;
            }
          }

          if (
            variant.key === 'compliance_comparison'
            || variant.key === 'compliance_current'
            || variant.key === 'compliance_over_time'
            || variant.key === 'compliance_grade'
          ) {
            if ( isNotEmpty( complianceFieldsOptions ) ) {
              const regulationField = _fields.fields.find( i => i.attribute === 'regulation' );

              if ( isNotEmpty( regulationField ) ) {
                const regulationOptions = {};

                Object.keys( complianceFieldsOptions ).map( regulationKey => {
                  regulationOptions[regulationKey] = complianceFrameworkLabelMap[regulationKey] || regulationKey;
                } );

                regulationField.options = regulationOptions;

                const [ defaultRegulationKey ] = Object.keys( complianceFieldsOptions );

                regulationField.defaultValue = defaultRegulationKey;
              }
            }
          }

          // for the specific remediation plan widget, need to populate the options
          if ( variant.key === 'remediation_specific_plan' ) {
            if ( isNotEmpty( remediationPlanOptions ) ) {
              const planIDField = _fields.fields.find( i => i.attribute === 'plan_id' );

              if ( isNotEmpty( planIDField ) ) {
                planIDField.options = remediationPlanOptions;
              }
            } else {
              const planIDField = _fields.fields.find( i => i.attribute === 'plan_id' );
              if ( isNotEmpty( planIDField ) ) {
                makeRequest( 'LIST', '/project/default/model/base/remediation_plan', {} ).then( response => {
                  if ( isNotEmpty( response ) && isNotEmpty( response.results ) ) {
                    const options = {};

                    response.results.map( r => {
                      options[r.id] = r.label;
                    } );

                    planIDField.options = options;

                    setRemediationPlanOptions( options );
                  }
                } );
              }
            }
          } else {
            setVariantFields( _fields.fields );
          }
          setVariantFields( _fields.fields );
        } else {
          setVariantFields( null );
        }
      }
    }
  };

  // if a widget is being edited, populate the form accordingly
  React.useEffect( () => {
    if ( isNotEmpty( currentWidget ) ) {
      const _fields = EMPTY_VARIANT_FIELDS[currentWidget.key];


      if ( isNotEmpty( _fields ) && isNotEmpty( _fields.fields ) ) {
        if ( isNotEmpty( tags ) ) {
          const tagFilter = _fields.fields.find( i => i.attribute === 'asset_tag_ids' );

          if ( isNotEmpty( tagFilter ) ) {
            tagFilter.options = tags;
          }
        }

        if ( isNotEmpty( complianceFieldsOptions ) ) {
          const regulationField = _fields.fields.find( i => i.attribute === 'regulation' );

          if ( isNotEmpty( regulationField ) ) {
            const regulationOptions = {};

            Object.keys( complianceFieldsOptions ).map( regulationKey => {
              regulationOptions[regulationKey] = complianceFrameworkLabelMap[regulationKey] || regulationKey;
            } );

            regulationField.options = regulationOptions;

            const [ defaultRegulationKey ] = Object.keys( complianceFieldsOptions );

            regulationField.defaultValue = defaultRegulationKey;
          }
        }

        // for the specific remediation plan widget, need to populate the options
        if ( currentWidget.key === 'remediation_specific_plan' ) {
          if ( isNotEmpty( remediationPlanOptions ) ) {
            const planIDField = _fields.fields.find( i => i.attribute === 'plan_id' );

            if ( isNotEmpty( planIDField ) ) {
              planIDField.options = remediationPlanOptions;
            }
          } else {
            const planIDField = _fields.fields.find( i => i.attribute === 'plan_id' );
            if ( isNotEmpty( planIDField ) ) {
              makeRequest( 'LIST', '/project/default/model/base/remediation_plan', {} ).then( response => {
                if ( isNotEmpty( response ) && isNotEmpty( response.results ) ) {
                  const options = {};

                  response.results.map( r => {
                    options[r.id] = r.label;
                  } );

                  planIDField.options = options;

                  setRemediationPlanOptions( options );
                }
              } );
            }
          }
        } else {
          setVariantFields( _fields.fields );
        }

      }
    } else {
      setSelectedWidgetVariant( null );
      setVariantFields( null );
    }
  }, [ currentWidget, complianceFieldsOptions ] );

  return (
    <React.Fragment>
      {
        ( isNotEmpty( variantOptions ) ) &&
        <div className="widgetVariantSelector">
          <h4>Available Widgets</h4>
          <ul>
            {
              Object.values( variantOptions ).map( ( variant, index ) => {
                return <li
                  key={index}
                  onClick={ () => handleVariantClick( variant ) }
                  // eslint-disable-next-line max-len
                  className={ `widgetVariantItem ${ isNotEmpty( selectedWidgetVariant ) && selectedWidgetVariant.key === variant.key ? 'selected' : ''}`}
                >
                  { variant.label }
                </li>;
              } )
            }
          </ul>
        </div>

      }
      {
        isNotEmpty( selectedWidgetVariant ) &&
        <div className="widgetFormAndPreviewWrapper">
          <h4>{ selectedWidgetVariant.label } Settings</h4>
          {
            isNotEmpty( variantFields )
              ? <Form
                existingRecord={ isNotEmpty( currentWidget ) ? currentWidget.settings : null }
                fields={variantFields}
                recordType={ 'widget' }
                onChangeCallback={ setUpdatedSettings }
              />
              : <EmptyState message="No settings for this widget" />
          }
        </div>
      }
    </React.Fragment>
  );
};

const WidgetSelectorAction = ( {
  editWidget,
  addWidget,
  selectedWidgetVariant,
  closeWidgetEditor,
  currentWidget,
  updatedSettings,
  // recordLabel,
} ) => {

  const handleAddUpdateWidget = () => {

    const settingsValues = getFieldValues( updatedSettings?.fieldStates, 'widget' ) || {};

    // creating a new widget
    if ( isNotEmpty( selectedWidgetVariant ) && isEmpty( currentWidget ) ) {
      const newWidget = { ...selectedWidgetVariant };
      newWidget.settings = settingsValues;

      // gives it a new id
      if ( isEmpty( newWidget.i ) ) {
        newWidget.i = uuidv4();
      }

      newWidget.x = 0;

      addWidget( newWidget );
      closeWidgetEditor();
    // updating an existing widget
    } else if ( isNotEmpty( currentWidget ) && isNotEmpty( currentWidget.i ) ) {

      const existingWidget = { ...currentWidget };
      existingWidget.settings = settingsValues;

      editWidget( existingWidget );
      closeWidgetEditor();
    }
  };

  return (
    <React.Fragment>
      {
        isNotEmpty( selectedWidgetVariant ) &&
        <button
          onClick={ handleAddUpdateWidget }
        >
          {
            isNotEmpty( currentWidget )
              ? 'Update Widget'
              : 'Add Widget'
          }
        </button>
      }
    </React.Fragment>
  );
};

const WidgetEditorModal = ( {
  showWidgetEditor,
  setShowWidgetEditor,
  selectedWidgetVariant,
  setSelectedWidgetVariant,
  selectedWidgetOptions,
  setSelectedWidgetOptions,
  selectedWidgetCategory,
  recordLabel,
  setRecordLabel,
  addWidget,
  editWidget,
  currentSettings,
  closeWidgetEditor,
  setSelectedWidgetCategory,
  currentWidget,
  setCurrentWidget,
  groupedComplianceData,
  complianceFieldsOptions,
} ) => {

  const [ updatedSettings, setUpdatedSettings ] = React.useState( null );

  React.useEffect( () => {
    if ( showWidgetEditor === false ) {
      setSelectedWidgetCategory( null );
      setShowWidgetEditor( false );
      setCurrentWidget( null );
      setSelectedWidgetVariant( null );
    }
  }, [ showWidgetEditor ] );

  return (
    <Modal
      visible={showWidgetEditor}
      setVisible={setShowWidgetEditor}
      elementClass={ `widgetEditorAndSelctorModal ${ isNotEmpty( currentWidget ) ? 'editMode' : '' }` }
      body={ <WidgetSelectorBody
        selectedWidgetVariant={ selectedWidgetVariant }
        setSelectedWidgetVariant={ setSelectedWidgetVariant }
        selectedWidgetOptions={ selectedWidgetOptions }
        setSelectedWidgetOptions={ setSelectedWidgetOptions }
        selectedWidgetCategory={ selectedWidgetCategory }
        setRecordLabel={ setRecordLabel }
        currentWidget={ currentWidget }
        currentSettings={ currentSettings }
        setUpdatedSettings={ setUpdatedSettings }
        groupedComplianceData={groupedComplianceData}
        complianceFieldsOptions={complianceFieldsOptions}
      /> }
      action={ <WidgetSelectorAction
        addWidget={ addWidget }
        editWidget={editWidget}
        selectedWidgetVariant={ selectedWidgetVariant }
        setSelectedWidgetVariant={ setSelectedWidgetVariant }
        selectedWidgetOptions={ selectedWidgetOptions }
        setSelectedWidgetOptions={ setSelectedWidgetOptions }
        closeWidgetEditor={ closeWidgetEditor }
        recordLabel={ recordLabel }
        currentWidget={ currentWidget }
        updatedSettings={ updatedSettings }
      /> }
    />
  );
};

export default WidgetEditorModal;