/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import Dropdown from '../../../shared/Dropdown';
import InlineSVG from '../../../shared/InlineSVG';
import { isEmpty, isNotEmpty } from '../../../shared/Utilities';

import './DashboardSelector.scss';
import { CurrentUserContext } from '../../../Contexts/CurrentUser';
import { canConfigure } from '../../App/AccessControl';

const DashboardSelector = ( {
  setEditMode,
  options,
  setCurrentLayoutID,
  currentDashboard,
  setCurrentDashboard,
  setLayoutWidgets,
  setContentWidgets,
} ) => {

  const [ menuItems, setMenuItems ] = React.useState( null );
  const [ , , licenseInfo ] = React.useContext( CurrentUserContext );

  const handleExistingLayoutClick = layout => {
    setCurrentLayoutID( layout.id );
    // setCurrentLayout( layout );
    window.location.reload();
  };

  const createNewDashboard = () => {
    setCurrentDashboard( null );
    setCurrentLayoutID( null );
    setLayoutWidgets( null );
    setContentWidgets( null );
    setEditMode( true );
  };

  React.useEffect( ( ) => {

    if ( isNotEmpty( options ) ) {
      const dsLayouts = [];
      const userLayouts = [];
      const orphanedLayouts = [];

      Object.entries( options ).map( ( [ id, dashboard ] ) => {
        if ( dashboard.builtin ) {
          dsLayouts.push(
            <div
              className={ `existingLayout ${ id === currentDashboard?.id ? 'current' : ''}` }
              onClick={ () => handleExistingLayoutClick( dashboard ) }
            >
              <InlineSVG type="primaryLogoBug" version="bug" size="logoBug" elementClass="dsLogo" />
              { dashboard.label }
            </div>,
          );
        } else if ( canConfigure( licenseInfo ) ) {
          if ( isEmpty( dashboard.owner ) ) {
            orphanedLayouts.push(
              <div
                className={ `existingLayout ${ id === currentDashboard?.id ? 'current' : ''}` }
                onClick={ () => handleExistingLayoutClick( dashboard ) }
              >
                { dashboard.label }
              </div>,
            );
          } else {
            userLayouts.push(
              <div
                className={ `existingLayout ${ id === currentDashboard?.id ? 'current' : ''}` }
                onClick={ () => handleExistingLayoutClick( dashboard ) }
              >
                { dashboard.label }
              </div>,
            );
          }

        }
      } );

      let _menuItems = [
        <label>DeepSurface Dashboards</label>,
        ...dsLayouts,
      ];

      if ( isNotEmpty( userLayouts ) ) {
        _menuItems.push( <label>Private Dashboards</label> );
        _menuItems = [
          ..._menuItems,
          ...userLayouts,
        ];
      }

      if ( isNotEmpty( orphanedLayouts ) ) {
        _menuItems.push( <label>Unowned Dashboards</label> );
        _menuItems = [
          ..._menuItems,
          ...orphanedLayouts,
        ];
      }

      _menuItems.push(
        <button
          className={ `${!canConfigure( licenseInfo ) ? 'disabled' : '' } menuCreateNewButton` }
          disabled={!canConfigure( licenseInfo )}
          onClick={ createNewDashboard }
        >
          Create a new dashboard
        </button>,
      );

      setMenuItems( _menuItems );
    }
  }, [ options, currentDashboard ] );

  return (
    <React.Fragment>
      {
        ( isNotEmpty( menuItems ) && isNotEmpty( currentDashboard ) ) &&
        <div className="dashboardSelectorWrapper">
          {
            isNotEmpty( currentDashboard ) &&
            <strong>{ currentDashboard.label }</strong>
          }
          <Dropdown
            trigger={
              <div className="triggerTextWrapper">
                <span>
                  Switch Dashboard
                </span>
                <InlineSVG type="carretDown" version="primary" />
              </div>
            }
            menuItems={ menuItems }
            elementClass="dashboardSelector"
            asSelect
            menuElementClass="dashboardSelectorMenu"
          />
        </div>
      }
    </React.Fragment>
  );
};

export default DashboardSelector;