/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
import React from 'react';
import ReactDOM from 'react-dom';

import {
  getDimensionsAndOffset,
  isEmpty,
  isNotEmpty,
  paramsToFilters,
  removeFromURLHash,
} from '../../Utilities';

import './Select.scss';
import InlineSVG from '../../InlineSVG';
import TagItem from '../../../components/RiskInsight/Tags/Item';

const MultiSelectOptions = ( {
  input,
  showMultiselectOptions,
  handleMultiSelect,
  multiSelectValue,
  setShowMultiSelectOptions,
  wrapperRef,
} ) => {
  const portalRoot = document.getElementById( 'selectMultiItemsPortal' );

  const [ style, setStyle ] = React.useState( {} );

  // when it is time to show the dropdown, position it accordingly
  React.useEffect( () => {
    if ( isNotEmpty( wrapperRef ) && isNotEmpty( wrapperRef.current ) && showMultiselectOptions ) {
      const offset = getDimensionsAndOffset( wrapperRef.current );

      if ( isNotEmpty( offset ) ) {
        setStyle(
          {
            top: offset.top + offset.height + 8,
            left: offset.left,
            width: offset.width,
          },
        );
      }
    }
  }, [ wrapperRef, showMultiselectOptions, multiSelectValue ] );

  if ( isNotEmpty( portalRoot ) ) {
    return ReactDOM.createPortal(
      <div className="multiSelectOptionsWrapper" style={ style } >
        {
          ( isNotEmpty( input.options ) && showMultiselectOptions ) &&
          <div className="multiSelectOptions">
            {
              Object.entries( input.options ).map( ( [ key, value ], index ) => {
                return <div className="option" key={index} onClick={ e => handleMultiSelect( e, key ) }>
                  {
                    multiSelectValue.includes( key )
                      ? <InlineSVG type="checkboxChecked" version="primary" elementClass="checked"/>
                      : <InlineSVG type="checkbox" elementClass="unchecked"/>
                  }
                  {
                    ( isNotEmpty( input ) && input.attribute === 'asset_tag_ids' )
                      ? <TagItem tag={value} />
                      : <strong>{ value }</strong>
                  }
                </div>;
              } )
            }
          </div>
        }
        {
          ( isEmpty( input.options ) && input.attribute === 'asset_tag_ids' && showMultiselectOptions ) &&
          <div className="multiSelectOptions">
            <div className="emptyOption">
              No Tags Created
            </div>
          </div>
        }
        {
          ( isEmpty( input.options ) && input.attribute === 'third_party_setting_ids' && showMultiselectOptions ) &&
          <div className="multiSelectOptions">
            <div className="emptyOption">
              No Third Party Vulnerability Sources Configured
            </div>
          </div>
        }
        {
          showMultiselectOptions &&
          <div className="multiselectShade" onClick={ () => setShowMultiSelectOptions( false ) } />
        }
      </div>,
      portalRoot,
    );
  }
};

const Select = ( {
  input,
  initialValues,
  value,
  handleChange,
} ) => {

  const inputRef = React.useRef( null );

  const [ selectValue, setSelectValue ] = React.useState( null );
  const [ multiSelectValue, setMultiSelectValue ] = React.useState( [] );
  const [ showMultiselectOptions, setShowMultiSelectOptions ] = React.useState( false );

  const wrapperRef = React.useRef( null );

  // whenever a selection is made ( not used for multiple variant )
  const handleSelection = ( e, value=null ) => {

    let _value;

    if ( isNotEmpty( e ) ) {
      e.preventDefault();
      e.stopPropagation();
      _value = e.target.valueAsNumber || e.target.value;
    }

    if ( isNotEmpty( value ) ) {
      _value = value;
    }

    if ( isNotEmpty( input ) && input.multiple ) {

      let currentValues = [ ...multiSelectValue ];

      // need to de-select existing
      if ( currentValues.includes( _value ) ) {
        currentValues = currentValues.filter( v => v !== _value );
      // otherwise select
      } else {
        currentValues.push( _value );
      }

      setMultiSelectValue( currentValues );
    } else {
      setSelectValue( _value );
    }
    handleChange( input.attribute, _value );
  };

  // whenever a multiselection is made
  const handleMultiSelect = ( e, value ) => {

    if ( isNotEmpty( e ) ) {
      e.preventDefault();
      e.stopPropagation();
    }
    let _values = [ ...multiSelectValue ];

    if ( _values.includes( value ) ) {
      _values = _values.filter( v => v !== value );
    } else {
      _values.push( value );
    }
    setMultiSelectValue( _values );
    handleChange( input.attribute, _values );
  };

  const handleHashChange = () => {
    const filterValues = paramsToFilters();

    if ( isNotEmpty( filterValues ) && isNotEmpty( filterValues[input.attribute] ) ) {
      const newValue = filterValues[input.attribute];

      setSelectValue( newValue );
      setMultiSelectValue( newValue );

      handleChange( input.attribute, newValue );

      if ( isNotEmpty( inputRef ) && isNotEmpty( inputRef.current ) ) {
        // for some conditionalOption type fields, when you change the parent value, the dependent value is no longer
        // available, need to empty if that is the case:
        // ie:
        // The parent value is third-party-vuln and that allows 'foo', 'bar' and 'baz' as options for the dependent
        // the dependent input is set to 'baz'
        // then the parent value is changed to something like analysis, which does not allow 'baz' as a choice anymore,
        // in that case we need to adjust this value
        if ( isNotEmpty( input ) ) {
          // this is on load for a conditional (dependent) select field and the values match, allow the value to stick
          if (
            isNotEmpty( input.conditionalOptions )
            && isNotEmpty( initialValues )
            && initialValues[input.conditionalOptions.attribute] === filterValues[input.conditionalOptions.attribute]
          ) {
            inputRef.current.value = newValue;
          // not on load, carry on with previous behavior for clearing out the no-longer matching value
          } else if (
            input.attribute !== 'asset_tag_ids' && (
              isEmpty( input.options )
              || !Object.keys( input.options ).includes( newValue )
            )
          ) {
            inputRef.current.value = '';
            setSelectValue( '' );
            handleChange( input.attribute, '' );
            removeFromURLHash( input.attribute );
          } else {
            inputRef.current.value = newValue;
          }
        }
      }
    } else {
      if ( input.multiple ) {
        setMultiSelectValue( [] );
      }
      setSelectValue( '' );
      handleChange( input.attribute, '' );
      removeFromURLHash( input.attribute );

      if ( isNotEmpty( inputRef ) && isNotEmpty( inputRef.current ) ) {
        inputRef.current.value = '';
      }
    }
  };

  // sets up the hashchange listener
  React.useEffect( () => {
    handleHashChange();
    window.addEventListener( 'hashchange', handleHashChange );
    return () => {
      window.removeEventListener( 'hashchange', handleHashChange );
    };
  }, [ value, input, inputRef ] );

  return (
    <React.Fragment>
      {
        input.asToggle
          ? <React.Fragment>
            {
              ( input.toggleClass === 'large' && Object.keys( input.options ).length === 2 ) &&
              <React.Fragment>
                <span className="labelWrapper">{ input.label } { input.helpItem }</span>
                <div
                  className="toggleWrapper"
                >
                  {
                    Object.entries( input.options ).map( ( [ val, label ], index ) => {
                      return  <button
                        onClick={ e => handleSelection( e, val )}
                        key={index}
                        className={`toggleButton ${selectValue === val ? 'toggled' : ''}`}
                      >
                        { label }
                      </button>;
                    } )
                  }
                </div>
              </React.Fragment>
            }
          </React.Fragment>
          : <label>
            <span className="labelWrapper">
              { input.label }
              { input.helpItem }
            </span>
            <div className={
              `selectFieldWrapper ${input.asButton ? 'asButton' : ''}`
            }>
              {
                input.multiple
                  ? <React.Fragment>
                    <div className="multiSelectWrapper" ref={wrapperRef}>
                      {
                        isNotEmpty( input.options ) &&
                        <div
                          className={ `selectFieldWrapper noInput ${ showMultiselectOptions ? 'open' : '' }` }
                          onClick={ () => setShowMultiSelectOptions( !showMultiselectOptions ) }
                        >
                          <div className="selectedOptions">
                            {
                              isEmpty( multiSelectValue ) &&
                              <span className="placeholder">{ input.placeholder || 'Select...' }</span>
                            }
                            {
                              Object.entries( input.options ).map( ( [ key, value ], index ) => {
                                if ( multiSelectValue.includes( key ) ) {
                                  return <div
                                    className="selected"
                                    key={index}
                                    onClick={ e => handleMultiSelect( e, key ) }
                                  >
                                    {
                                      ( isNotEmpty( input ) && input.attribute === 'asset_tag_ids' )
                                        ? <TagItem tag={value} />
                                        : <strong>{ value }</strong>
                                    }
                                    <span className="removeButton" >
                                      <InlineSVG type="remove" />
                                    </span>
                                  </div>;
                                }

                              } )
                            }
                          </div>

                          <InlineSVG type="carretDown" elementClass="carretIcon" />
                        </div>
                      }
                      {
                        (
                          isEmpty( input.options )
                          && ( input.attribute === 'asset_tag_ids' || input.attribute === 'third_party_setting_ids' )
                        ) &&
                        <div
                          className={ `selectFieldWrapper noInput ${ showMultiselectOptions ? 'open' : '' }` }
                          onClick={ () => setShowMultiSelectOptions( !showMultiselectOptions ) }
                        >
                          <div className="selectedOptions">
                            <span className="placeholder">{ input.placeholder || 'Select...' }</span>
                          </div>
                          <InlineSVG type="carretDown" elementClass="carretIcon" />
                        </div>
                      }
                      <MultiSelectOptions
                        input={input}
                        showMultiselectOptions={showMultiselectOptions}
                        setShowMultiSelectOptions={setShowMultiSelectOptions}
                        handleMultiSelect={handleMultiSelect}
                        multiSelectValue={multiSelectValue}
                        wrapperRef={wrapperRef}
                      />
                    </div>
                  </React.Fragment>
                  : <select
                    disabled={ input.disabled }
                    onChange={ handleSelection }
                    ref={ inputRef }
                  >
                    {
                      input.allowBlank
                        ? <option
                          value=""
                        >
                          { input.blankDisplayText ? input.blankDisplayText : 'None' }
                        </option>
                        : <React.Fragment>
                          <option disabled value="">select...</option>
                        </React.Fragment>
                    }
                    {
                      ( input.options && isNotEmpty( input.options ) )
                        ? <React.Fragment>
                          {
                            Object.keys( input.options ).map( ( opt, index ) => {
                              return  <option
                                key={index}
                                value={opt}
                                // eslint-disable-next-line max-len
                                className={ `${isNotEmpty( input.originalValue ) && input.originalValue === input.options[opt] ? 'original' : ''}`}
                              >
                                { input.options[opt] }
                              </option>;
                            } )
                          }
                        </React.Fragment>
                        : <React.Fragment>
                        </React.Fragment>
                    }
                  </select>
              }
            </div>
          </label>
      }
    </React.Fragment>
  );
};

export default Select;
