/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
import React from 'react';
import CollectionFieldsWrapper from './CollectionFieldsWrapper';

import './style.scss';

import { v4 as uuidv4 } from 'uuid';

import {
  isEmpty,
  isNotEmpty,
} from '../../../../Utilities';
import InlineSVG from '../../../../InlineSVG';

const Collection = ( {
  field,
  fields,
  originalValue,
  formState,
  onChange,
  existingRecord,
} ) => {

  const [ items, setItems ] = React.useState( {} );
  const [ allItemsValid, setAllItemsValid ] = React.useState( true );

  const emptyItem = {
    root: '',
    port: field.defaultPort ? field.defaultPort : '',
    isValid: field.isValid,
  };

  const addEmptyItem = () => {
    setItems( {
      ...items,
      [uuidv4()]: emptyItem,
    } );
  };

  const validateAndHandleChange = _items => {
    const parsedItems = [];

    let _valid = false;

    const _allItemsValid = [];

    if ( isNotEmpty( _items ) ) {
      Object.keys( _items ).map( uuid => {
        if ( items[uuid].isValid ) {
          let _trimmedRoot = _items[uuid].root ? _items[uuid].root.trim() : '';
          // special logic for ipv6 (adds [ and ] to beginning and end)
          if ( _items[uuid].root.includes( ':' ) ) {
            _trimmedRoot = [ '[', _trimmedRoot, ']' ].join( '' );
          }
          const _trimmedPort = _items[uuid].port ? _items[uuid].port : '';
          if ( field.needsPort ) {
            parsedItems.push( `${_trimmedRoot}:${_trimmedPort}` );
            if ( isNotEmpty( _items[uuid].port ) ) {
              _allItemsValid.push( true );
            } else {
              _allItemsValid.push( false );
            }
          } else {
            parsedItems.push( _trimmedRoot );
            _allItemsValid.push( true );
          }
        } else if ( field.needsFQDN ) {
          // allow an empty root for this, it will not get saved
          if ( isEmpty( _items[uuid].root ) ) {
            _allItemsValid.push( true );
          } else {
            _allItemsValid.push( false );
          }
        } else {
          _allItemsValid.push( false );
        }
      } );

      if ( isEmpty( _allItemsValid ) ) {
        _valid = true;
      } else if ( _allItemsValid.every( d => d === true ) ) {
        _valid = true;
      }
      setAllItemsValid( _valid );

    } else {
      _valid = true;
      setAllItemsValid( true );
    }
    onChange( field, parsedItems, _valid );
  };

  React.useEffect( () => {
    validateAndHandleChange( items );
  }, [ items ] );

  // if there are any existing items, break them out and get them ready
  const parseAndSetDomains = items => {
    const v6Root = /(\[.*\])/g;
    const allItems = {};
    if ( field.needsPort ) {
      items.map( d => {
        const uuid = uuidv4();
        // is an IPV6, need to split differently than a regular domain/ip : port
        if ( d.includes( '[' ) && d.includes( ']' ) ) {
          let [ root ] = d.match( v6Root );

          root = root.replace( '[', '' ).replace( ']', '' );

          const split = d.split( ':' );

          allItems[uuid] = {
            root,
            port: parseInt( split[ split.length - 1 ] ),
            isValid: true,
          };
        } else {
          const split = d.split( ':' );
          allItems[uuid] = {
            root: split[0],
            port: split[1] ? parseInt( split[1] ) : '',
            isValid: true,
          };
        }
      } );
    } else {
      items.map( d => {
        const uuid = uuidv4();
        allItems[uuid] = {
          root: d,
          isValid: true,
        };
      } );
    }

    setItems( allItems );
  };

  // 1) First thing to happen with values from the server
  React.useEffect( () => {
    if ( isNotEmpty( originalValue ) ) {
      parseAndSetDomains( originalValue );
    } else if ( isEmpty( items ) ) {
      addEmptyItem();
    }
  }, [ existingRecord, originalValue, field ] );

  return (
    <React.Fragment>
      {
        isNotEmpty( items ) &&
        <div className="addedItems">
          {
            Object.keys( items ).map( ( uuid, index ) => {
              return  <CollectionFieldsWrapper
                key={index}
                item={items[uuid]}
                uuid={uuid}
                items={items}
                setItems={setItems}
                field={field}
                formState={formState}
              />;
            } )
          }
        </div>
      }

      <div className="actions">
        <button
          className={ `${ ( !allItemsValid || field.disabled ) ? 'disabled' : ''} addNewItemButton`}
          disabled={ !allItemsValid || field.disabled }
          onClick={ addEmptyItem }
        >
          <InlineSVG type="new" version="light" />
          <span>Add</span>
        </button>
      </div>
      {
        ( field.removable ) &&
        <button
          className="removeFieldButton"
          onClick={ () => field.onRemove( field, fields ) }
        >
          <InlineSVG type="close" />
        </button>
      }
    </React.Fragment>
  );
};

export default Collection;
