/** **************************************************************************
 * Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
 ****************************************************************************/

import React from 'react';
import { isNotEmpty } from '../shared/Utilities';
import {
  fetchHelpDocumentation,
  getContextualHelpItemsForPage,
} from '../../../../../../documentation/src/javascript/react/App/Shared';

export const HelpContext = React.createContext();

export const HelpProvider = ( { children } ) => {

  const [ helpItems, setHelpItems ] = React.useState( null );
  const [ selectedHelpItem, setSelectedHelpItem ] = React.useState( null );
  const [ showHelp, setShowHelp ] = React.useState( false );

  const showHelpFor = ( e, helpKey ) => {
    e.preventDefault();
    e.stopPropagation();

    if ( isNotEmpty( helpItems ) ) {
      if ( isNotEmpty( helpItems[helpKey] ) ) {
        setSelectedHelpItem( { [helpKey]: helpItems[helpKey] } );
        setShowHelp( true );
      }
      setShowHelp( true );
    }
  };

  const data = [
    helpItems,
    setHelpItems,
    selectedHelpItem,
    setSelectedHelpItem,
    showHelp,
    setShowHelp,
    showHelpFor,
    getContextualHelpItemsForPage,
    fetchHelpDocumentation,
  ];

  return (
    <HelpContext.Provider value={ data }>
      { children }
    </HelpContext.Provider>
  );
};
