/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';

// routes without tabs
import About from '../About';
import HelpDocumentation from '../HelpDocumentation';
import { usePostHog } from 'posthog-js/react';
import ReactGA from 'react-ga4';

// import { ContextualHelpContainer } from '../../components/HelpDocumentation/ContextualHelp/index.js';
import { decodeURLHash, isEmpty, isNotEmpty } from '../../shared/Utilities';
import { ModelContext } from '../../Contexts/Model';
import { featureAccessLevel, hasFeatureAccess, pageToFeatureKeyMap } from './AccessControl';
import { CurrentUserContext } from '../../Contexts/CurrentUser';
import NotFound from './NotFound';
import FlashMessageQueue from '../../shared/FlashMessageQueue';
import { HelpContext } from '../../Contexts/Help';
import { clearCache } from '../../shared/RecordCache';
import { OnboardingWizardContext } from '../../Contexts/OnboardingWizard';
import { RiskContext } from '../../Contexts/Risk';
import { NavigationContext } from '../../Contexts/Navigation';
import { FullScreenVisualContext } from '../../Contexts/FullScreenVisual';
import { PrintingContext } from '../../Contexts/Printing';
import DemoModeContent, { pagesReplacedInDemoMode } from './DemoModeContent';

import { PostHogProvider } from 'posthog-js/react';

const posthogOptions = {
  // eslint-disable-next-line camelcase
  api_host: 'https://us.i.posthog.com',
};

const Content = ( { currentLocation, pageClassName } ) => {

  // eslint-disable-next-line no-unused-vars
  let isMounted = true;

  let refreshInterval = null;
  let riskInterval = null;
  let peerPercentileInterval = null;

  const posthog = usePostHog();

  const [ globalModel, refreshModelData ] = React.useContext( ModelContext );
  const [ lastModelAnalysis, setLastModelAnalysis ] = React.useState( null );
  const [ pageComponent, setPageComponent ] = React.useState( <NotFound /> );

  const [ currentUser, , licenseInfo ] = React.useContext( CurrentUserContext );
  const [
    ,
    setHelpItems,
    ,
    ,
    ,
    ,
    ,
    getContextualHelpItemsForPage,
    ,
  ] = React.useContext( HelpContext );
  const [ , , refreshWizard, , , , , , , ] = React.useContext( OnboardingWizardContext );
  const [ expandLeftNavigation, , routing ] = React.useContext( NavigationContext );
  const [ , , , , , , , , , ] = React.useContext( FullScreenVisualContext );

  const [ , , , refreshRisk, refreshPeerPercentile ] = React.useContext( RiskContext );
  const [ printingOrientation ] = React.useContext( PrintingContext );

  // attempts to automatically load the help topics for a given page without needing to call anything on each page
  const loadHelpTopics = async ( ) => {
    const hash = decodeURLHash();
    const section = hash['.'];
    const { page, report } = hash;
    const helpPage = report || page || '';

    const _helpItems = await getContextualHelpItemsForPage( section, helpPage, true );
    if ( isNotEmpty( _helpItems ) ) {
      setHelpItems( _helpItems );
    } else {
      setHelpItems( null );
    }
  };

  React.useEffect( () => {
    loadHelpTopics();
    window.addEventListener( 'hashchange', loadHelpTopics );
    return () => {
      window.removeEventListener( 'hashchange', loadHelpTopics );
    };
  }, [] );

  const intialize = async( ) => {
    console.log( 'initializing application with model, global, and project data' );

    // refresh the global vars
    await refreshModelData( );
    await refreshRisk( );
  };

  // clear cache if analysis is stale
  React.useEffect( () => {
    if ( isNotEmpty( globalModel ) ) {
      console.log( 'Model Refreshed' );
      if ( isEmpty( lastModelAnalysis ) || lastModelAnalysis < globalModel.last_analyzed ) {
        clearCache();
        console.log( 'Risk and Percentile Refreshed' );
        refreshRisk();
        refreshPeerPercentile();
      }
      setLastModelAnalysis( globalModel.last_analyzed );
    }
  }, [ globalModel ] );

  React.useEffect( () => {
    refreshWizard();
    isMounted = true;

    // refresh the global vars
    intialize( );

    refreshInterval = setInterval( () => refreshModelData( [ 'model' ] ), 10_000 );
    // set up a poll to refresh the globals/model every 10 minutes
    riskInterval = setInterval( () => refreshRisk( ), 60_000 );
    peerPercentileInterval = setInterval( () => refreshPeerPercentile( ), 60_000 );
    return () => {
      clearInterval( refreshInterval );
      clearInterval( riskInterval );
      clearInterval( peerPercentileInterval );
      isMounted = false;
    };
  }, [] );

  const sendGoogleAnalytics = () => {
    if ( isNotEmpty( licenseInfo ) && featureAccessLevel( 'f_analytics', licenseInfo ) === 'enabled' ) {
      ReactGA.send( { hitType: 'pageview', page: window.location.href, title: 'DeepSurface - RiskAnalyzer' } );
    }
  };

  // analytics tracking w/ posthog, identify() the currentUser whenever the currentUser changes
  React.useEffect( () => {
    if (
      isNotEmpty( currentUser )
      && isNotEmpty( licenseInfo )
      && featureAccessLevel( 'f_analytics', licenseInfo ) === 'enabled'
    ) {
      ReactGA.initialize( 'G-GC02HTS6Z0' );
      sendGoogleAnalytics();
      window.addEventListener( 'hashchange', sendGoogleAnalytics );
      posthog?.identify( currentUser.id, {
        email: currentUser.email || currentUser.username,
        name: currentUser.username,
      } );
    }
    return () => window.removeEventListener( 'hashchange', sendGoogleAnalytics );
  }, [ currentUser, licenseInfo ] );

  // load the correct component whenever the location changes
  React.useEffect( () => {
    let _component = <NotFound />;

    if ( isNotEmpty( currentLocation ) && isNotEmpty( routing ) ) {

      const { route, page, report } = currentLocation;
      const pageOrReport = isEmpty( report ) ? page : report;
      const currentRoute = routing[route];
      // if we are in a demo like env, and the page should be replaced
      if (
        featureAccessLevel( pageToFeatureKeyMap[currentLocation.page], licenseInfo ) === 'restricted'
        && pagesReplacedInDemoMode.includes( pageOrReport )
      ) {
        _component = <DemoModeContent currentLocation={currentLocation} />;
      } else if ( isNotEmpty( currentRoute ) && isNotEmpty( currentRoute.items ) ) {
        for ( const section of Object.values( currentRoute.items ) ) {
          if ( section.slug === pageOrReport && isNotEmpty( section.component ) ) {
            _component = section.component;
            break;
          // seeing if it matches a sub-page of a route section ( ie Reports > Standard > Hosts )
          } else if ( isNotEmpty( section.items ) ) {

            for ( const page of Object.values( section.items ) ) {
              if ( page.slug === pageOrReport && isNotEmpty( page.component ) ) {
                _component = page.component;
                break;
              }
            }
          }
        }
      }
    }
    setPageComponent( _component );
  }, [ currentLocation, routing ] );


  const pageContent = () => {
    return ( <React.Fragment>
      <style type="text/css">
        { `@media print{ @page { size: letter ${printingOrientation}; margin: 0.39in; } }` }
      </style>
      <div
        id="pageContent"
        // eslint-disable-next-line max-len
        className={ `${expandLeftNavigation ? 'leftNavExpanded' : ''} ${pageClassName()}PageContainer ${ currentLocation.route || 'default' }Container`}
      >
        {
          isNotEmpty( licenseInfo ) && isNotEmpty( currentLocation ) &&
          <React.Fragment>
            {
              ( isNotEmpty( currentLocation ) && isNotEmpty( currentLocation.route ) ) &&
              <React.Fragment>
                {
                  isEmpty( currentLocation.page ) && isEmpty( currentLocation.report )
                    ? <React.Fragment>
                      {
                        currentLocation.route === 'about' &&
                        <About />
                      }
                      {
                        currentLocation.route === 'help_documentation' &&
                        <HelpDocumentation />
                      }
                    </React.Fragment>
                    : <React.Fragment>
                      {/* check for additional content to be displayed while in demo mode */}
                      {
                        (
                          featureAccessLevel( pageToFeatureKeyMap[currentLocation.page], licenseInfo ) === 'restricted'
                          && !pagesReplacedInDemoMode.includes( currentLocation?.page )
                        ) &&
                        <DemoModeContent currentLocation={currentLocation} />
                      }
                      {/* check to see if content should be displayed at all */}
                      {
                        featureAccessLevel( pageToFeatureKeyMap[currentLocation.page], licenseInfo ) === 'disabled'
                          ? <NotFound
                            message={
                              <React.Fragment>
                                <p>Your license does not grant you access to this feature</p>
                                <a href="#.=reports&page=reporting_dashboard">Return to Dashboard</a>
                              </React.Fragment>
                            }
                          />
                          : pageComponent
                      }
                    </React.Fragment>
                }
              </React.Fragment>
            }
          </React.Fragment>
        }
        <FlashMessageQueue />
      </div>
    </React.Fragment> );
  };

  return (
    <React.Fragment>
      {
        isNotEmpty( licenseInfo ) &&
        <React.Fragment>
          {
            hasFeatureAccess( 'f_analytics', licenseInfo )
              ? <PostHogProvider
                apiKey="phc_x0tcXsjYDZrXF6gOzQW0u7khzvwMavTJnmM9FPKxIo1"
                options={ posthogOptions }
              >
                { pageContent() }
              </PostHogProvider>
              : <React.Fragment>
                { pageContent() }
              </React.Fragment>
          }
        </React.Fragment>
      }

    </React.Fragment>

  );
};

export default Content;