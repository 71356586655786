/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
import React from 'react';

import Checkbox from      './FilterInputs/Checkbox';
import CheckboxGroup from './FilterInputs/CheckboxGroup';
import Date from          './FilterInputs/Date';
import DebouncedText from './FilterInputs/DebouncedText';
import Hidden from        './FilterInputs/Hidden';
import RadioGroup from    './FilterInputs/RadioGroup';
import SearchResults from './FilterInputs/SearchResults';
import Select from        './FilterInputs/Select';
import TextSearch from    './FilterInputs/TextSearch';
import Number from        './FilterInputs/Number';

import {
  decodeURLHash,
  encodeURLHash,
  isEqual,
  triggerHashRefresh,
  isArrayType,
  removeFromURLHash,
  isEmpty,
  isNotEmpty,
  itemIsArray,
  isInequalityType,
} from '../Utilities';

import './style.scss';
import Inequality from './FilterInputs/Inequality';
import Buttons from './FilterInputs/Buttons';
import { needsRecordLabel } from './shared';

const Filter = ( {
  input,
  value,
  values,
  inputs,
  horizontal,
  disabled,
  onRefresh=() => {},
  withinFilterDrawer=false,
} ) => {

  // called whenever a filter value is changed
  /* eslint-disable camelcase */
  const handleChange = ( attribute, value ) => {

    const prevParams = decodeURLHash();

    // special check to see if any conditional options or
    // inputs need to be updated because of this value
    if ( isNotEmpty( inputs ) ) {
      inputs.map( i => {
        if ( i.conditionalOptions ) {
          const targetValue = prevParams[i.conditionalOptions.attribute];
          if ( isNotEmpty( targetValue ) ) {
            i.options = i.conditionalOptions.options[ targetValue ];
          } else {
            i.options = { };
          }
        }
      } );
    }

    if ( isEmpty( value ) ) {
      removeFromURLHash( attribute );
    } else {
      let _value = value;

      if ( isArrayType.includes( attribute ) ) {
        if ( attribute === 'asset_tag_ids' ) {
          _value = value;
        } else {
          _value = itemIsArray( value ) ? value : [ value ];
        }
        if ( isInequalityType.includes( attribute ) ) {
          if ( itemIsArray( _value ) ) {
            const [ , _unit ] = _value;

            if ( !isNaN( _unit ) && isNotEmpty( _unit ) && _unit !== '' && _unit !== 'null' ) {
              encodeURLHash( { [attribute]: _value } );
            } else {
              removeFromURLHash( attribute );
            }
          }
        } else {
          encodeURLHash( { [attribute]: _value } );
        }
      } else if ( attribute === 'risk_type' && _value === 'direct' ) {
        _value = 'direct_risk';
        encodeURLHash( { [attribute]: _value } );
      } else {
        encodeURLHash( { [attribute]: _value } );
      }
    }

    const newParams = decodeURLHash();

    // host_ids, vulnerability_ids, pathc_ids, will already have had the params applied from the component
    // so the first half of this check will never trigger, hence adding an additional conditional for those filters
    if ( !isEqual( prevParams, newParams ) || needsRecordLabel.includes( attribute ) ) {

      // for advanced Filters, we don't have an eventListener on the hashchange event because users will be
      // explicitely applying any filters, rather than having the onchange handle it. This override is so
      // certain fields within an advanced form can still have that functionality, but the rest of the form will
      // need to have its fields explicitely applied
      // this is done with any of the fields above the fold (or outside of the drawer) for the SPOG report for now...
      // but could be used for more in the future
      if ( !withinFilterDrawer ) {
        triggerHashRefresh();
        onRefresh( [ attribute ] );
      }
    }
  };
  /* eslint-enable camelcase */

  const filterInputContent = input => {

    const filterInputsMap = {
      buttons: <Buttons
        input={{ ...input, disabled }}
        value={value}
        handleChange={handleChange}
        withinFilterDrawer={withinFilterDrawer}
        onRefresh={onRefresh}
      />,
      checkbox: <Checkbox
        input={{ ...input, disabled }}
        value={value}
        handleChange={handleChange}
        withinFilterDrawer={withinFilterDrawer}
        onRefresh={onRefresh}
      />,
      checkboxGroup: <CheckboxGroup
        input={{ ...input, disabled }}
        value={value}
        handleChange={handleChange}
        withinFilterDrawer={withinFilterDrawer}
        onRefresh={onRefresh}
      />,
      date: <Date
        input={{ ...input, disabled }}
        value={value}
        handleChange={handleChange}
        withinFilterDrawer={withinFilterDrawer}
        onRefresh={onRefresh}
      />,
      hidden: <Hidden
        input={{ ...input, disabled }}
        value={value}
        handleChange={handleChange}
        withinFilterDrawer={withinFilterDrawer}
        onRefresh={onRefresh}
      />,
      inequality: <Inequality
        input={{ ...input, disabled }}
        value={value}
        handleChange={handleChange}
        withinFilterDrawer={withinFilterDrawer}
        onRefresh={onRefresh}
      />,
      number: <Number
        input={{ ...input, disabled }}
        value={value}
        handleChange={handleChange}
        withinFilterDrawer={withinFilterDrawer}
        onRefresh={onRefresh}
      />,
      debouncedText: <DebouncedText
        input={{ ...input, disabled }}
        value={value}
        handleChange={handleChange}
        withinFilterDrawer={withinFilterDrawer}
        onRefresh={onRefresh}
      />,
      radioGroup: <RadioGroup
        input={{ ...input, disabled }}
        value={value}
        handleChange={handleChange}
        withinFilterDrawer={withinFilterDrawer}
        onRefresh={onRefresh}
      />,
      searchResults: <SearchResults
        input={{ ...input, disabled }}
        value={value}
        handleChange={handleChange}
        onRefresh={onRefresh}
        withinFilterDrawer={withinFilterDrawer}
      />,
      select: <Select
        input={{ ...input, disabled }}
        value={value}
        initialValues={values}
        handleChange={handleChange}
        withinFilterDrawer={withinFilterDrawer}
        onRefresh={onRefresh}
      />,
      textSearch: <TextSearch
        input={{ ...input, disabled }}
        value={value}
        handleChange={handleChange}
        withinFilterDrawer={withinFilterDrawer}
        onRefresh={onRefresh}
      />,
    };

    return filterInputsMap[input.type];
  };

  return (
    <React.Fragment>
      {
        ( value !== null && value !== undefined ) &&
        // eslint-disable-next-line max-len
        <div className={`filterWrapper  ${input.attribute} ${input.type} ${ horizontal ? '' : 'withinForm'}`}>
          { filterInputContent( input ) }
        </div>
      }
    </React.Fragment>
  );
};

export default Filter;