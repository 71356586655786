import { globalColors, isNotEmpty } from '../../../shared/Utilities';

export const CURRENT_LAYOUT_VERSION = 2;

export const NODE_SIZE = 72;
export const NODE_HEIGHT = 140;
export const NODE_WIDTH = NODE_HEIGHT * 3;
export const SVG_WIDTH = 1_000;
export const SVG_HEIGHT = 1_000;
export const MIN_SCOPE_WIDTH = NODE_WIDTH;
export const SCOPE_PADDING = NODE_HEIGHT / 6;
export const ICON_SIZE = NODE_HEIGHT / 2.5;
export const MENU_WIDTH = 28 * 16;

export const GLOBAL_SCOPE = {
  id: '00000000-0000-0000-0000-000000000000',
  label: 'Global',
};

export const ADVERSARY_NODE = {
  id: '13371337-1337-1337-1337-133713371337',
  label: 'Adversary',
  type: 'adversary',
  // eslint-disable-next-line camelcase
  scope_id: '00000000-0000-0000-0000-000000000000',
  impact: null,
  original: {},
};

export const getDepthFill = depth => {
  if ( depth === 1 ) {
    return '#FFF';
  }
  if ( depth === 2 ) {
    return globalColors['primary--10'];
  }
  return globalColors['grey--icon'];
};

export const getArrowDirection = ( x1, y1, x2, y2 ) => {
  // vertical north or south
  if ( Math.abs( x1 - x2 ) < 64 ) {
    if ( y2 >= y1 ) {
      return 's';
    }
    return 'n';
  }

  // horizontal west or east
  if ( Math.abs( y1 - y2 ) < 64 ) {
    if ( x2 >= x1 ) {
      return 'e';
    }
    return 'w';
  }

  // going from west to east, either ne or se
  if ( x2 >= x1 ) {
    if ( y2 >= y1 ) {
      return 'se';
    }
    return 'ne';

  }
  // going from east to west, either nw or sw
  if ( y2 >= y1 ) {
    return 'sw';
  }
  return 'nw';
};

export const binPackChildren = ( children, entireGraph=false ) => {
  // calculate total box area and maximum box width
  let area = 0;
  let maxWidth = 0;

  let _children = [ ...children ];

  for ( const child of _children ) {

    const childArea = child.w * child.h;

    area += childArea;
    const max = Math.max( maxWidth, child.w );
    maxWidth = max;
  }

  // sort the boxes for insertion by height, descending
  if ( !entireGraph ) {
    _children.sort( ( a, b ) => b.h - a.h );
  }

  if ( entireGraph ) {
    const adversary = _children.find( c => c.id === ADVERSARY_NODE.id );
    _children = _children.filter( c => c.id !== ADVERSARY_NODE.id );
    if ( isNotEmpty( adversary ) ) {
      _children = _children.unshift( adversary );
    }
  }

  // aim for a squarish resulting container,
  // slightly adjusted for sub-100% space utilization
  // eslint-disable-next-line max-len
  const startWidth = Math.max( Math.ceil( Math.sqrt( area / 0.95 ) ), maxWidth ) + ( NODE_WIDTH * ( entireGraph ? 4 : 1 ) );

  // start with a single empty space, unbounded at the bottom
  const spaces = [ {x: 0, y: 0, w: startWidth, h: Infinity } ];

  let width = 0;
  let height = 0;

  for ( const child of _children ) {

    // look through spaces backwards so that we check smaller spaces first
    for ( let i = spaces.length - 1; i >= 0; i-- ) {
      const space = spaces[i];

      // look for empty spaces that can accommodate the current box
      if ( child.w > space.w || child.h > space.h ) {
        continue;
      }

      // found the space; add the box to its top-left corner
      // |-------|-------|
      // |  box  |       |
      // |_______|       |
      // |         space |
      // |_______________|
      child.x = space.x;
      child.y = space.y;

      height = Math.max( height, child.y + child.h );
      width = Math.max( width, child.x + child.w );

      if ( child.w === space.w && child.h === space.h ) {
        // space matches the box exactly; remove it
        const last = spaces.pop();
        if ( i < spaces.length ) {
          spaces[i] = last;
        }

      } else if ( child.h === space.h ) {
        // space matches the box height; update it accordingly
        // |-------|---------------|
        // |  box  | updated space |
        // |_______|_______________|
        space.x += child.w;
        space.w -= child.w;

      } else if ( child.w === space.w ) {
        // space matches the box width; update it accordingly
        // |---------------|
        // |      box      |
        // |_______________|
        // | updated space |
        // |_______________|
        space.y += child.h;
        space.h -= child.h;

      } else {
        // otherwise the box splits the space into two spaces
        // |-------|-----------|
        // |  box  | new space |
        // |_______|___________|
        // | updated space     |
        // |___________________|
        spaces.push( {
          x: space.x + child.w,
          y: space.y,
          w: space.w - child.w,
          h: child.h,
        } );
        space.y += child.h;
        space.h -= child.h;
      }
      break;
    }
  }

  return {
    children: _children,
    w: width, // container width
    h: height, // container height
    fill: ( area / ( width * height ) ) || 0, // space utilization
  };
};

export const layoutGraph = ( children, containerDimensions, menuCollapsed=false ) => {

  const aspectRatio = ( containerDimensions.w - ( menuCollapsed ? SCOPE_PADDING : MENU_WIDTH ) )
    / containerDimensions.h;
  let _children = children.sort( ( a, b ) => ( b.w * b.h ) - ( a.w * a.h ) );

  const globalScope = _children.find( c => c.id !== GLOBAL_SCOPE.id );

  if ( isNotEmpty( globalScope ) ) {
    _children = _children.filter( c => c.id !== GLOBAL_SCOPE.id );
  }

  const [ firstChild ] = _children;
  // start off with a rectangle that fits the first scope, it will grow from there
  let container = {
    w: firstChild.w > firstChild.h ? firstChild.w : firstChild.h * aspectRatio,
    h: firstChild.w > firstChild.h ? firstChild.w / aspectRatio : firstChild.h,
  };

  const columns = {
    1: {
      h: firstChild.h,
      w: firstChild.w,
      children: [ firstChild ],
    },
  };

  _children.map( ( child, index ) => {
    // skip the first one, already placed
    if ( index > 0 ) {
      // always going to add to the current column first, before adding a new column

      let idealColumn;

      if ( isNotEmpty( columns ) ) {
        for ( const column of Object.values( columns ) ) {

          const remainingColumnHeight = ( container.w / aspectRatio ) - column.h;

          if ( remainingColumnHeight > 0 && child.h <= remainingColumnHeight ) {
            idealColumn = column;
            break;
          }
        }
      }

      // we found a column to put the scope into
      if ( isNotEmpty( idealColumn ) ) {
        // add the item to the column and bump the height
        idealColumn.h += child.h;
        idealColumn.w = Math.max( idealColumn.w, child.w );
        idealColumn.children.push( child );

      // there was not enough room to fit the scope into any columns, starting a new one
      } else if ( isNotEmpty( columns ) ) {
        const newKey = Math.max( ...Object.keys( columns ).map( k => parseInt( k ) ) ) + 1;

        columns[newKey] = {
          h: child.h,
          w: child.w,
          children: [ child ],
        };
        const newWidth = container.w + child.w;
        container = { w: newWidth, h: newWidth / aspectRatio };
      }
    }
  } );

  return (
    { columns, container }
  );
};