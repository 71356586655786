/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { makeRequest } from '../../../../../legacy/io';
import { HelpTrigger } from '../../../HelpDocumentation/ContextualHelp/index.js';
import DataTable from '../../../../shared/DataTable';
import Form from '../../../../shared/Form';
import { getFieldValues } from '../../../../shared/Form/Shared';
import InlineSVG from '../../../../shared/InlineSVG';
import Loading from '../../../../shared/Loading';
import IndeterminantPagination, {
  getRowNums,
  pageIterator,
} from '../../../../shared/Pagination/IndeterminantPagination';
import {
  // decodeURLHash,
  encodeURLHash,
  formatNumber,
  isNotEmpty,
  removeFromURLHash,
} from '../../../../shared/Utilities';

import './PolicyModal.scss';
import {
  scopeTypeOptions,
  buildPolicyRecord,
  conditionalTagLabels,
  userFriendlyNameFor,
  conditionalScopeNameLabels,
  conditionalNodeTypeLabels,
  nodeTypeOptions,
} from './shared';
import { CurrentUserContext } from '../../../../Contexts/CurrentUser.js';
import { canConfigure } from '../../../App/AccessControl.js';

const PolicyModal = ( {
  selectedRecord,
  setSelectedRecord,
  showModal,
  setShowModal,
  onSave,
  assetTagOptions,
  externalLoading,
} ) => {

  const [ fields, setFields ] = React.useState( null );
  const [ updatedRecord, setUpdatedRecord ] = React.useState( null );
  const [ isValid, setIsValid ] = React.useState( true );

  // pagination related state variables
  const [ currentPageNumber, setCurrentPageNumber ] = React.useState( 1 );
  const [ currentPageResults, setCurrentPageResults ] = React.useState( [] );
  const [ nextPageResults, setNextPageResults ] = React.useState( [] );
  // pagination scroll behavior
  const [ scrollToTableTop, setScrollToTableTop ] = React.useState( false );
  const [ loading, setLoading ] = React.useState( false );
  const [ , , licenseInfo ] = React.useContext( CurrentUserContext );

  const goToPage = page => {
    // eslint-disable-next-line camelcase
    encodeURLHash( { current_page: parseInt( page ) } );
    setScrollToTableTop( true );
    testPolicy( parseInt( page ) );
  };

  const EMPTY_FIELDS = {
    label: {
      fields: [
        {
          type: 'text',
          attribute: 'label',
          label: 'Policy Name (Optional)',
          defaultValue: '',
        },
      ],
    },
    impact: {
      asSentence: true,
      header: 'Impact',
      fields: [
        {
          type: 'number',
          attribute: 'impact',
          label: 'Give an impact score of',
          defaultValue: 100,
          required: true,
        },
      ],
    },
    scope: {
      asSentence: true,
      header: 'Scope',
      fields: [
        {
          type: 'select',
          attribute: 'scope_name',
          conditionalLabel: { attribute: 'scope_name', labels: conditionalScopeNameLabels },
          defaultLabel: 'to',
          postLabel: 'scopes',
          label: 'to',
          options: scopeTypeOptions,
          defaultValue: 'null',
        },
        {
          type: 'text',
          attribute: 'scope_label',
          label: <React.Fragment>
            with the wildcard matcher
            <HelpTrigger helpKey="scope_wildcard" />
          </React.Fragment>,
          defaultValue: '',
        },
        {
          includeIf: { attribute: 'scope_name', value: [ 'sharedfolder_*' ] },
          type: 'checkbox',
          attribute: 'scope_builtin',
          label: '(Include Default Folders)',
          defaultValue: false,
        },
      ],
    },
    tag: {
      asSentence: true,
      header: 'Asset',
      fields: [
        {
          type: 'select',
          attribute: 'asset_tag_id',
          postLabel: 'tag',
          conditionalLabel: { attribute: 'asset_tag_id', labels: conditionalTagLabels },
          label: 'that belong to the',
          defaultLabel: 'that belong to the',
          options: {},
          defaultValue: 'null',
          allowBlank: true,
          blankDisplayValue: 'null',
          blankDisplayText: 'Any',
        },
      ],
    },
    node: {
      asSentence: true,
      header: 'Node',
      fields: [
        {
          includeIf: { attribute: 'scope_name', value: 'sharedfolder_*' },
          type: 'select',
          attribute: 'node_name',
          label: 'and have the name',
          options: {
            'read': 'Read',
            'write': 'Write',
          },
          defaultValue: 'read',
        },
        {
          type: 'select',
          attribute: 'node_type',
          conditionalLabel: { attribute: 'node_type', labels: conditionalNodeTypeLabels },
          label: 'and are',
          postLabel: 'asset type',
          options: nodeTypeOptions,
          defaultValue: 'null',
        },
        {
          includeIf: { attribute: 'scope_name', value: [ 'mssql_*', 'mysqld', 'null' ] },
          type: 'text',
          attribute: 'node_label',
          label: <React.Fragment>
            and include the the wildcard matcher
            <HelpTrigger helpKey="node_wildcard" />
          </React.Fragment>,
          defaultValue: '',
        },
        {
          includeIf: { attribute: 'scope_name', value: [ 'mssql_*', 'mysqld' ] },
          type: 'checkbox',
          attribute: 'node_builtin',
          label: '(Include Default Databases)',
          defaultValue: false,
        },
      ],
    },
  };

  // on load need to fetch the existing asset_tags (if any) and then setup the fields for the form.
  React.useEffect( ( ) => {

    if ( showModal ) {
      // eslint-disable-next-line camelcase
      encodeURLHash( { current_page: 1, item_count: 100 } );
      const tagField = EMPTY_FIELDS.tag.fields.find( f => f.attribute === 'asset_tag_id' );
      if ( isNotEmpty( tagField ) && isNotEmpty( assetTagOptions ) ) {
        tagField.options = assetTagOptions;
      }

      setFields( EMPTY_FIELDS );
    } else {
      removeFromURLHash( 'current_page' );
      removeFromURLHash( 'item_count' );
      setSelectedRecord( null );
      setUpdatedRecord( null );
    }
  }, [ assetTagOptions, showModal ] );

  // when the modal closes, clear out the results of the policy
  React.useEffect( () => {
    if ( showModal === false ) {
      setCurrentPageNumber( 1 );
      setCurrentPageResults( [] );
      setNextPageResults( [] );
    }
  }, [ showModal ] );


  // passed in as the callback to the Form component, takes the values from the fields and outputs a more user-friendly
  // sentence explaining what the applied filters will do.
  const onFormChange = form => {
    if ( isNotEmpty( form ) && isNotEmpty( form.fieldStates ) ) {
      const values = getFieldValues( form.fieldStates, 'sensitive_asset_policy' );
      setUpdatedRecord( values );
    }
  };

  // when clicking the test policy button, shows results of what would currently be returned by the given policy
  const testPolicy = async ( page=1 ) => {
    setLoading( true );
    setCurrentPageNumber( page );
    setCurrentPageResults( [] );
    setNextPageResults( [] );
    // eslint-disable-next-line camelcase
    encodeURLHash( { current_page: page } );

    // eslint-disable-next-line camelcase
    const filterValues = { current_page: page, item_count: 100 };
    const recordParams = buildPolicyRecord( selectedRecord, isValid, updatedRecord );

    const params =  {
      // eslint-disable-next-line camelcase
      asset_tag_id: recordParams.asset_tag_id,
      criteria: recordParams.criteria,
      // eslint-disable-next-line camelcase
      order_by: [ [ 'label', 'ASC' ] ],
      rownums: getRowNums( filterValues, 100 ),
    };
    const testPolicyResponse = await makeRequest( 'COMPUTE', '/model/base/sensitive_asset_policy', params );

    setLoading( false );

    if ( isNotEmpty( testPolicyResponse ) && isNotEmpty( testPolicyResponse.results ) ) {
      const pageResults = pageIterator( testPolicyResponse.results, filterValues );

      setCurrentPageResults( adjustPageResults( pageResults.firstPage ) );
      setNextPageResults( adjustPageResults( pageResults.secondPage ) );
    }
  };

  // whenever the results change, adjust the data for display in the table
  const adjustPageResults = results => {
    if ( results ) {
      return results.map( transformRowData );
    }
  };

  const transformRowData = row => {
    return {
      id: row.id,
      scope: row.ancestor_labels ? row.ancestor_labels.join( ' > ' ) : 'N/A',
      // eslint-disable-next-line max-len, camelcase
      asset_name: <a className="inlineLink newTabLink" href={`#.=explore&page=explore_model&explore_scope=${ encodeURIComponent( JSON.stringify( [ row.scope_id ] ) ) }&selected=${row.id}`} target="_blank" rel="noreferrer noopener" >
        { row.label }
        <InlineSVG type="newTabLink" version="primary" />
      </a>,
      // eslint-disable-next-line camelcase
      asset_type: row.type ? userFriendlyNameFor( row.type ) : 'N/A',
    };
  };

  const onSaveCallback = () => {
    setShowModal( false );
    removeFromURLHash( 'selected_record' );
    removeFromURLHash( 'item_count' );
    removeFromURLHash( 'current_page' );
  };

  return (
    <React.Fragment>
      { externalLoading && <Loading text="Saving Policy..." /> }
      {
        isNotEmpty( fields ) &&
        <div className="filterBuilder">
          <Form
            fields={fields}
            trackUpdates={false}
            recordType="sensitive_asset_policy"
            onChangeCallback={ onFormChange }
            existingRecord={selectedRecord}
            setIsValid={setIsValid}
          />
          <button
            className="testPolicyButton"
            onClick={ () => testPolicy( 1 ) }
          >
            Test Policy
          </button>
        </div>
      }
      <IndeterminantPagination
        currentPageNumber={currentPageNumber}
        nextPageResults={nextPageResults}
        goToPage={goToPage}
        elementClass="policyTestPagination"
      />
      <div className="policyTestTableWrapper">
        {
          ( isNotEmpty( updatedRecord ) && isNotEmpty( currentPageResults ) ) &&
          <div className="tableDescription">
            Once created, this policy would apply to the following
            assets (unless overwritten manually or by another policy with higher precedence) and give
            them an impact score of {formatNumber( updatedRecord?.impact || 0 ) }
          </div>
        }
        <DataTable
          data={currentPageResults}
          elementClass="policyTestDataTable"
          scrollToTableTop={scrollToTableTop}
        />
        { loading && <Loading /> }
      </div>
      <div className="modalActions">
        <button
          disabled={ !isValid || !canConfigure( licenseInfo ) }
          className={`${ ( isValid || !canConfigure( licenseInfo ) ) ? 'disabled' : ''} submitButton`}
          onClick={ () => onSave(
            selectedRecord,
            isValid,
            updatedRecord,
            onSaveCallback,
          ) }
        >
          Save Changes
        </button>
        <button
          className="cancelButton"
          onClick={ () => setShowModal( false ) }
        >
          Cancel
        </button>
      </div>
    </React.Fragment>
  );
};

export default PolicyModal;