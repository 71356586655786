/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { isNotEmpty } from '../../../shared/Utilities';
import RiskAndRemediation from './RiskAndRemediation';
import ThirdPartyScanning from './ThirdPartyScanning';
import DeepSurfaceScanning from './DeepSurfaceScanning';
import PathAnalysis from '../Path/PathAnalysis';
import VulnerabilityEvidence from './VulnerabilityEvidence';

const Body = ( {
  currentTab,
  // setCurrentTab,
  record,
  recordType,
  recordInstanceData,
  // isCollapsed,
  riskType,
  currentScannerSignature,
  setCurrentScannerSignature,
  thirdPartyData,
  setThirdPartyData,
  relatedPaths,
  setRelatedPaths,
  pathCount,
  setPathCount,
  handlePathCountChange,
  latestThirdParty,
  getScannerSignatureInfoAndSetAsCurrent,
  thirdPartySettings,

  // path recordType vars
  pathSensitiveAsset,
  pathTopVulnerabilities,
  loading,
  pathFormattedEdges,

  // recordCard variables
  recordCardRecord,
  setRecordCardRecord,
  recordCardType,
  setRecordCardType,
  showRecordCard,
  setShowRecordCard,
} ) => {

  return (
    <React.Fragment>
      {
        isNotEmpty( currentTab ) &&
        <div className={ `recordDetails__MainPanel__Body ${recordType}` }>
          {
            currentTab === 'path_analysis' &&
            <PathAnalysis
              path={record}
              relatedPaths={relatedPaths}

              // path recordType vars
              pathSensitiveAsset={pathSensitiveAsset}
              pathTopVulnerabilities={pathTopVulnerabilities}
              loading={loading}
              pathFormattedEdges={pathFormattedEdges}

              // recordCard variables
              recordCardRecord={recordCardRecord}
              setRecordCardRecord={setRecordCardRecord}
              recordCardType={recordCardType}
              setRecordCardType={setRecordCardType}
              showRecordCard={showRecordCard}
              setShowRecordCard={setShowRecordCard}
            />
          }
          {
            currentTab === 'risk_and_remediation' &&
            <RiskAndRemediation
              record={record}
              recordType={recordType}
              riskType={riskType}
              recordInstanceData={recordInstanceData}
              relatedPaths={relatedPaths}
              setRelatedPaths={setRelatedPaths}
              pathCount={pathCount}
              setPathCount={setPathCount}
              handlePathCountChange={handlePathCountChange}

              // recordCard variables
              recordCardRecord={recordCardRecord}
              setRecordCardRecord={setRecordCardRecord}
              recordCardType={recordCardType}
              setRecordCardType={setRecordCardType}
              showRecordCard={showRecordCard}
              setShowRecordCard={setShowRecordCard}
            />
          }
          {
            currentTab === 'third_party_scanning' &&
            <ThirdPartyScanning
              record={record}
              recordType={recordType}
              riskType={riskType}
              recordInstanceData={recordInstanceData}
              currentScannerSignature={currentScannerSignature}
              setCurrentScannerSignature={setCurrentScannerSignature}
              thirdPartyData={thirdPartyData}
              setThirdPartyData={setThirdPartyData}
              latestThirdParty={latestThirdParty}
              getScannerSignatureInfoAndSetAsCurrent={getScannerSignatureInfoAndSetAsCurrent}
              thirdPartySettings={thirdPartySettings}
            />
          }
          {
            currentTab === 'deepsurface_scanning' &&
            <DeepSurfaceScanning
              record={record}
            />
          }
          {
            currentTab === 'vulnerability_evidence' &&
            <VulnerabilityEvidence
              record={record}
              recordType={recordType}
              thirdPartySettings={thirdPartySettings}
            />
          }
        </div>
      }
    </React.Fragment>
  );
};

export default Body;