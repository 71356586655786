/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { isNotEmpty, itemIsArray } from '../../../shared/Utilities';
import NotScannedEmptyState from '../Host/NotScannedEmptyState';
import ScanningStatusIndicator from '../ScanningStatusIndicator';

import './DeepSurfaceScanning.scss';
import ScanLogViewer from '../../Activity/ScanLogs/ScanLogViewer';
import { makeRequest } from '../../../../legacy/io';

const DeepSurfaceScanning = ( { record } ) => {

  const [ thirdPartyIdentifiers, setThirdPartyIdentifiers ] = React.useState( null );
  const [ hostNames, setHostNames ] = React.useState( null );

  const setupAdditionalHostInfo = async () => {
    const hostResponse = await makeRequest( 'POST', 'fe/insights/host', {
      columns: [ 'host_names', 'third_party_identifiers' ],
      // eslint-disable-next-line camelcase
      field_in_map: {
        id: [ record.host_id ],
      },
      // eslint-disable-next-line camelcase
      order_by: [
        [ 'risk', 'DESC' ],
        [ 'id', 'ASC' ],
      ],
      rows: [ 0, 100 ],
    } );

    if ( isNotEmpty( hostResponse ) && itemIsArray( hostResponse ) ) {
      const [ hostInfo ] = hostResponse;

      if ( isNotEmpty( hostInfo ) ) {
        if ( isNotEmpty( hostInfo.host_names ) ) {
          setHostNames( hostInfo.host_names );
        } else {
          setHostNames( null );
        }
        console.log( hostInfo );
        if ( isNotEmpty( hostInfo.third_party_identifiers ) ) {
          setThirdPartyIdentifiers( hostInfo.third_party_identifiers );
        } else {
          setThirdPartyIdentifiers( null );
        }
      }
    }
  };

  React.useEffect( () => {
    if ( isNotEmpty( record ) && isNotEmpty( record.host_id ) ) {
      setupAdditionalHostInfo();
    }
  }, [ record ] );

  return (
    <React.Fragment>
      <div className="recordDetails__MainPanel__Body_DeepSurfaceScanning">
        <div className="recordDetails__MainPanel_BodyRow scanningStatusSummary">
          <h3 className="mainPanel__SectionHeader">DeepSurface Scanner Status</h3>
          <div className="bodySectionItem">
            <label>
              <span>Associated Host Names</span>
            </label>
            {
              isNotEmpty( hostNames )
                ? <pre>{ hostNames.join( ', ' ) }</pre>
                : <span>N/A</span>
            }
          </div>
          <div className="bodySectionItem">
            <label>
              <span>Associated Identifiers</span>
            </label>
            {
              isNotEmpty( thirdPartyIdentifiers )
                ? <pre>{ thirdPartyIdentifiers.join( ', ' ) }</pre>
                : <span>N/A</span>
            }
          </div>
          <div className="bodySectionItem">
            <label>
              <span>Latest scan status</span>
            </label>
            <ScanningStatusIndicator timestamp={ record?.last_scanned } />
          </div>
        </div>
        {
          ( isNotEmpty( record?.last_scanned ) && isNotEmpty( record?.last_scanned_address ) )
            ? <div className="recordDetails__MainPanel_BodyRow latestScanLog">
              <h3 className="mainPanel__SectionHeader">
                <span>Latest Scan Log</span>
              </h3>
              <ScanLogViewer logIdentifier={ record?.last_scanned_address } />
            </div>
            : <NotScannedEmptyState />
        }
      </div>
    </React.Fragment>
  );
};

export default DeepSurfaceScanning;