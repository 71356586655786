/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import { isNotEmpty } from '../../../../../shared/Utilities';
import { completionClass, nameForPlan, percentComplete } from '../../Shared';
import TaskItem from './TaskItem';
import './Step4.scss';

const Step4 = ( {
  setLoadingStep,
  transitionToStep,
  users,
  plan,
  // setPlan,
  planTasks,
  setPlanTasks,
  fullPlanDetails,
  getTasks,
  activeIntegrations,
} ) => {

  return (
    <React.Fragment>
      <div className="panel review remediationPanel">
        <div className="activePlanOverView" >
          <div className="column">
            <h2>
              { nameForPlan( fullPlanDetails ) }
            </h2>
            <div className="recordAttribute">
              <strong>Total Risk</strong>
              <span>{ Math.ceil( fullPlanDetails.risk ).toLocaleString( 'en-US' ) || 0 }</span>
            </div>
            <div className="recordAttribute">
              <strong>Total Instances</strong>
              <span>{ fullPlanDetails.num_instances.toLocaleString( 'en-US' ) || 0 }</span>
            </div>
          </div>
          <div className="column">
            <div className="completionWrapper risk">
              <label>Risk Reduced</label>
              {/* eslint-disable-next-line max-len */}
              <div className={`${completionClass( fullPlanDetails.original_risk, fullPlanDetails.risk )} completionBarWrapper risk`}>
                <div className="completionLabel">
                  {/* eslint-disable-next-line max-len */}
                  { percentComplete( fullPlanDetails.original_risk, fullPlanDetails.risk ) ? `${percentComplete( fullPlanDetails.original_risk, fullPlanDetails.risk )}%`: '0%' }
                </div>
                <div
                  className={ 'completionBar risk' }
                  style={{
                    width: percentComplete( fullPlanDetails.original_risk, fullPlanDetails.risk ) || '0%',
                  }}
                ></div>
              </div>
            </div>
            <div className="completionWrapper instances">
              <label>Instances Remediated</label>
              {/* eslint-disable-next-line max-len */}
              <div className={ `${completionClass( fullPlanDetails.original_num_instances, fullPlanDetails.num_instances )} completionBarWrapper instances` }>
                <div
                  className={ 'completionBar instances' }
                  style={{
                    width: percentComplete(
                      fullPlanDetails.original_num_instances, fullPlanDetails.num_instances,
                    ) || '0%',
                  }}
                ></div>
                <div className="completionLabel">
                  {/* eslint-disable-next-line max-len */}
                  { percentComplete( fullPlanDetails.original_num_instances, fullPlanDetails.num_instances ) ? `${percentComplete( fullPlanDetails.original_num_instances, fullPlanDetails.num_instances )}%`: '0%' }
                </div>
              </div>
            </div>
          </div>
          <button onClick={ () => transitionToStep( 1 ) } className="stepChangeButton">
            Edit Plan Details
          </button>
        </div>
        {
          isNotEmpty( planTasks?.selected ) &&
          <section>
            <h2>{ `Tasks included in plan (${planTasks?.selected.length})` }</h2>

            <ul className="taskList">
              {
                planTasks?.selected.map( ( task, index ) => {
                  return  <TaskItem
                    task={task}
                    key={index}
                    plan={plan}
                    setPlanTasks={ setPlanTasks }
                    setLoadingStep={setLoadingStep}
                    getTasks={getTasks}
                    users={users}
                    isSelected
                    isActive
                    activeIntegrations={activeIntegrations}
                  />;
                } )
              }
            </ul>
          </section>
        }
      </div>
    </React.Fragment>
  );
};

export default Step4;