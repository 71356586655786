/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import {
  capitalize,
  decodeURLHash,
  formatUnixTime,
  isNotEmpty,
  promiseAllSequential,
  shortenedVulnerabilityScannerNameMap,
  useLocalStorage,
} from '../../shared/Utilities';
import InformationPanel from './InformationPanel';
import MainPanel from './MainPanel';
import './style.scss';
import { makeRequest, search_model_records } from '../../../legacy/io';
import Loading from '../../shared/Loading';
import { getEscalationsForSignature } from './shared';
import RecordCard from './RecordCard';
import { getRecords } from '../../shared/RecordCache';

const RecordDetails = ( {
  recordType,
  record,
  options,
  riskType,
  selectRecord=() => {},
} ) => {

  // keeps track of which information (left) panels are in their collapsed state, and stores in localStorage
  const [
    collapsedInformationPanels,
    setCollapsedInformationPanels,
  ] = useLocalStorage(
    'dsRecordDetailsCollapsedInformationPanels',
    {
      host: false,
      patch: false,
      vulnerability: false,
      user: false,
      path: false,
    },
  );

  const [ renderContext, setRenderContext ] = React.useState( '' );
  const [ prefetchedLoading, setPrefetchedLoading ] = React.useState( false );
  const [ recordInstanceData, setRecordInstanceData ] = React.useState( null );
  const [ thirdPartyData, setThirdPartyData ] = React.useState( null );
  const [ thirdPartySettings, setThirdPartySettings ] = React.useState( null );
  const [ latestThirdParty, setLatestThirdParty ] = React.useState( null );
  const [ currentScannerSignature, setCurrentScannerSignature ] = React.useState( null );

  const [ relatedPaths, setRelatedPaths ] = React.useState( null );
  const [ pathCount, setPathCount ] = React.useState( 3 );

  // path recordType specific vars
  const [ pathSensitiveAsset, setPathSensitiveAsset ] = React.useState( null );
  const [ pathTopVulnerabilities, setPathTopVulnerabilities ] = React.useState( null );
  const [ loading, setLoading ] = React.useState( false );
  const [ pathFormattedEdges, setPathFormattedEdges ] = React.useState( null );

  // recordCard vars
  const [ recordCardRecord, setRecordCardRecord ] = React.useState( null );
  const [ recordCardType, setRecordCardType ] = React.useState( null );
  const [ showRecordCard, setShowRecordCard ] = React.useState( null );

  const needsInstanceData = [
    'host',
    'patch',
    'vulnerability',
  ];

  const needsThirdPartyData = [
    'host',
    'patch',
    'vulnerability',
  ];

  const needsRelatedPaths = [
    'host',
    'patch',
    'vulnerability',
    'user',
    'path',
  ];

  // need to grab all of the configured 3rd part settings so that certain tabs can display information about where
  // the different information came from, we only need the settings that are of type vulnerability_scanner
  const getThirdPartySettings = async () => {
    const allSettingsResponse = await makeRequest( 'SEARCH', '/project/default/third_party_setting', {
      // eslint-disable-next-line camelcase
      extra_columns: [ 'tool', 'settings', 'credential_id', 'category' ],
    } );

    if ( isNotEmpty( allSettingsResponse ) && isNotEmpty( allSettingsResponse.results ) ) {

      const _thirdPartySettings = {};

      allSettingsResponse.results.map( setting => {
        if ( setting.category === 'vulnerability_scanner' && setting.tool !== 'jira' ) {
          _thirdPartySettings[setting.id] = setting;
        }
      } );

      if ( isNotEmpty( _thirdPartySettings ) ) {
        setThirdPartySettings( _thirdPartySettings );
      }
    }
  };

  // need to hit the instance endpoint to get additional information for this item, this does a lot of heavy lifting
  // and is different depending on what reportType it is. For each reportType, we are going to have to make 3 group
  // calls and 1 tally call... in order to handle this, the requests will be sent syncronously and the page will render
  // once they are all finished
  // EDIT: group_by endpoints were not working out as expecting, only grabbing the tally
  const getInstanceInfo = async ( record, recordType ) => {
    setPrefetchedLoading( true );
    const promises = [];

    const tallyParams = {
      project: 'default',
      model: 'base',
      filters: {
        [`${recordType}_ids`]: [ record.id ],
        // eslint-disable-next-line camelcase
        risk_type: riskType,
      },
    };

    promises.push( () => makeRequest( 'TALLY', '/analysis/instance', tallyParams ) );

    const resolvedResponses = await promiseAllSequential( promises );

    const _instanceData = {
      tally: resolvedResponses[0],
    };

    setRecordInstanceData( _instanceData );
    setPrefetchedLoading( false );
  };

  // gets all of the related scanner signatures and loads the first one so that it is ready to view on the
  // third party scanning tab
  const getThirdPartyInfo = async ( record, recordType ) => {
    const params = {
      model: 'base',
      project: 'default',
      filters: {
        // eslint-disable-next-line camelcase
        extra_columns: [
          'scanner',
          'signature',
          'title',
          'signature_analysis.risk',
          'manual_html',
          'scanner_rating',
          'description',
          'service_info',
          'urls',
          'recommendation',
          'modified',
        ],
        rownums: [],
        // eslint-disable-next-line camelcase
        order_by: [
          [ 'modified', 'DESC' ],
          [ 'signature_analysis.risk', 'DESC' ],
        ],
        // eslint-disable-next-line camelcase
        id_list: record.scan_results || [],
      },
    };

    const _signatures = {};

    const signatureResponse = await makeRequest( 'SEARCH', '/model/signature', params );

    if ( isNotEmpty( signatureResponse ) && isNotEmpty( signatureResponse.results ) ) {

      const [ latest ] = signatureResponse.results;

      const formattedLatest = {
        scannerKey: latest.scanner,
        record: latest,
        label: `${ capitalize( shortenedVulnerabilityScannerNameMap[latest.scanner] )} Results:`,
        signature: latest.signature,
        type: recordType === 'host' ? 'scope': recordType,
        id: record.id,
        prefix: `${recordType}_${record.local_name}`,
        timestamp: formatUnixTime( latest.modified ),
      };

      signatureResponse.results.map( ( scanResult ) => {
        if ( scanResult.scanner !== 'internal' ) {
          const _signature = {
            scannerKey: scanResult.scanner,
            record: scanResult,
            label: `${ capitalize( shortenedVulnerabilityScannerNameMap[scanResult.scanner] )} Results:`,
            signature: scanResult.signature,
            type: recordType === 'host' ? 'scope': recordType,
            id: record.id,
            prefix: `${recordType}_${record.local_name}`,
            timestamp: formatUnixTime( scanResult.modified ),
          };
          _signatures[scanResult.id] = _signature;
        }
      } );
      setLatestThirdParty( formattedLatest );
      setThirdPartyData( _signatures );
      getScannerSignatureInfoAndSetAsCurrent( formattedLatest );
    }
  };

  const getEscalations = async ( edge ) => {
    // currently leaving escalations out of the cache becuase it would fill up so quickly,
    // so this function use is fine. -DMC 2022-02-16
    const _escalations = await search_model_records( 'escalation', {
      // eslint-disable-next-line camelcase
      field_map: { edge_id: edge.id},
      rownums: [],
      // eslint-disable-next-line camelcase
      extra_columns: [ 'vulnerability_id', 'escalation_analysis.risk' ],
    } );

    return _escalations;
  };

  const topPathReportType = ( recordType, riskType ) => {
    if ( recordType === 'user' ) {
      return 'domain_user';
    }
    if ( recordType === 'patch' ) {
      if ( riskType === 'risk' ) {
        return 'patch';
      }
      return 'direct_patch';
    }
    return recordType;
  };

  const handlePathCountChange = newCount => {
    setPathCount( newCount );
    getPathsInfo( record, recordType, newCount );
  };

  // gets the related Paths data here and passes it down to all the components that need it,
  const getPathsInfo = async( record, recordType, count=3 ) => {
    setLoading( true );

    // params for initial fetch of the related paths
    let params = {
      model: 'base',
      [`${topPathReportType( recordType, riskType )}_id`]: record.id,
      rownums: [ 0, count ],
    };

    if ( recordType === 'path' ) {
      params = {
        model: 'base',
        // eslint-disable-next-line camelcase
        path_ids: [ record.id ],
        rownums: [ 0, 1 ],
      };
    }

    // fetches the paths for this item
    const relatedPathsResponse = await makeRequest( 'FETCH', '/analysis/related_paths', params );

    setRelatedPaths( relatedPathsResponse );

    // a couple extra steps for the path
    if ( recordType === 'path' && isNotEmpty( relatedPathsResponse ) && isNotEmpty( relatedPathsResponse.results ) ) {

      // STEP 1) get all the scopes
      const scopeIDs = [];

      Object.values( relatedPathsResponse.results.nodes ).map( node => {
        if ( !scopeIDs.includes( node.scope_id ) ) {
          scopeIDs.push( node.scope_id );
        }
      } );

      if ( isNotEmpty( scopeIDs ) ) {
        // returns the needed scope labels and parent ids
        let scopesResponse = await getRecords( 'scope', {
          // eslint-disable-next-line camelcase
          extra_columns: [ 'label', 'risk' ],
          // eslint-disable-next-line camelcase
          id_list: scopeIDs,
        } );

        scopesResponse = scopesResponse.sort( ( a, b ) => b.risk - a.risk );

        const scopes = {};
        const edgeToVulnIDsMap = {};
        const vulnToEdgeIDsMap = {};

        scopesResponse.map( s => {
          scopes[s.id] = s;
        } );

        // STEP 2: Fetch all the edge escalations
        const escalationPromises = [];

        Object.values( relatedPathsResponse.results.edges ).map( ( edge ) => {
          escalationPromises.push( getEscalations( edge ) );
        } );

        const resolvedResponses = await Promise.all( escalationPromises );

        Object.values( relatedPathsResponse.results.edges ).map( ( edge, index ) => {
          const allVulnIDs = [];
          resolvedResponses[index].map( _e => {
            allVulnIDs.push( _e.vulnerability_id );
            if ( isNotEmpty( vulnToEdgeIDsMap[ _e.vulnerability_id ] ) ) {
              vulnToEdgeIDsMap[ _e.vulnerability_id ].push( edge.id );
            } else {
              vulnToEdgeIDsMap[ _e.vulnerability_id ] = [ edge.id ];
            }
          } );
          edgeToVulnIDsMap[edge.id] = allVulnIDs;
        } );

        let allVulnIDs = [];

        if ( isNotEmpty( edgeToVulnIDsMap ) ) {
          Object.values( edgeToVulnIDsMap ).map( vulnIDs => {
            allVulnIDs = [ ...allVulnIDs, ...vulnIDs ];
          } );
        }
        if ( isNotEmpty( allVulnIDs ) ) {
          const vulnParams = {
            // eslint-disable-next-line camelcase
            id_list: allVulnIDs,
            // eslint-disable-next-line camelcase
            extra_columns: [
              'vulnerability_analysis.hosts',
              'vulnerability_analysis.patches',
              'vulnerability_analysis.risk',
              'identifier',
              'modified',
              'identifier',
              'description',
              'urls',
              'cvss_base_score',
              'public_notes',
              'cvssv2',
              'cvssv3',
              'vulnerability_analysis.model_id',
              'vulnerability_analysis.vulnerability_id',
              'vulnerability_analysis.patches',
              'vulnerability_analysis.hosts',
              'vulnerability_analysis.scan_results',
              'vulnerability_analysis.risk',
              'vulnerability_analysis.risk_percentile',
              'vulnerability_analysis.exploit_status',
              'vulnerability_analysis.nofix',
            ],
            rownums: [ 0, allVulnIDs.length + 1 ],
            // eslint-disable-next-line camelcase
            order_by: [ [ 'vulnerability_analysis.risk', 'DESC' ] ],
          };
          // eslint-disable-next-line camelcase
          const vulnerabilityRecords = await getRecords( 'vulnerability', vulnParams );

          if ( isNotEmpty( vulnerabilityRecords ) ) {
            vulnerabilityRecords.map( v => {
              const edgeIDs = vulnToEdgeIDsMap[v.id];

              if ( isNotEmpty( edgeIDs ) ) {
                edgeIDs.map( edgeID => {
                  const edge = relatedPathsResponse.results.edges[edgeID];
                  if ( isNotEmpty( edge.vulnerabilities ) ) {
                    edge.vulnerabilities.push( v );
                  } else {
                    edge.vulnerabilities = [ v ];
                  }
                } );
              }
            } );
          }
        }

        // STEP 3: format the edges for display
        const [ path ] = relatedPathsResponse.results.paths;

        const _formattedEdges = [];
        const _topVulnerabilities = [];
        let _allVulnerabilities = [];

        path.edges.map( ( eID, index ) => {

          const edge = relatedPathsResponse.results.edges[eID];

          if ( isNotEmpty( edge ) ) {

            let vulnerabilities = [];

            if ( isNotEmpty( edge.vulnerabilities ) ) {
              vulnerabilities = Object.values( edge.vulnerabilities ).sort( ( a, b ) => b.risk - a.risk );
            }

            _allVulnerabilities = [ ..._allVulnerabilities, ...vulnerabilities ];

            if ( !edge.nofix ) {
              _topVulnerabilities.push( vulnerabilities[0] );
            }

            const _formattedEdge = {
              id: eID,
              risk: edge.risk,
              riskPercentile: edge.risk_percentile,
              nofix: edge.nofix,
              fromNode: relatedPathsResponse.results.nodes[edge.from_node],
              toNode: relatedPathsResponse.results.nodes[edge.to_node],
              fromScope: scopes[edge.from_scope],
              toScope: scopes[edge.to_scope],
              vulnerabilities,
            };

            if ( index === path.edges.length - 1 ) {
              setPathSensitiveAsset( {
                node: relatedPathsResponse.results.nodes[edge.to_node],
                scope: scopes[edge.to_scope],
              } );
            }
            _formattedEdges.push( _formattedEdge );
          }
        } );
        setLoading( false );
        setPathTopVulnerabilities( _topVulnerabilities );
        setPathFormattedEdges( _formattedEdges );
      }
    } else {
      setLoading( false );
    }
  };

  const getScannerSignatureInfoAndSetAsCurrent = async ( _signature ) => {
    if ( isNotEmpty( _signature ) ) {
      const _currentScannerSignature = {
        record: _signature,
      };

      const escalationsResponse = await getEscalationsForSignature( _signature );

      if ( isNotEmpty( escalationsResponse ) ) {
        if ( isNotEmpty( escalationsResponse.vulnerabilities ) ) {
          // eslint-disable-next-line max-len
          const _vulnerabilities = Object.values( escalationsResponse.vulnerabilities ).sort( ( a, b ) => b.risk - a.risk );
          _currentScannerSignature.vulnerabilities = _vulnerabilities;
        }

        if ( isNotEmpty( escalationsResponse.instanceAndEscalationData ) ) {
          _currentScannerSignature.instance = escalationsResponse.instanceAndEscalationData.instance;
          _currentScannerSignature.signature = escalationsResponse.instanceAndEscalationData.signature?.record;
          _currentScannerSignature.escalation = escalationsResponse.instanceAndEscalationData.escalation;
        }
      }
      setCurrentScannerSignature( _currentScannerSignature );
    }
  };

  React.useEffect( () => {
    if ( isNotEmpty( record ) && isNotEmpty( recordType ) && isNotEmpty( riskType ) ) {
      if ( needsInstanceData.includes( recordType ) ) {
        getInstanceInfo( record, recordType );
      }
      if ( needsThirdPartyData.includes( recordType ) ) {
        getThirdPartyInfo( record, recordType );
        getThirdPartySettings();
      }
      if ( needsRelatedPaths.includes( recordType ) ) {
        getPathsInfo( record, recordType );
      }
    }
  }, [ record, recordType, riskType ] );

  // sets the render context so that we know where this is being displayed, almost always the recordDetail page... but
  // sometimes it could be in other places
  React.useEffect( () => {
    if ( isNotEmpty( options ) && 'renderContext' in options ) {
      setRenderContext( options.renderContext );
    } else {
      const hash = decodeURLHash();
      setRenderContext( hash['.'] );
    }
  }, [ record, recordType, options ] );

  const toggleCollapse = () => {
    if ( isNotEmpty( recordType ) ) {
      const _current = collapsedInformationPanels[recordType];

      setCollapsedInformationPanels( { ...collapsedInformationPanels, [recordType]: !_current } );
    }
  };

  // eslint-disable-next-line max-len
  const isCollapsed = () => isNotEmpty( recordType ) && isNotEmpty( collapsedInformationPanels ) && collapsedInformationPanels[recordType] === true;

  return (
    <React.Fragment>
      { prefetchedLoading && <Loading /> }
      <RecordCard
        record={recordCardRecord}
        type={recordCardType}
        show={showRecordCard}
        setShow={setShowRecordCard}
        context="record_details"
        options= { {
          isDismissable: true,
          // isDraggable: true,
          // eslint-disable-next-line camelcase
          include_shade: true,
        } }
      />
      {
        isNotEmpty( record ) &&
        <div className={ `recordDetailsContainerV2 ${isCollapsed() ? 'isCollapsed' : '' } ${recordType}` }>
          <InformationPanel
            record={record}
            recordType={recordType}
            riskType={riskType}
            isCollapsed={isCollapsed}
            renderContext={renderContext}
            options={options}
            recordInstanceData={recordInstanceData}
            thirdPartyData={thirdPartyData}
            currentScannerSignature={currentScannerSignature}
            selectRecord={selectRecord}
            toggleCollapse={toggleCollapse}
            latestThirdParty={latestThirdParty}

            // path recordType vars
            pathSensitiveAsset={pathSensitiveAsset}
            pathTopVulnerabilities={pathTopVulnerabilities}
          />
          <MainPanel
            record={record}
            recordType={recordType}
            riskType={riskType}
            isCollapsed={isCollapsed}
            currentScannerSignature={currentScannerSignature}
            recordInstanceData={recordInstanceData}
            setCurrentScannerSignature={setCurrentScannerSignature}
            thirdPartyData={thirdPartyData}
            setThirdPartyData={setThirdPartyData}
            relatedPaths={relatedPaths}
            setRelatedPaths={setRelatedPaths}
            pathCount={pathCount}
            setPathCount={setPathCount}
            handlePathCountChange={handlePathCountChange}
            latestThirdParty={latestThirdParty}
            getScannerSignatureInfoAndSetAsCurrent={getScannerSignatureInfoAndSetAsCurrent}
            thirdPartySettings={thirdPartySettings}
            // recordCard variables
            recordCardRecord={recordCardRecord}
            setRecordCardRecord={setRecordCardRecord}
            recordCardType={recordCardType}
            setRecordCardType={setRecordCardType}
            showRecordCard={showRecordCard}
            setShowRecordCard={setShowRecordCard}

            // path recordType vars
            pathSensitiveAsset={pathSensitiveAsset}
            pathTopVulnerabilities={pathTopVulnerabilities}
            loading={loading}
            pathFormattedEdges={pathFormattedEdges}
          />
        </div>
      }
    </React.Fragment>
  );
};

export default RecordDetails;