/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import Form from '../../../shared/Form';
import { credOptions, credPamSettings, getFieldValues } from '../../../shared/Form/Shared';
import { isEmpty, isEqual, isNotEmpty } from '../../../shared/Utilities';

import {
  CONDITIONAL_CRED_OPTIONS,
  CRED_OPTIONS,
  PAM_OPTIONS,
  recordData,
} from './data';
import { CurrentUserContext } from '../../../Contexts/CurrentUser';
import { canConfigure } from '../../App/AccessControl';

// eslint-disable-next-line camelcase
const DEFAULT_WORKING_RECORD = { options: { elevate_method: 'null', ports: [ 22 ] } };

const CredentialModal = ( {
  selectedRecord,
  showModal,
  setShowModal,
  onSave,
} ) => {

  // STEP 1) These fields decide what form to build
  const [ selectionFields, setSelectionFields ] = React.useState( null );
  const [ selectionFieldStates, setSelectionFieldStates ] = React.useState( null );

  // an existing "record" for the left column
  const [ protocolPlusPam, setProtocolPlusPam ] = React.useState( null );
  // an existing "record" for the right column
  const [ workingRecord, setWorkingRecord ] = React.useState( DEFAULT_WORKING_RECORD );

  // STEP 2) The fields for the form that needs to be filled out depending on what credential you are building
  const [ credentialFields, setCredentialFields ] = React.useState( null );
  const [ credentialFieldStates, setCredentialFieldStates ] = React.useState( null );
  const [ withPAM, setWithPAM ] = React.useState( false );
  const [ onlyPAM, setOnlyPAM ] = React.useState( false );
  const [ isValid, setIsValid ] = React.useState( true );
  const [ , , licenseInfo ] = React.useContext( CurrentUserContext );

  // 1) First thing to happen, if there is a record then we are editing, and we need to fill out the form
  // and show the correctly selected options
  React.useEffect( ( ) => {
    // no record present, we are creating a new one
    if ( isEmpty( selectedRecord ) ) {
      setWorkingRecord( DEFAULT_WORKING_RECORD );

      recordData.typeSelectionFields[2].options = CRED_OPTIONS;
      // eslint-disable-next-line max-len
      recordData.typeSelectionFields[2].conditionalOptions = { attribute: 'pam_type', options: CONDITIONAL_CRED_OPTIONS };
      recordData.typeSelectionFields[2].disabled = false;
      setSelectionFields( recordData.typeSelectionFields );
      // eslint-disable-next-line camelcase
      setProtocolPlusPam( { protocol: 'smb-wmi', pam_type: 'none', label: '', eval_order: null } );
    // need create an 'existingRecord' for the selection form so that it can populate fields correctly
    } else {
      // using a duplicate selected record so that I do not pollute the original,
      // this is adjusted and passed along to the form as the selected record in order to correctly
      // populate the fields when switching between options in the first column
      setWorkingRecord( selectedRecord );

      recordData.typeSelectionFields[2].options = CRED_OPTIONS;
      // eslint-disable-next-line max-len
      recordData.typeSelectionFields[2].conditionalOptions = { attribute: 'pam_type', options: CONDITIONAL_CRED_OPTIONS };
      recordData.typeSelectionFields[2].disabled = false;

      const _protocolPlusPam = {
        protocol: selectedRecord.protocol,
        label: selectedRecord.label,
        // eslint-disable-next-line camelcase
        eval_order: selectedRecord.eval_order,
      };
      if ( isNotEmpty( selectedRecord?.pam_type ) ) {
        // eslint-disable-next-line camelcase
        _protocolPlusPam.pam_type = selectedRecord.pam_type ;
      }
      setProtocolPlusPam( _protocolPlusPam );
    }
  }, [ selectedRecord ] );

  // 2) called whenever any of the fields in the selection portion change, sets up the main form
  const handleSelectionChanges = selectionFormState => {
    setCredentialFields( null );
    const credentialValues = getFieldValues( credentialFieldStates, 'scan_credential' );

    /* eslint-disable camelcase */
    const cred = {};
    const options = {};
    const pam_settings ={};
    let _workingRecord = { ...workingRecord };
    // need to "un-flatten" the attributes and nest them in the correct place before setting the working record,
    // need to do this one section at a time in order to ensure that we are not overwriting data
    if ( isNotEmpty( credentialValues ) ) {
      Object.entries( credentialValues ).map( ( [ attr, val ] ) => {
        if ( credOptions.includes( attr ) ) {
          options[attr] = val;
        } else if ( credPamSettings.includes( attr ) ) {
          pam_settings[attr] = val;
        } else {
          cred[attr] = val;
        }
      } );
    }

    if ( isNotEmpty( options ) ) {
      if ( isNotEmpty( _workingRecord.options ) ) {
        _workingRecord.options = { ..._workingRecord.options, ...options };
      } else {
        _workingRecord.options = options;
      }
    }

    if ( isNotEmpty( pam_settings ) ) {
      if ( isNotEmpty( _workingRecord.pam_settings ) ) {
        _workingRecord.pam_settings = { ..._workingRecord.pam_settings, ...pam_settings };
      } else {
        _workingRecord.pam_settings = pam_settings;
      }
    }

    const workingAttributes = [
      'username',
      'secret',
      'domain',
      'pam_client_cert',
      'pam_client_key',
      'ssh-key-passphrase',
    ];

    // we are editing an existing record, need to make some adjustsments to the cred so that we populate the form
    // correctly
    if ( isNotEmpty( selectedRecord ) ) {
      // map through all possible attrs that are on the base cred and check for different conditions
      workingAttributes.map( attr => {
        // if the cred and the workingrecord do not match, we need to make some adjustments
        if ( !isEqual( _workingRecord[attr], cred[attr] ) ) {
          // the original record has a value, but the form is null, defer to the record
          if ( isNotEmpty( _workingRecord[attr] ) && isEmpty( cred[attr] ) ) {
            cred[attr] = _workingRecord[attr];
          }
        }
      } );
    }

    _workingRecord = { ..._workingRecord, ...cred };

    setWorkingRecord( _workingRecord );

    if ( isNotEmpty( selectionFormState ) && isNotEmpty( selectionFormState.fieldStates ) ) {
      setSelectionFieldStates( selectionFormState.fieldStates );

      const protocolSelectionKey = selectionFormState?.fieldStates?.protocol?.updatedValue;
      let pamSelectionKey = selectionFormState?.fieldStates?.pam_type?.updatedValue;

      if ( pamSelectionKey === '*' || pamSelectionKey === '' || isEmpty( pamSelectionKey ) ) {
        pamSelectionKey = 'none';
      }

      if ( pamSelectionKey !== 'none' ) {
        setWithPAM( true );
      } else {
        setWithPAM( false );
        setOnlyPAM( false );
      }

      const fields = recordData.credentialFields[pamSelectionKey][protocolSelectionKey];

      if ( isNotEmpty( fields ) && isNotEmpty( fields.pam ) && isNotEmpty( pamSelectionKey ) ) {
        fields.pam.header = `${PAM_OPTIONS[pamSelectionKey]}`;
      }
      setCredentialFields( fields );
    }
  };

  // 3) called whenever a field in the main credential form changes, used to save the credential when the user is done
  const handleCredentialFormChanges = credentialFormState => {
    if ( isNotEmpty( credentialFormState ) && isNotEmpty( credentialFormState.fieldStates ) ) {
      setCredentialFieldStates( credentialFormState.fieldStates );
    }
  };

  // 4) whenever the modal closes, reset the working record
  React.useEffect( ( ) => {
    if ( showModal === false ) {
      setWorkingRecord( DEFAULT_WORKING_RECORD );
    }
  }, [ showModal ] );

  return (
    <div className="scanCredentialsModalWrapper">
      {
        isNotEmpty( selectionFields ) &&
        <div className="column">
          <div className="columnHeader">
            <span className="stepNumber">1</span>
            <h3>Protocol + PAM</h3>
          </div>
          <Form
            fields={selectionFields}
            recordType="credTypeSelection"
            trackUpdates={false}
            onChangeCallback={ handleSelectionChanges }
            existingRecord={protocolPlusPam}
          />
        </div>
      }
      {
        isNotEmpty( credentialFields ) &&
        <div className="column fullForm">
          <div className="columnHeader">
            <span className="stepNumber">2</span>
            <h3>Settings</h3>
          </div>
          <Form
            existingRecord={workingRecord}
            setIsValid={setIsValid}
            fields={credentialFields}
            recordType="scan_credential"
            trackUpdates={ isNotEmpty( selectedRecord ) }
            onChangeCallback={ handleCredentialFormChanges }
            elementClass={ `${withPAM ? 'withPAM' : ''} ${onlyPAM ? 'onlyPAM' : ''}`}
          />
        </div>
      }
      <div className="modalActions">
        <button
          disabled={ !isValid || !canConfigure( licenseInfo ) }
          className={`${isValid ? '' : 'disabled'} submitButton`}
          onClick={ () => onSave(
            selectedRecord,
            isValid,
            { ...selectionFieldStates, ...credentialFieldStates },
            () => setShowModal( false ),
          ) }
        >
          Save Changes
        </button>
        <button
          className="cancelButton"
          onClick={ () => setShowModal( false ) }
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default CredentialModal;