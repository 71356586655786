/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
import React from 'react';

import { Draggable } from 'react-beautiful-dnd';

import {
  CurrentUserContext,
} from '../../Contexts/CurrentUser';

import {
  isNotEmpty,
  isEmpty,
  capitalize,
  decodeURLHash,
  manualVulnerabilityScanners,
} from '../Utilities';

import {
  getDisplayValueFor,
  getDeletePathForType,
  deleteRecord,
  deleteClique,
  editRecord,
} from './shared';
import { makeRequest } from '../../../legacy/io';
import InlineSVG from '../InlineSVG';
import { closeRemediationPlan } from '../../components/Remediation/RemediationPlans/Shared';
import { FlashMessageQueueContext } from '../../Contexts/FlashMessageQueue';

// specific content for each recordType
import UserItem from '../../components/Setup/Authentication/Users/UserItem';
import VulnerabilityScannerItem from '../../components/Scanning/VulnerabilityScanners/VulnerabilityScannerItem';
import PolicyItem from '../../components/Setup/Settings/SensitiveAssets/PolicyItem';
import CredentialItem from '../../components/Scanning/ScanCredentials/CredentialItem';
import RemediationPlanItem from '../../components/Remediation/RemediationPlans/RemediationPlanItem';
import IntegrationItem from '../../components/Remediation/RemediationTicketing/IntegrationItem';
import TagItem from '../../components/Setup/Tags/TagItemV2';
import CertificateItem from '../../components/Setup/Settings/Certificates/CertificateItem';
import SubordinateScannerItem from '../../components/Scanning/SubordinateScanners/SubordinateScannerItem';
import CloudScanningItem from '../../components/Scanning/CloudScanning/CloudScanningItem';
import CliqueItem from '../../components/Scanning/NetworkConnectivity/CliqueItem';
// eslint-disable-next-line max-len
import AuthenticationProviderItem from '../../components/Setup/Authentication/AuthenticationProviders/AuthenticationProviderItem';
import AcceptedRiskPlanItem from '../../components/Remediation/AcceptedRisk/AcceptedRiskPlanItem';

// specific modals for different types
import DownloadCertificate from '../../components/Setup/Settings/Certificates/DownloadCertificate';
import ViewCertificate from '../../components/Setup/Settings/Certificates/ViewCertificate';
import FileUploader from '../../components/Scanning/VulnerabilityScanners/FileUploader';
import UserNotificationSettingsModal from '../../components/Setup/Authentication/Users/UserNotificationSettingsModal';
import TaskRunner from '../TaskRunner';
import RemediationExportMenu from '../../components/Remediation/RemediationPlans/RemediationExportMenu';

import './RecordItem.scss';
import ScanGroupItem from '../../components/Scanning/ScanGroups/ScanGroupItem';
import { canConfigure, hasFeatureAccess } from '../../components/App/AccessControl';


const RecordItem = ( {
  record,
  records,
  index,
  recordType,
  providers,
  credentials,
  setSelectedRecord,
  setShowModal,
  onRefresh,
  recordData,
  isDraggable,
  showRegistrationModal,
  asCard,
  alternateItemLayout,
  users,
  globalSettings,
  notificationsCallback,
  activeIntegrations,
  externalUsers,
  assetTagOptions,
  setShowIPModal,
  setShowConnectionModal,
} ) => {

  const [ currentUser, , licenseInfo ] = React.useContext( CurrentUserContext );
  const [ addFlashMessage, , , ] = React.useContext( FlashMessageQueueContext );

  const [ showUploader, setShowUploader ] = React.useState( false );

  React.useEffect( () => {
    const _recordID = decodeURLHash().selected_record;
    if ( _recordID && _recordID === record.id ) {
      editRecord( record, setSelectedRecord, setShowModal, recordType );
    }
  }, [] );

  const showIP = () => {
    setSelectedRecord( record );
    setShowIPModal( true );
  };
  const showConnectivity = () => {
    setSelectedRecord( record );
    setShowConnectionModal( true );
  };

  const customActions = {
    // eslint-disable-next-line camelcase
    vulnerability_source: manualVulnerabilityScanners.includes( record.tool )
      ? [
        <button
          title={`Upload ${capitalize( recordType )}`}
          className={ `recordItemAction ${!canConfigure( licenseInfo ) ? 'disabled' : ''}` }
          disabled={ !canConfigure( licenseInfo ) }
          onClick={ () => uploadRecord() }
        >
          <InlineSVG type="upload" />
        </button>,
      ]
      : [],
    // eslint-disable-next-line camelcase
    scan_credential: [
      <button
        title={`Duplicate ${capitalize( recordType )}`}
        className={ `recordItemAction ${!hasFeatureAccess( 'f_credentialed_scan', licenseInfo ) ? 'disabled' : ''}` }
        disabled={ !hasFeatureAccess( 'f_credentialed_scan', licenseInfo ) }
        onClick={ () => copyRecord( record.id ) }
      >
        <InlineSVG type="copy" />
      </button>,
    ],
    certificate: [
      <DownloadCertificate certificate={record} />,
      <ViewCertificate certificate={record} />,
      <button
        title={`Duplicate ${capitalize( recordType )}`}
        // eslint-disable-next-line max-len
        className={ `recordItemAction ${!hasFeatureAccess( 'f_certificate_configuration', licenseInfo ) ? 'disabled' : ''}` }
        disabled={ !hasFeatureAccess( 'f_certificate_configuration', licenseInfo ) }
        onClick={ () => copyCertificate() }
      >
        <InlineSVG type="copy" />
      </button>,
    ],
    user: [
      <UserNotificationSettingsModal user={record} />,
    ],
    // eslint-disable-next-line camelcase
    scan_group: [
      <button
        title={'Test Scan Configuration'}
        className={ `recordItemAction ${!hasFeatureAccess( 'f_credentialed_scan', licenseInfo ) ? 'disabled' : ''}` }
        disabled={ !hasFeatureAccess( 'f_credentialed_scan', licenseInfo ) }
        onClick={ showConnectivity }
      >
        <InlineSVG type="network" />
      </button>,
      <button
        title={'View Scan Group Information'}
        className={ `recordItemAction ${!hasFeatureAccess( 'f_credentialed_scan', licenseInfo ) ? 'disabled' : ''}` }
        disabled={ !hasFeatureAccess( 'f_credentialed_scan', licenseInfo ) }
        onClick={ showIP }
      >
        <InlineSVG type="visible" />
      </button>,
      <button
        title={`Duplicate ${capitalize( recordType )}`}
        className={ `recordItemAction ${!hasFeatureAccess( 'f_credentialed_scan', licenseInfo ) ? 'disabled' : ''}` }
        disabled={ !hasFeatureAccess( 'f_credentialed_scan', licenseInfo ) }
        onClick={ () => copyScanGroup() }
      >
        <InlineSVG type="copy" />
      </button>,
    ],
    // eslint-disable-next-line camelcase
    remediation_plan: record.status === 'active'
      ? [
        <RemediationExportMenu plan={record} activeIntegrations={activeIntegrations} />,
        <button
          title={`Close ${capitalize( recordType )}`}
          className={ `recordItemAction ${!hasFeatureAccess( 'f_remediation', licenseInfo ) ? 'disabled' : ''}` }
          disabled={ !hasFeatureAccess( 'f_remediation', licenseInfo ) }
          onClick={ () => closePlan( record ) }
        >
          <InlineSVG type="checkmarkCircle" />
        </button>,
      ]
      : [],
  };

  const closePlan = record => {
    if ( confirm( 'Are you sure you want to close this remediation plan?' ) ) {
      closeRemediationPlan( record ).then( response => {
        if ( isNotEmpty( response.results ) ) {
          onRefresh();
          addFlashMessage( {
            type: 'success',
            body: 'successfully closed remediation plan',
          } );
        }
      } );
    }
  };

  const copyCertificate = () => {
    const newCertifcate = {
      source: 'manual',
      type: record.type,
      purpose: record.purpose,
      subpurpose: record.subpurpose,
      key: btoa( record.key ),
      identity: record.identity,
    };
    makeRequest( 'UPSERT', '/certificate', {'records':[ newCertifcate ]} ).then( () => {
      onRefresh();
    } );
  };

  const copyRecord = id => {
    makeRequest( 'COPY', `${getDeletePathForType( recordType )}`, {'id': id } ).then( () => {
      onRefresh();
    } );
  };

  const copyScanGroup = () => {
    const newScanGroup = Object.assign( {}, record );

    delete newScanGroup.created;
    delete newScanGroup.modified;
    delete newScanGroup.id;

    newScanGroup.label = `Copy of ${record.label}`;
    makeRequest( 'UPSERT', '/project/default/scan_group', { 'records': [ newScanGroup ] } ).then( response => {
      onRefresh();
      if ( response['errors'] ) {
        addFlashMessage( {
          body: response['errors'],
          type: 'alert',
        } );
      } else {
        addFlashMessage( {
          body: 'Successfully copied Scan Group',
          type: 'success',
        } );
      }
    } );
  };

  const onDelete = () => {
    const successCallBack = () => {
      let body = 'Successfully deleted record';

      if ( recordType === 'accepted_risk_plan' ) {
        body = <React.Fragment>
          Successfully deleted record. It may be necessary to run a full analysis in order to properly re-show all of
          the items in the DeepSurface threat model. To run a full analysis go to
          the <a target="_blank" href="#.=activity&page=tasks">{ 'Activity > Tasks' }</a> page and run
          the "Risk Analysis and Prioritization" task.
        </React.Fragment>;
      }
      addFlashMessage( {
        body,
        type: 'success',
      } );
      onRefresh();
    };

    const failureCallBack = () => {
      addFlashMessage( {
        body: 'There was an error deleting the record',
        type: 'alert',
      } );
      onRefresh();
    };

    deleteRecord( record, recordType, successCallBack, failureCallBack );
  };

  const uploadRecord = () => {
    setShowUploader( true );
  };

  const finishUploader = () => {
    setShowUploader( false );
  };

  const canDelete = () => {
    if ( recordType === 'user' ) {
      if ( isNotEmpty( currentUser ) && currentUser.id === record.id ) {
        return false;
      }
      return true;
    }

    if ( recordType === 'vulnerability_source' ) {
      if ( record.tool === 'tanium' ) {
        return false;
      }
    }

    if ( recordType === 'authentication_provider' ) {
      if ( record.type === 'internal' ) {
        return false;
      }
      return true;

    }
    if ( recordType === 'subordinate_scanner' ) {
      if ( record.type === 'local' ) {
        return false;
      }
      return true;

    }
    return true;
  };

  const canRunTask = () => {
    if ( recordType === 'scan_group' || recordType === 'vulnerability_source' ) {
      return true;
    }
    return false;
  };

  const taskTypeForRecord = {
    // eslint-disable-next-line camelcase
    scan_group: 'authenticated',
    // eslint-disable-next-line camelcase
    vulnerability_source: 'import',
  };

  const canEdit = () => {
    if ( recordType === 'authentication_provider' ) {
      if ( record.type === 'internal' ) {
        return false;
      }
      return true;
    }

    if ( recordType === 'vulnerability_source' ) {
      if ( record.tool === 'tanium' ) {
        return false;
      }
    }

    if ( recordType === 'vulnerability_source' ) {
      if ( isEmpty( record.settings ) ) {
        return false;
      }
      return true;
    }

    if ( recordType === 'certificate' ) {
      if ( record.source === 'observed' ) {
        return false;
      }
      return true;
    }

    if ( recordType === 'remediation_plan' ) {
      if ( record.status === 'closed' ) {
        return false;
      }
      return true;
    }

    if ( recordType === 'accepted_risk_plan' ) {
      return false;
    }

    return true;
  };

  const customActionsFor = type => {
    if ( customActions[type] ) {
      return customActions[type];
    }
    return [];

  };

  const onDeleteCallback = () => {
    addFlashMessage( {
      body: 'Successfully deleted clique',
      type: 'success',
    } );
    onRefresh();
  };

  const isSelected = ( record, type ) => {
    if (
      type === 'authentication_provider'
      && isNotEmpty( globalSettings )
    ) {
      return globalSettings.autoprovision_auth_provider === record.id;
    }
    return false;
  };

  return (
    <React.Fragment>
      <FileUploader
        showUploader={showUploader}
        setShowUploader={setShowUploader}
        record={record}
        finishUploader={finishUploader}
      />
      {
        recordType === 'remediation_plan'
          ? <li
            // eslint-disable-next-line max-len
            className={ `setupRecordItem  item ${ isDraggable ? 'isDraggable' : '' } ${recordType} ${alternateItemLayout ? 'alternateLayout' : ''} ${asCard ? 'asCard' : ''} ${isSelected( record, recordType ) ? 'selected' : ''} ${recordType === 'certificate' && record.source === 'observed' ? 'observed' : ''}` }
          >
            {
              recordType === 'remediation_plan' &&
              <RemediationPlanItem plan={record} users={users} activeIntegrations={activeIntegrations} />
            }
            {
              isNotEmpty( recordData.display ) &&
              Object.keys( recordData.display ).map( ( displayAttribute, index ) => {
                return  <div
                  className="itemAttribute"
                  key={index}
                >
                  <strong>{ recordData.display[displayAttribute] }</strong>
                  <span>
                    {
                      getDisplayValueFor(
                        recordType,
                        displayAttribute,
                        record,
                        providers,
                        credentials,
                      )
                    }
                  </span>
                </div>;
              } )
            }
            <div className="itemActions">
              {
                isNotEmpty( customActionsFor( recordType ) ) &&
                customActionsFor( recordType ).map( ( action, index ) => {
                  return  <React.Fragment
                    key={index}
                  >
                    { action }
                  </React.Fragment>;
                } )
              }
              {
                canEdit() &&
                <button
                  className="recordItemAction"
                  onClick={ () => editRecord( record, setSelectedRecord, setShowModal, recordType )}
                  title={`Edit ${capitalize( recordType )}`}
                >
                  <InlineSVG type="edit" />

                </button>
              }
              {
                canDelete() &&
                <React.Fragment>
                  {
                    recordType === 'connectivity_clique'
                      ? <button
                        className={ `recordItemAction ${!canConfigure( licenseInfo ) ? 'disabled' : ''}` }
                        disabled={ !canConfigure( licenseInfo ) }
                        onClick={ () => deleteClique( record, records, onDeleteCallback ) }
                        title={`Delete ${capitalize( recordType )}`}
                      >
                        <InlineSVG type="delete" />
                      </button>
                      : <button
                        className={ `recordItemAction ${!canConfigure( licenseInfo ) ? 'disabled' : ''}` }
                        disabled={ !canConfigure( licenseInfo ) }
                        onClick={ onDelete }
                        title={`Delete ${capitalize( recordType )}`}
                      >
                        <InlineSVG type="delete" />
                      </button>
                  }
                </React.Fragment>
              }
            </div>
          </li>
          : <Draggable
            isDragDisabled={ !isDraggable }
            draggableId={`record-draggable-${index}`}
            index={index}>
            {
              ( provided, snapshot ) => (
                <li
                  // eslint-disable-next-line max-len
                  className={ `setupRecordItem  item ${ isDraggable ? 'isDraggable' : '' }  ${snapshot.isDragging ? 'isDragging' : ''} ${recordType} ${alternateItemLayout ? 'alternateLayout' : ''} ${asCard ? 'asCard' : ''} ${isSelected( record, recordType ) ? 'selected' : ''} ${recordType === 'certificate' && record.source === 'observed' ? 'observed' : ''}` }
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                >
                  {
                    recordType === 'sensitive_asset_policy' &&
                 <PolicyItem policy={record} assetTagOptions={assetTagOptions} index={index} />
                  }
                  {
                    recordType === 'scan_credential' &&
                 <CredentialItem credential={record} index={index} />
                  }
                  {
                    recordType === 'remediation_plan' &&
                 <RemediationPlanItem plan={record} users={users} activeIntegrations={activeIntegrations} />
                  }
                  {
                    recordType === 'accepted_risk_plan' &&
                 <AcceptedRiskPlanItem plan={record} />
                  }
                  {
                    recordType === 'integration' &&
                 <IntegrationItem integration={record} />
                  }
                  {
                    recordType === 'user' &&
                 <UserItem user={record} externalUsers={externalUsers} index={index} />
                  }
                  {
                    recordType === 'tag' &&
                 <TagItem tag={record} users={users} />
                  }
                  {
                    recordType === 'vulnerability_source' &&
                 <VulnerabilityScannerItem vulnerabilityScanner={record} />
                  }
                  {
                    recordType === 'cloud_scanner' &&
                 <CloudScanningItem cloudScanner={record} />
                  }
                  {
                    recordType === 'scan_group' &&
                 <ScanGroupItem scanGroup={record} />
                  }
                  {
                    recordType === 'connectivity_clique' &&
                 <CliqueItem clique={record} recordData={recordData} />
                  }
                  {
                    recordType === 'subordinate_scanner' &&
                 <SubordinateScannerItem  scanner={record} showRegistrationModal={showRegistrationModal} />
                  }
                  {
                    recordType === 'certificate' &&
                 <CertificateItem certificate={record} />
                  }
                  {
                    recordType === 'authentication_provider' &&
                 <AuthenticationProviderItem provider={record} globalSettings={globalSettings} />
                  }
                  {
                    isNotEmpty( recordData.display ) &&
                 Object.keys( recordData.display ).map( ( displayAttribute, index ) => {
                   return  <div
                     className="itemAttribute"
                     key={index}
                   >
                     <strong>{ recordData.display[displayAttribute] }</strong>
                     <span>
                       {
                         getDisplayValueFor(
                           recordType,
                           displayAttribute,
                           record,
                           providers,
                           credentials,
                         )
                       }
                     </span>
                   </div>;
                 } )
                  }
                  <div className="itemActions">
                    {
                      isNotEmpty( customActionsFor( recordType ) ) &&
                      customActionsFor( recordType ).map( ( action, index ) => {
                        return  <React.Fragment
                          key={index}
                        >
                          { action }
                        </React.Fragment>;
                      } )
                    }
                    {
                      canEdit() &&
                      <button
                        className="recordItemAction"
                        onClick={ () => editRecord( record, setSelectedRecord, setShowModal, recordType )}
                        title={`Edit ${capitalize( recordType )}`}
                      >
                        <InlineSVG type="edit" />

                      </button>
                    }
                    {
                      canDelete() &&
                      <React.Fragment>
                        {
                          recordType === 'connectivity_clique'
                            ? <button
                              className={ `recordItemAction ${!canConfigure( licenseInfo ) ? 'disabled' : ''}` }
                              disabled={ !canConfigure( licenseInfo ) }
                              onClick={ () => deleteClique( record, records, onDeleteCallback ) }
                              title={`Delete ${capitalize( recordType )}`}
                            >
                              <InlineSVG type="delete" />
                            </button>
                            : <button
                              className={ `recordItemAction ${!canConfigure( licenseInfo ) ? 'disabled' : ''}` }
                              disabled={ !canConfigure( licenseInfo ) }
                              onClick={ onDelete }
                              title={`Delete ${capitalize( recordType )}`}
                            >
                              <InlineSVG type="delete" />
                            </button>
                        }
                      </React.Fragment>
                    }
                    {
                      canRunTask() &&
                      <TaskRunner
                        record={record}
                        taskType={taskTypeForRecord[recordType]}
                        notificationsCallback={notificationsCallback}
                      />
                    }
                    {
                      isDraggable &&
                      <React.Fragment>
                        <span
                          className={ `${!canConfigure( licenseInfo ) ? 'disabled' : ''} recordItemAction draggable` }
                        >
                          <InlineSVG type="draggable" />
                        </span>
                        <span
                          // eslint-disable-next-line max-len
                          className={ `${!canConfigure( licenseInfo ) ? 'disabled' : ''} recordItemAction draggable current` }
                        >
                          <InlineSVG type="draggable" version="primary" />
                        </span>
                      </React.Fragment>
                    }
                  </div>
                </li>
              ) }
          </Draggable>
      }
    </React.Fragment>
  );
};

export default RecordItem;
