/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import InlineSVG from '../InlineSVG';


import {
  isNotEmpty,
  paramsToFilters,
  triggerHashRefresh,
  removeFromURLHash,
  isEqual,
} from '../Utilities';

import './AdvancedFilterForm.scss';

import Filter from './Filter';
import { appliedFilterLabelForAttribute, defaultFilterValues, ignoredFieldsForAppliedFilters } from './shared';
import AppliedFilter from './AppliedFilter';

const AdvancedFilterForm = ( {
  fieldGroups,
  values,
  disabled,
  onRefresh,
  collapsed,
  visualCollapsed,
  reportType,
  showAdvancedFilters=false,
} ) => {

  const [ flattenedFields, setFlattenedFields ] = React.useState( null );
  const [ appliedFilterValues, setAppliedFilterValues ] = React.useState( [] );

  // flatten the fields for easier lookups
  React.useEffect( ( ) => {
    if ( isNotEmpty( fieldGroups ) ) {
      const _flattenedFields = [];

      fieldGroups.map( group => {
        group.fields.map( _field => {
          _flattenedFields.push( _field );
        } );
      } );
      setFlattenedFields( _flattenedFields );
    }
  }, [ fieldGroups ] );

  // calls whenever the hash changes, to figure out which filters need to be displayed as buttons
  // because they do not match the default values
  const syncDefaultFilters = () => {
    const filterValues = paramsToFilters();

    const _appliedFilterValues = {};


    Object.entries( filterValues ).map( ( [ attr, val ] ) => {
      if (
        defaultFilterValues !== null
        && !isEqual( defaultFilterValues[reportType][attr], val )
        && !ignoredFieldsForAppliedFilters.includes( attr )
      ) {
        const field = flattenedFields.find( f => f.attribute === attr );
        if ( isNotEmpty( field ) ) {
          _appliedFilterValues[attr] = { label: appliedFilterLabelForAttribute( field.attribute, field ), value: val };
        }
      }
    } );
    setAppliedFilterValues( _appliedFilterValues );
  };

  // setup the hashchange watcher to setup all the fields that need to be displayed as a pill
  React.useEffect( ( ) => {
    if ( isNotEmpty( fieldGroups ) && isNotEmpty( flattenedFields ) ) {
      syncDefaultFilters();
      window.addEventListener( 'hashchange', syncDefaultFilters );
    }
    return () => {
      window.removeEventListener( 'hashchange', syncDefaultFilters );
    };

  }, [ fieldGroups, flattenedFields ] );


  const clearAllAppliedFilters = async () => {
    const changedFilters = [];
    Object.keys( appliedFilterValues ).map( attr => {
      changedFilters.push( attr );
      removeFromURLHash( attr );
    } );

    await onRefresh( changedFilters );
    triggerHashRefresh();
  };

  return (
    <React.Fragment>
      {
        ( isNotEmpty( reportType ) ) &&
        <div className={ `appliedFilters ${ collapsed ? 'collapsed' : ''}` }>
          {
            Object.entries( appliedFilterValues ).map( ( [ attr, val ], _index ) => {
              return <AppliedFilter
                key={_index}
                label={val.label}
                value={val.value}
                attribute={attr}
                onRefresh={onRefresh}
                reportType={reportType}
              />;
            } )
          }
          {
            isNotEmpty( appliedFilterValues ) &&
            <button
              onClick={ clearAllAppliedFilters }
              className="roundGlyphButton light clearFiltersButton"
            >
              <InlineSVG type="delete" />
            </button>
          }
        </div>
      }
      {
        showAdvancedFilters &&
        <div
          // eslint-disable-next-line max-len
          className={ `advancedFilterFormWrapper ${ visualCollapsed ? 'visualCollapsed' : '' } ${ collapsed ? 'collapsed' : '' }` }
        >
          {
            ( isNotEmpty( flattenedFields ) && isNotEmpty( reportType ) ) &&
            fieldGroups.map( ( group, index ) => {
              return <div
                key={index}
                className={ `advancedFormColumn ${index === fieldGroups.length - 1 ? 'last' : ''}` }
              >
                {
                  group.header &&
                  <h2>{ group.header }</h2>
                }
                {
                  group.fields.map( ( field, _index ) => {
                    return <Filter
                      key={_index}
                      value={values[field.attribute] || ''}
                      inputs={ flattenedFields }
                      values={values}
                      input={field}
                      disabled={disabled}
                      onRefresh={onRefresh}
                      reportType={reportType}
                      withinFilterDrawer={ false }
                    />;
                  } )
                }
              </div>;
            } )
          }
        </div>
      }
    </React.Fragment>
  );
};

export default AdvancedFilterForm;