/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import InlineSVG from '../../../shared/InlineSVG';
import { integrationNameMap } from '../../../shared/Utilities';
import { integrationIconMap } from './data';
import RatingBadge from '../../../shared/RatingBadge';

const IntegrationItem = ( { integration } ) => {
  const integrationFeaturesMap = {
    jira: [ 'Remediation', 'User Import' ],
    email: [ 'Remediation' ],
    tanium: [ 'Patching' ],
  };

  return (
    <React.Fragment>
      <section className="mainDetailsSection">
        <InlineSVG
          type={ integrationIconMap[ integration.tool ] }
          version="special"
        />
        <div className="column">
          <h2>
            { integrationNameMap[ integration.tool ] }
            { integration.tool === 'tanium' && <RatingBadge rating="beta" altVersion /> }
          </h2>
          {
            integration.tool === 'email'
              ? <span>
                <strong>Address: </strong>
                { integration?.destination_email_address || 'N/A' }
              </span>
              : <span><strong>Name: </strong>{ integration?.label || integration?.email_label || 'N/A' }</span>
          }
        </div>
      </section>
      <section>
        <strong className="sectionLabel">Features</strong>
        <span className="sectionDetails"> { integrationFeaturesMap[ integration.tool ]?.join( ', ' ) || 'N/A' } </span>
      </section>
    </React.Fragment>
  );
};

export default IntegrationItem;